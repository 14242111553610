import React from "react";

const defaultValue = {
  isLoaderShown: false,
  setIsLoaderShown: () => {},
  isIDPInitialized: false,
  /** @var {User} user */
  user: null,
  isSubscribedToAnyService: false,
  language: null,
  wizardSession: {},
};
const GlobalContext = React.createContext(defaultValue);
export default GlobalContext;
