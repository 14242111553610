const errorUrlMappings = {
  get_owner_reference_object: { 401: { redirectUrl: "reference-gallery/manage" } },
  get_owner_open_position: { 401: { redirectUrl: "jobs/manage" } },
  appAccountTechnicalEmail: { 400: null },
  appAccountMainAddressCorporate: { 400: null },
  appAccountSubAddressCorporate: { 400: null },
  "account/corporate/subsidiaryaddresses/sub": { 400: null },
  "account/corporate/subsidiaryaddresses/main": { 400: null },
  delete_newsletter_outsider: { 400: null },
  appAccountProfessionalNetworksSingle: { 400: null },
};

export default errorUrlMappings;
