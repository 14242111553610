import React, { useContext, useRef } from "react";
import "./JobOfferDetailsPage.scss";
import Page from "pages/Page";
import { AppBack, Content, InfoBlock, JobPreviews, Link, TagsGroup, dateFormatter, fileLoader } from "@sia/style-guide";
import { useHistory, useParams } from "react-router-dom";
import { usePathFactory } from "common/Path/PathFactoryHook";
import { useCallback, useEffect, useState } from "react";
import JobsContentNavigation from "../JobsContentNavigation";
import { Link as RouterLink } from "react-router-dom";
import { useTranslator } from "common/Translation/Translator";
import webPlatformAdapter from "common/Adapters/WebPlatformAdapter/WebPlatformAdapter";
import GlobalContext from "contexts/GlobalContext";
import NotFoundPage from "pages/Error/NotFoundPage";
import { useNavigator } from "common/Navigator";
const JobOfferDetailPage = ({ setJobOffersFilters, setJobOffersPageArgs, isSubscribedToJobs }) => {
  const { id } = useParams();
  const history = useHistory();
  const { language: currentLanguage } = useContext(GlobalContext);
  const pathFactory = usePathFactory();
  const translate = useTranslator();
  const [title, setTitle] = useState("");
  const [offerDate, setOfferDate] = useState("");
  const [tags, setTags] = useState([]);
  const [offerText, setOfferText] = useState("");
  const [company, setCompany] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [otherOffers, setOtherOffers] = useState([]);
  const [offerRequirement, setOfferRequirement] = useState({});
  const [contactAddressLines, setContactAddressLines] = useState([]);
  const [logo, setLogo] = useState("");
  const [website, setWebsite] = useState("");
  const logoRef = useRef(null);
  const navigate = useNavigator();

  const generateOfferRequirement = useCallback(
    (formations, offerFunction, professionalLevels, languageSkills, cad, construction, visualization) => {
      const requirements = {};
      const formationReuirements = formations.map((e) => e.formation);
      const offerFunctionRequirements = offerFunction ? [offerFunction] : [];
      const professionalLevelsRquirements = professionalLevels.map((e) => e.professional_level);
      const languageSkillsRequirements = languageSkills.map((e) => e.language_skill);
      requirements[translate("jobs.details.education")] = formationReuirements;
      requirements[translate("jobs.details.function")] = offerFunctionRequirements;
      requirements[translate("jobs.details.experience")] = professionalLevelsRquirements;
      requirements[translate("jobs.details.languages")] = languageSkillsRequirements;

      const cadSoftwareRequirements = cad?.map((e) => e.name);
      if (cadSoftwareRequirements) {
        requirements[translate("jobs.details.cad")] = cadSoftwareRequirements;
      }

      const constructionSoftwareRequirements = construction?.map((e) => e.name);
      if (constructionSoftwareRequirements) {
        requirements[translate("jobs.details.software")] = constructionSoftwareRequirements;
      }

      const visualizationSoftwareRequirements = visualization?.map((e) => e.name);
      if (visualizationSoftwareRequirements) {
        requirements[translate("jobs.details.visualization")] = visualizationSoftwareRequirements;
      }

      return requirements;
    },
    [translate]
  );

  const handleResponse = useCallback(
    (response) => {
      const { data } = response;
      const title = data[0].title;
      const startDate = data[0].start_date;
      const tags = generateTags(data[2].workloads, data[3].regions);
      const description = data[0].description;
      const company = data[0].company;
      const contactPerson = data[0].contact_person;
      const contactEmail = data[0].contact_email;
      const logo = data[0].logo;
      const webiste = data[0].website;
      const offerRequirement = generateOfferRequirement(
        data[4].formations,
        data[0].function,
        data[5].professional_levels,
        data[1].language_skills,
        data[6].software.CAD,
        data[6].software.construction,
        data[6].software.visualisation
      );
      const contactPhone = data[0].contact_phone;

      const addressLines = [];
      for (let i = 0; i < 10; i++) {
        if (data[0].hasOwnProperty(`line_0${i}`) && data[0][`line_0${i}`]) {
          addressLines.push(data[0][`line_0${i}`]);
        }
      }
      setTitle(title);
      setOfferDate(startDate);
      setTags(tags);
      setOfferText(description);
      setCompany(company);
      setContactPerson(contactPerson);
      setContactEmail(contactEmail);
      setOfferRequirement(offerRequirement);
      setContactAddressLines(addressLines);
      setContactPhone(contactPhone);
      setLogo(logo);
      setWebsite(webiste);
      const relatedData = data[7]["additional_open_positions"];
      let relatedOffers = Object.entries(relatedData)
        .filter(([key, value]) => {
          return value[0].company === company && key !== id;
        })
        .map(([key, value]) => ({
          id: key,
          name: value[0].company,
          title: value[0].title,
          logo: value[0].logo,
          tags: <TagsGroup items={generateTags(value[1].workloads, value[2].regions)} />,
          date: value[0].start_date,
          url: `${key}`,
          translate: translate,
        }));
      setOtherOffers(relatedOffers);
    },
    [generateOfferRequirement, id, translate]
  );

  useEffect(() => {
    const jobId = parseInt(id);
    if (!jobId) return;

    window.scrollTo(0, 0);
    if (currentLanguage) {
      webPlatformAdapter.getOpenPosition(jobId, currentLanguage, handleResponse);
    }
  }, [id, currentLanguage, handleResponse]);

  useEffect(() => {
    const img = logoRef.current;
    if (img) {
      let { width, height } = img;
      let imageContainer = img.parentNode;
      let parentWidth = parseInt(window.getComputedStyle(imageContainer).width.replace("px", ""));
      let parentHeight = parseInt(window.getComputedStyle(imageContainer).height.replace("px", ""));
      let parentWidthToHeightRatio = parentWidth / parentHeight;
      if (width !== 0 && height !== 0) {
        if (width > height) {
          img.style.maxWidth = (width / height / parentWidthToHeightRatio) * 100 + "%";
          img.style.width = "100%";
          img.style.height = "auto";
        } else {
          img.style.height = "100%";
          img.style.width = "auto";
        }
      }
    }
  }, [logo]);

  const generateTags = (workLoads, regions) => {
    const workLoadsTags = workLoads.map((e) => ({
      label: e.workload,
      type: "primary",
    }));

    const regionsTags = regions.map((e) => ({
      label: e.region,
      type: "quaternary",
    }));

    return workLoadsTags.concat(regionsTags);
  };

  const callBack = () => {
    setJobOffersFilters({ jobs: [], regions: [], contractTypes: [] });
    setJobOffersPageArgs((state) => ({
      ...state,
      chosenSort: {
        id: "newest",
        label: translate("jobs.search.sort.newest"),
      },
    }));
  };
  if (!parseInt(id)) {
    return <NotFoundPage />;
  }
  return (
    <Page
      contentNavigation={isSubscribedToJobs ? <JobsContentNavigation callBack={callBack} isSubscribedToJobs={isSubscribedToJobs} /> : null}
      title={"jobs.name"}
    >
      <AppBack
        onClick={() => {
          history.push(pathFactory.create("jobs/offers"));
        }}
      />
      <Content className={"DetailsPage"} width={"full"}>
        <div className="bx--grid reset-spacings">
          <div className="bx--row">
            <div className="bx--col-lg-2 bx--col-md-0 bx--col-sm-0"></div>
            <div className="bx--col-lg-8 bx--col-md-5 bx--col-sm-4">
              <div className="DetailsPage-Title">
                <p>{dateFormatter.format(offerDate)}</p>
                <h1>{title}</h1>
                <TagsGroup items={tags} />
              </div>
              <p className="DetailsPage-Description">{offerText}</p>
            </div>
            <div className="bx--col-lg-4 bx--col-md-3 bx--col-sm-4">
              <div className="DetailsPage-Contact">
                <div className={`DetailsPage-Logo-container ${!logo ? "DetailsPage-Logo-Container__empty" : ""}`}>
                  {logo && <img ref={logoRef} src={fileLoader.createBase64ImageSrc(logo)} alt={translate("jobs.details.logo.alt")} />}
                </div>
                <InfoBlock loose>
                  <span className="label">{translate("jobs.details.company")}</span>
                  <div>
                    <div>
                      <Link className="sia-margin-01-bottom" target={() => navigate(`directory?name=${company}&tab=0&sort=1`)}>
                        {company}
                      </Link>
                    </div>
                    {website && (
                      <Link type="external" target={website}>
                        {website}
                      </Link>
                    )}
                  </div>
                </InfoBlock>
                <span className="productive-heading-03">{translate("jobs.details.contact")}</span>
                <InfoBlock loose>
                  <span className="label">{translate("jobs.details.address")}</span>
                  <div className="DetailsPage-Address">
                    {contactAddressLines.map((line, i) => {
                      if (i === 1) {
                        return (
                          <React.Fragment key={i}>
                            <span>{contactPerson}</span>
                            <span key={i}>{line}</span>
                          </React.Fragment>
                        );
                      }
                      return <span key={i}>{line}</span>;
                    })}
                  </div>
                </InfoBlock>
                <span className="DetailsPage-Email">
                  <Link type={"external"} target={contactEmail}>
                    {contactEmail}
                  </Link>
                </span>
                {contactPhone && (
                  <span className="DetailsPage-Phone">
                    <Link type={"external"} target={contactPhone}>
                      {contactPhone}
                    </Link>
                  </span>
                )}
                <span className="productive-heading-03">{translate("jobs.details.requirements")}</span>
                {Object.entries(offerRequirement).map(([key, value]) => {
                  if (value.length > 0)
                    return (
                      <InfoBlock key={key} loose>
                        <span className="label">{key}</span>
                        {value && (
                          <div className="DetailsPage-Info">
                            {value.map((val, i) => {
                              return <span key={i}>{val}</span>;
                            })}
                          </div>
                        )}
                      </InfoBlock>
                    );
                  return null;
                })}
              </div>
            </div>
            <div className="bx--col-lg-2 bx--col-md-0 bx--col-sm-0"></div>
          </div>
          {otherOffers.length > 0 ? (
            <div className="bx--row">
              <div className="bx--col-lg-16 DetailsPage-Other">
                <p>{translate("jobs.details.other.jobs", { company: company })}</p>
                <JobPreviews items={otherOffers} elementToRender={RouterLink} />
              </div>
            </div>
          ) : null}
        </div>
      </Content>
    </Page>
  );
};

export default JobOfferDetailPage;
