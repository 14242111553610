import React, { useContext } from "react";
import GlobalContext from "contexts/GlobalContext";
import { SIAAgenda } from "@sia/style-guide";
import { useTranslator } from "../../common/Translation/Translator";
import cmsAdapter from "../../common/Adapters/CMSAdapter/CMSAdapter";
import Page from "pages/Page";
import { useHistory } from "react-router-dom";
const Agenda = () => {
  const { language } = useContext(GlobalContext);
  const history = useHistory();
  const translate = useTranslator();
  const getAgenda = (onSuccess) => {
    cmsAdapter.fetchAgenda(language, onSuccess, translate);
  };

  return (
    language && (
      <Page title={translate("header.app-switcher.agenda.name")}>
        <SIAAgenda lang={language} history={history} translate={(value, options) => translate(value, options)} getAgenda={getAgenda}></SIAAgenda>
      </Page>
    )
  );
};

export default Agenda;
