export default class PathFactory {
    constructor(i18n) {
        this.i18n = i18n;
    }

    create(path) {
        if (!path) {
            return `/${this.i18n.language}`;
        }
        return `/${this.i18n.language}/${path}`;
    }
}