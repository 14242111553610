import React, {useEffect, useLayoutEffect, useState} from 'react';
import PropTypes from "prop-types";
import Page from "./../Page";
import {Accordion, ArrowDown32, Content, dateFormatter, Link, ScrollToTop} from "@sia/style-guide";
import {useTranslator} from "common/Translation/Translator";
import './PrivacyPolicy.scss';
import webPlatformAdapter from "common/Adapters/WebPlatformAdapter/WebPlatformAdapter";
import {getLocale} from "common/Translation/i18n";

const PrivacyPolicyPage = (props) => {
    const translate = useTranslator();
    const [base64Pdf, setBase64Pdf] = useState('');
    const [effectiveFromDate, setEffectiveFromDate] = useState('');

    const accordionTranslationKeys = [
        {title: 'privacy-policy.validity', paragraphs: 'privacy-policy.validity.paragraphs'},
        {title: 'privacy-policy.contact', paragraphs: 'privacy-policy.contact.paragraphs'},
        {title: 'privacy-policy.general', paragraphs: 'privacy-policy.general.paragraphs'},
        {title: 'privacy-policy.which-personal-data', paragraphs: 'privacy-policy.which-personal-data.paragraphs'},
        {title: 'privacy-policy.retention-period', paragraphs: 'privacy-policy.retention-period.paragraphs'},
        {title: 'privacy-policy.third-party', paragraphs: 'privacy-policy.third-party.paragraphs'},
        {title: 'privacy-policy.analytics', paragraphs: 'privacy-policy.analytics.paragraphs'},
        {title: 'privacy-policy.cookies', paragraphs: 'privacy-policy.cookies.paragraphs'},
        {title: 'privacy-policy.legal-bases', paragraphs: 'privacy-policy.legal-bases.paragraphs'},
        {title: 'privacy-policy.rights', paragraphs: 'privacy-policy.rights.paragraphs'},
        {title: 'privacy-policy.severability-clause', paragraphs: 'privacy-policy.severability-clause.paragraphs'},
        {title: 'privacy-policy.applicable-law', paragraphs: 'privacy-policy.applicable-law.paragraphs'},
    ]
    const handlePrivacyPolicyNotification = props.handlePrivacyPolicyNotification;

    const loadPrivacyPolicy = () => {
        const data = {lang: getLocale()}
        // todo SW-412, backend should rename rpc to rest
        webPlatformAdapter.request('get', 'rpc/privacy_policy', data, response => {
            setBase64Pdf(response.data.pdf_base_64);
            setEffectiveFromDate(response.data.effective_from);
        })
    }

    useEffect(() => {
        setTimeout(() => { // for hard reload so that i18n contains the expected chosen language
            loadPrivacyPolicy();
        }, 100)
    }, [])

    useLayoutEffect(() => {
        return () => {
            handlePrivacyPolicyNotification()
        }
    }, [handlePrivacyPolicyNotification])

    const accordionItems = accordionTranslationKeys.map((item, index) => ({
        title: <><span className={'sequence'}>{index + 1}</span>{translate(item.title)}</>,
        content: translate(item.paragraphs, {returnObjects: true}).map((item, index) => <p key={index}>{item}</p>)
    }))
    const pdfDownloadLink = <Link target={base64Pdf} downloadName={translate('layout.footer.links.privacy-policy')}>
        {translate('privacy-policy.download-pdf')}
    </Link>;
    const effectiveFromText = translate('privacy-policy.effective-from', {date: dateFormatter.format(effectiveFromDate)});
    return <Page className={'privacy-policy'}>
        <Content activePageTitle={translate('layout.footer.links.privacy-policy')}>
            <h2>{translate('privacy-policy.title')}</h2>
            <ArrowDown32 className={'content-separator-icon'}/>
        </Content>
        <Content>
            <p className={'effective-from'}>
                {effectiveFromText}<span className={'separator'}>|</span>{pdfDownloadLink}
            </p>
            <Accordion items={accordionItems}/>
        </Content>
        <ScrollToTop/>
    </Page>;
};
PrivacyPolicyPage.propTypes = {
    handlePrivacyPolicyNotification: PropTypes.func.isRequired,
};
export default PrivacyPolicyPage;