class MemberType {

    /**
     * @param {integer} id
     * @param {string} group
     * @param {string} name
     */
    constructor(id, group, name) {
        this.id = id;
        this.group = group;
        this.name = name;
    }

    getGroup() {
        return this.group;
    }

    getName() {
        return this.name;
    }
}

export default MemberType;