import React from "react";
import { Link } from "@sia/style-guide";
import FragmentTitle from "./FragmentTitle";
import { useNavigator } from "common/Navigator";
const InfoListWithLinks = ({ listItems, listTitle, language }) => {
  const filteredItems = listItems?.filter((e) => e.name) || [];
  const isThereAnyFilteredItems = filteredItems.length > 0;
  const navigate = useNavigator();
  return (
    isThereAnyFilteredItems && (
      <div className="Branch">
        <FragmentTitle title={listTitle} />
        {listItems.map((item, idx) => {
          return (
            item.name && (
              <React.Fragment key={idx}>
                <Link target={() => navigate(`directory/member/${item.hashed_member_id}`)} key={idx}>
                  {item.name}
                  {item.city ? `, ${item.city}` : ""}
                </Link>
                <br />
              </React.Fragment>
            )
          );
        })}
      </div>
    )
  );
};
export default InfoListWithLinks;
