class Sorter {
    sortFromArray(sortingArray, referenceArray) {
        const reducedReferenceArray = referenceArray.filter(value => sortingArray.includes(value));
        const notInReferenceSortingArray = sortingArray.filter(value => !referenceArray.includes(value));
        return reducedReferenceArray.concat(notInReferenceSortingArray);
    }

    sortItem(array, fromIndex, toIndex) {
        const reduceItems = () => {
            const startingItems = array.slice(0, fromIndex);
            const endingItems = array.slice(fromIndex + 1);
            return startingItems.concat(endingItems);
        }
        const addMovingItem = (movingItem, reducedItems) => {
            const startingNewItems = reducedItems.slice(0, toIndex);
            const endingNewItems = reducedItems.slice(toIndex);
            const newItems = startingNewItems.concat([movingItem]).concat(endingNewItems);
            return newItems;
        }
        const movingItem = array[fromIndex];
        const reducedItems = reduceItems();
        const newItems = addMovingItem(movingItem, reducedItems);
        return newItems;
    }
}

const sorter = new Sorter();
export default sorter;