import {EmailInput as StyleguideEmailInput} from "@sia/style-guide";
import {useTranslator} from "common/Translation/Translator";

const EmailInput = (props) => {
    const translate = useTranslator();
    const exampleLabel = translate('form.input.error.invalid.example.email');
    const invalidPatternText = translate('form.input.error.invalid.with-example', {example: exampleLabel});
    const enrichedProps = {...props, ...{invalidPatternText: invalidPatternText}};
    return <StyleguideEmailInput {...enrichedProps}/>;
};
EmailInput.defaultProps = {
    type: 'EmailInput', // todo, https://stackoverflow.com/q/72446985/4840661
};
export default EmailInput;