import React, { useContext, useEffect, useState } from "react";
import { useTranslator } from "common/Translation/Translator";
import { useHistory } from "react-router-dom";
import UserContentNavigation from "pages/Account/UserContentNavigation";
import Page from "pages/Page";
import GlobalContext from "contexts/GlobalContext";
import { usePathFactory } from "common/Path/PathFactoryHook";
import webPlatformAdapter from "common/Adapters/WebPlatformAdapter/WebPlatformAdapter";
import {
  Content,
  InfoBlock,
  Form,
  DateInput,
  TextInput,
  MultiselectDropdown,
  dateFormatter,
  cloner,
  FileUploader,
  TrashCan32,
  FileUploaderFile,
  fileLoader,
} from "@sia/style-guide";
import "@sia/style-guide/src/components/InfoBlock/InfoBlock.scss"; // todo, fix order of import (component p overwrites info-block p)
import erpAdapter from "adapters/ERPAdapter";
import { BackButton } from "@sia/style-guide/dist/components/BackButton/BackButton";
import downloader from "common/Downloader";
import "./BaseDataPage.scss";

const BaseDataPage = () => {
  const translate = useTranslator();
  const history = useHistory();
  const { user, language } = useContext(GlobalContext);
  const pathFactory = usePathFactory();
  const [shouldFetchCustomerData, setShouldFetchCustomerData] = useState(true);
  const [shouldFetchIndustrySectors, setShouldFetchIndustrySectors] = useState(true);
  const [shouldFetchAreasOfActivity, setShouldFetchAreasOfActivity] = useState(true);
  const [wasIndustrySectorsFetched, setWasIndustrySectorsFetched] = useState(false);
  const [wasAreasOfActivityFetched, setWasAreasOfActivityFetched] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [foundationDate, setFoundationDate] = useState("");
  const [vatNumber, setVatNumber] = useState("");
  const [industrySectors, setIndustrySectors] = useState([]);
  const [areasOfActivity, setAreasOfActivity] = useState([]);
  const [isIndustrySectorsOpen, setIsIndustrySectorsOpen] = useState(false);
  const [isAreasOfActivityOpen, setIsAreasOfActivityOpen] = useState(false);
  const [logoFiles, setLogoFiles] = useState([]);
  const [invalidLogoText, setInvalidLogoText] = useState("");
  const title = translate("account.customer-data.base-data.corporate");
  const REQUEST_DELAY = 2000;
  const closeDropdowns = () => {
    setIsIndustrySectorsOpen(false);
    setIsAreasOfActivityOpen(false);
  };

  const onSave = () => {
    const data = {
      work_branches: industrySectors.filter((item) => item.active).map((item) => item.id),
      work_specialisations: areasOfActivity.filter((item) => item.active).map((item) => item.id),
      logo: logoFiles.length ? logoFiles[0].getBase64() : null,
    };
    if (vatNumber) {
      data.company_UID = vatNumber;
    }
    if (foundationDate) {
      data.foundation_date = dateFormatter.formatForApi(foundationDate);
    }
    setInvalidLogoText("");
    erpAdapter.requestWithDelay(
      "put",
      "appAccountBasicDataCorporate",
      data,
      () => {
        if (vatNumber) {
          setVatNumber("");
        }
        if (foundationDate) {
          setFoundationDate("");
        }
        setShouldFetchCustomerData(true);
      },
      null,
      REQUEST_DELAY
    );
  };

  useEffect(() => {
    if (user && shouldFetchIndustrySectors && language) {
      setShouldFetchIndustrySectors(false);
      webPlatformAdapter.getIndustrySectors(language, (response) => {
        setIndustrySectors(responseItemsToMultiselectItems(response.data));
        setWasIndustrySectorsFetched(true);
      });
    }
    if (user && shouldFetchAreasOfActivity && language) {
      setShouldFetchAreasOfActivity(false);
      webPlatformAdapter.getAreasOfActivity(language, (response) => {
        setAreasOfActivity(responseItemsToMultiselectItems(response.data));
        setWasAreasOfActivityFetched(true);
      });
    }
    if (wasIndustrySectorsFetched && wasAreasOfActivityFetched && shouldFetchCustomerData && language) {
      setShouldFetchCustomerData(false);
      webPlatformAdapter.getCustomerDataOfMemberTypeCorporate(language, (response) => {
        const customerData = response.data[0];
        if (customerData.logo) {
          setLogoFiles([new FileUploaderFile(null, null, customerData.logo)]);
        }
        setUserInfo({
          siaId: response.data[0].sia_id,
          companyName: response.data[0].name,
          additionalName: response.data[0].additional_name,
          contactLanguage: response.data[0].language,
          foundationDate: response.data[0].foundation_date,
          vatNumber: response.data[0].company_uid,
        });
        const assignedIndustrySectorIds = response.data[0].work_branches.map((item) => item.id);
        const assignedAreasOfActivityIds = response.data[0].work_specialisations.map((item) => item.id);
        const activateAssignedItems = (items, activeIds) => {
          const containingActiveItems = items.map((item) => {
            const newItem = cloner.clone(item);
            newItem.active = activeIds.includes(newItem.id);
            return newItem;
          });
          return containingActiveItems;
        };
        setIndustrySectors(activateAssignedItems(industrySectors, assignedIndustrySectorIds));
        setAreasOfActivity(activateAssignedItems(areasOfActivity, assignedAreasOfActivityIds));
      });
    }
  }, [
    user,
    shouldFetchIndustrySectors,
    shouldFetchAreasOfActivity,
    wasIndustrySectorsFetched,
    wasAreasOfActivityFetched,
    shouldFetchCustomerData,
    industrySectors,
    areasOfActivity,
    language,
  ]);

  const responseItemsToMultiselectItems = (items) => {
    return items.map((item) => {
      return { id: item.id, label: item.name };
    });
  };

  const onRemoveLogo = () => {
    setLogoFiles([]);
    setInvalidLogoText("");
  };

  const getLogoName = () => {
    if (!logoFiles.length) {
      return;
    }
    const fileUploaderFile = logoFiles[0];
    const file = fileUploaderFile.getOriginalFile();
    if (file) {
      return file.name;
    }
    const extension = downloader.getFileExtensionFromBase64(fileUploaderFile.getBase64());
    return `Logo.${extension}`;
  };

  const renderContent = () => {
    if (!userInfo) {
      return;
    }
    return (
      <>
        <FileUploader
          label={"account.customer-data.base-data.corporate.upload-logo"}
          sizeLimit={1}
          sizeLimitUnit={"MB"}
          files={logoFiles}
          fileTypes={["jpg", "png"]}
          buttonLabel={"file-uploader.upload-button.label"}
          translate={translate}
          onChange={(event, files, error) => {
            if (error) {
              setInvalidLogoText(error);
              setLogoFiles([]);
              return;
            }
            setInvalidLogoText("");
            setLogoFiles(files);
          }}
          onRemove={onRemoveLogo}
          invalidText={invalidLogoText}
          customList={true}
        />
        {Boolean(logoFiles.length) && (
          <div className={"chosen-image"}>
            <div className={"preview"}>
              <img src={fileLoader.createBase64ImageSrc(logoFiles[0].getBase64())} alt={""} />
            </div>
            <span className={"filename"}>{getLogoName()}</span>
            <TrashCan32 onClick={onRemoveLogo} />
          </div>
        )}

        <InfoBlock>
          <span className={"label"}>{translate("account.attributes.sia-id")}</span>
          <p>{userInfo.siaId}</p>
          <span className={"label"}>{translate("account.attributes.company-name")}</span>
          <p>{userInfo.companyName}</p>

          {userInfo.additionalName && (
            <>
              <span className={"label"}>{translate("account.attributes.additional-name")}</span>
              <p>{userInfo.additionalName}</p>
            </>
          )}

          <span className={"label"}>{translate("account.attributes.contact-language")}</span>
          <p>{userInfo.contactLanguage}</p>

          {userInfo.vatNumber && (
            <>
              <span className={"label"}>{translate("account.attributes.corporate.vat-number")}</span>
              <p>{userInfo.vatNumber}</p>
            </>
          )}
          {userInfo.foundationDate && (
            <>
              <span className={"label"}>{translate("account.attributes.corporate.foundation-date")}</span>
              <p>{dateFormatter.format(userInfo.foundationDate)}</p>
            </>
          )}
        </InfoBlock>
        <Form primaryButtonLabel={translate("form.save")} onClickPrimaryButton={onSave}>
          {!userInfo.vatNumber && (
            <TextInput
              id={"vat-number"}
              label={translate("account.attributes.corporate.vat-number")}
              onChange={(event) => setVatNumber(event.target.value)}
              value={vatNumber}
            />
          )}
          {!userInfo.foundationDate && (
            <DateInput
              id={"foundation-date"}
              label={translate("account.attributes.corporate.foundation-date")}
              onChange={(event, value) => setFoundationDate(value)}
              value={foundationDate}
              invalidPatternText={translate("form.input.error.invalid.with-example", { example: "d.m.yyyy" })}
            />
          )}
          <MultiselectDropdown
            id="industry-sectors"
            label={translate("account.attributes.corporate.industry-sectors")}
            isOpen={isIndustrySectorsOpen}
            toggle={() => {
              closeDropdowns();
              setIsIndustrySectorsOpen(!isIndustrySectorsOpen);
            }}
            items={industrySectors}
            onClickItem={(event, item, updatedItems) => setIndustrySectors(updatedItems)}
          />
          <MultiselectDropdown
            id="areas-of-activity"
            label={translate("account.attributes.corporate.areas-of-activity")}
            isOpen={isAreasOfActivityOpen}
            toggle={() => {
              closeDropdowns();
              setIsAreasOfActivityOpen(!isAreasOfActivityOpen);
            }}
            items={areasOfActivity}
            onClickItem={(event, item, updatedItems) => setAreasOfActivity(updatedItems)}
          />
        </Form>
      </>
    );
  };

  return (
    <Page className={"base-data"} contentNavigation={<UserContentNavigation />} title={"account.name"}>
      <Content width={"slim"} centeredTexts={true}>
        <h1>
          <BackButton onClick={() => history.push(pathFactory.create("account/customer-data"))} />
          {title}
        </h1>
        {renderContent()}
      </Content>
    </Page>
  );
};
export default BaseDataPage;
