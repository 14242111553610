import React, { useCallback, useEffect, useState } from "react";
import Page from "../../Page";
import JobsContentNavigation from "../JobsContentNavigation";
import {
  AppContent,
  JobPreviews,
  TagsGroup,
  AddAlt32,
  Content,
} from "@sia/style-guide";
import { useTranslator } from "common/Translation/Translator";
import "./OffersOverviewPage.scss";
import { useNavigator } from "common/Navigator";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { usePathFactory } from "common/Path/PathFactoryHook";
import webPlatformAdapter from "common/Adapters/WebPlatformAdapter/WebPlatformAdapter";
import i18n from "i18next";

const OffersOverviewPage = ({ isSubscribedToJobs }) => {
  const translate = useTranslator();
  const navigate = useNavigator();
  const [jobPreviewsItems, setJobPreviewsItems] = useState([]);
  const [shouldFetchItems, setShouldFetchItems] = useState(true);
  const history = useHistory();
  const pathFactory = usePathFactory();
  const language = i18n.language;

  const createActions = useCallback(
    (id, published) => {
      const actions = {
        change: () =>
          history.push(pathFactory.create(`jobs/manage/offer/${id}`)),
        hide: published ? () => unpublishJob(id) : null,
        show: published ? null : () => publishJob(id),
        remove: () => deleteJob(id),
      };
      return actions;
    },
    [history, pathFactory]
  );

  const publishJob = (jobId) => {
    webPlatformAdapter.request(
      "post",
      "rpc/publish_open_position",
      { job_id: jobId },
      (response) => {
        setShouldFetchItems(true);
      }
    );
  };

  const unpublishJob = (jobId) => {
    webPlatformAdapter.request(
      "post",
      "rpc/unpublish_open_position",
      { job_id: jobId },
      (response) => {
        setShouldFetchItems(true);
      }
    );
  };

  const deleteJob = (jobId) => {
    webPlatformAdapter.request(
      "post",
      "rpc/delete_open_position",
      { job_id: jobId },
      (response) => {
        setShouldFetchItems(true);
      }
    );
  };

  const tagGroupGenerator = (workloadArr, regionArr) => {
    const workloadTags = workloadArr.map((e) => ({
      label: e.workload,
      type: "primary",
    }));
    const regionTags = regionArr.map((e) => ({
      label: e.region,
      type: "quaternary",
    }));
    const items = workloadTags.concat(regionTags);
    return <TagsGroup items={items} />;
  };

  useEffect(() => {
    if (shouldFetchItems) {
      setShouldFetchItems(false);
      webPlatformAdapter.getOwnerOpenPositions(language, (response) => {
        const { data } = response;
        const items = Object.entries(data).map(([jobId, array]) => {
          const id = jobId;
          const {
            company: name,
            title,
            start_date: date,
            logo,
            status,
          } = array[0];
          const tags = tagGroupGenerator(array[1].workloads, array[2].regions);
          const url = `manage/offer/${id}/preview`;
          const published = status === 1;
          const actions = createActions(jobId, published);
          const onClickUnpublished = () =>
            history.push(
              pathFactory.create(`jobs/manage/offer/${id}/preview`),
              { canGoBack: true }
            );
          return {
            id,
            name,
            title,
            logo,
            date,
            tags,
            url,
            published,
            actions,
            translate,
            onClickUnpublished,
          };
        });
        setJobPreviewsItems(items);
      });
    }
  }, [
    translate,
    language,
    createActions,
    shouldFetchItems,
    history,
    pathFactory,
  ]);

  return (
    <Page
      contentNavigation={
        <JobsContentNavigation isSubscribedToJobs={isSubscribedToJobs} />
      }
      className={"job-requests"}
      title={'jobs.name'}
    >
      <AppContent>
        <p>{translate("jobs.manage.description")}</p>
      </AppContent>
      {jobPreviewsItems.length > 0 ? (
        <>
          <JobPreviews items={jobPreviewsItems} elementToRender={RouterLink} />
          <div className="JobOffer-Addbtn">
            <AddAlt32 onClick={() => navigate("jobs/manage/offer")} />
          </div>
        </>
      ) : (
        <Content>
          <div>
            <p>{translate("jobs.manage.no-items-found")}</p>
            <div className="JobOffer-Addbtn">
              <AddAlt32 onClick={() => navigate("jobs/manage/offer")} />
            </div>
          </div>
        </Content>
      )}
    </Page>
  );
};

export default OffersOverviewPage;
