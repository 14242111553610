import React, { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import PropTypes from "prop-types";
import { useTranslator } from "common/Translation/Translator";
import Page from "pages/Page";
import './ReferenceGallery.scss';
import ReferenceGalleryContentNavigation from "./ReferenceGalleryContentNavigation";
import {
    AppContent, changer, Content,
    Link,
    MultiselectDropdown, Dropdown,
    Pagination,
    ReferenceGalleryItemPreviews, safeStateChanger,
    Search24, TextInput
} from "@sia/style-guide";
import webPlatformAdapter from "common/Adapters/WebPlatformAdapter/WebPlatformAdapter";
import i18n from "i18next";
import cloner from "@sia/style-guide/dist/utils/Cloner";
import itemPreviewFactory from "./ItemPreviewFactory";
import IDPAdapter from "common/Adapters/IDPAdapter/IDPAdapter";
import GlobalContext from "contexts/GlobalContext";
import { usePathFactory } from "common/Path/PathFactoryHook";
import { useLocation, useParams, Link as RouterLink, useHistory } from "react-router-dom";
import paginator from "common/Paginator";
import isGoogleBot from "../../common/GoogleBots";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const ReferenceGalleryPage = (props) => {
    const { setReferenceGalleryPageArgs, referenceGalleryPageArgs } = props
    const { targetPage, perPageLimit, chosenSort } = props.referenceGalleryPageArgs
    const location = useLocation();
    const history = useHistory();
    const baseUrl = location.pathname.replace(/\/$/g, '');
    const params = useParams();
    const preselectedCompanyId = Number(params.ownerId);
    const translate = useTranslator();
    const GlobalContextValue = useContext(GlobalContext);
    const user = GlobalContextValue.user;
    const language = GlobalContextValue.language;
    const pathFactory = usePathFactory();
    const [name, setName] = useState('');
    const [locations, setLocations] = useState([]);
    const [cantons, setCantons] = useState([]);
    const [categories, setCategories] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [wasItemsFetchStarted, setWasItemsFetchStarted] = useState(false);
    const [items, setItems] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [limitedItems, setLimitedItems] = useState([]);
    const [isLocationsOpen, setIsLocationsOpen] = useState(false);
    const [isCantonsOpen, setIsCantonsOpen] = useState(false);
    const [isCategoriesOpen, setIsCategoriesOpen] = useState(false);
    const [isCompaniesOpen, setIsCompaniesOpen] = useState(false);
    const sortDropdownItems = [
        { id: 'newest', label: translate('reference-gallery.sort.newest') },
        { id: 'oldest', label: translate('reference-gallery.sort.oldest') },
        { id: 'name', label: translate('reference-gallery.sort.name') },
        { id: 'canton', label: translate('reference-gallery.sort.canton') },
        { id: 'category', label: translate('reference-gallery.sort.category') },
        { id: 'company', label: translate('reference-gallery.sort.company') },
    ];
    const userAgent = navigator.userAgent;
    if (isGoogleBot(userAgent) && !baseUrl.endsWith('0')) {
        history.push(`${baseUrl}/0`)
    }
    const sortItems = useCallback((sortingItems, sortBy) => {
        sortingItems.sort((a, b) => b.id - a.id)
        const getComparisonResult = (valueA, valueB) => {
            if (valueA === valueB) {
                return 0;
            }
            return valueA > valueB ? 1 : -1;
        }
        return cloner.clone(sortingItems).sort((itemA, itemB) => {
            if (sortBy === 'name') {
                return getComparisonResult(itemA.title.name.toLowerCase(), itemB.title.name.toLowerCase())
            }
            if (sortBy === 'canton') {
                return getComparisonResult(itemA.cantons[0].toLowerCase(), itemB.cantons[0].toLowerCase())
            }
            if (sortBy === 'category') {
                return getComparisonResult(itemA.categories[0].toLowerCase(), itemB.categories[0].toLowerCase())
            }
            if (sortBy === 'company') {
                return getComparisonResult(itemA.company.toLowerCase(), itemB.company.toLowerCase())
            }
            if (sortBy === 'newest') {
                let comparisonResult = getComparisonResult((new Date(itemB.responseItem.publication_date)).getTime(), (new Date(itemA.responseItem.publication_date)).getTime())
                if (comparisonResult === 0) {
                    return getComparisonResult(itemA.title.name.toLowerCase(), itemB.title.name.toLowerCase())
                }
                return comparisonResult
            }
            if (sortBy === 'oldest') {
                let comparisonResult = getComparisonResult((new Date(itemA.responseItem.publication_date)).getTime(), (new Date(itemB.responseItem.publication_date)).getTime())
                if (comparisonResult === 0) {
                    return getComparisonResult(itemA.title.name.toLowerCase(), itemB.title.name.toLowerCase())
                }
                return comparisonResult
            }
            return 0;
        })
    }, [])
    const onPaginate = (targetPage, perPageLimit) => {
        const limitedItems = limitItems(filteredItems, targetPage, perPageLimit);
        // setTargetPage(targetPage);
        setReferenceGalleryPageArgs(state => ({ ...state, targetPage }))
        setReferenceGalleryPageArgs(state => ({ ...state, perPageLimit }))
        // setPerPageLimit(perPageLimit);
        setLimitedItems(limitedItems);
    }

    const limitItems = useCallback((items, targetPage, limit) => {
        return paginator.paginateItems(items, targetPage, limit)
    }, [])

    const onFilter = (filter) => {
        // for when being on a subsequential page, and the filter result length does not reach the current page
        // setTargetPage(1);
        setReferenceGalleryPageArgs(state => ({ ...state, targetPage: 1 }))

        filter();
    }
    const stateMultiselectItems = useMemo(() => {
        return {
            locations: locations,
            cantons: cantons,
            categories: categories,
            companies: companies,
        }
    }, [cantons, categories, companies, locations]);

    const filterItems = useCallback((filteringItems, filteringMultiselectName, updatedMultiselectItems, nameFilter) => {
        const getMostRecentMultiselectItems = (multiselectName) => {
            return (multiselectName === filteringMultiselectName) ? updatedMultiselectItems : stateMultiselectItems[multiselectName];
        }
        const selectedLocations = getMostRecentMultiselectItems('locations').filter(item => item.active);
        const selectedCantons = getMostRecentMultiselectItems('cantons').filter(item => item.active);
        const selectedCategories = getMostRecentMultiselectItems('categories').filter(item => item.active);
        const selectedCompanies = getMostRecentMultiselectItems('companies').filter(item => item.active);

        return filteringItems.filter(item => {
            const andFilterResults = [];
            if (nameFilter) {
                andFilterResults.push(item.title.name.toLowerCase().includes(nameFilter.toLowerCase()));
            }
            if (selectedLocations.length) {
                const hasSelectedLocation = Boolean(
                    selectedLocations.find(selectedLocation => selectedLocation.label === item.responseItem.city)
                );
                andFilterResults.push(hasSelectedLocation);
            }
            if (selectedCantons.length) {
                const hasSelectedCanton = item.responseItem.states ?
                    oneOfInArray(selectedCantons, item.responseItem.states) :
                    false;
                andFilterResults.push(hasSelectedCanton);
            }
            if (selectedCategories.length) {
                const hasSelectedCategory = oneOfInArray(selectedCategories, item.responseItem.categories);
                andFilterResults.push(hasSelectedCategory);
            }
            if (selectedCompanies.length) {
                const hasSelectedCompany = oneOfInArray(selectedCompanies, [{ label: item.responseItem.corporate }], 'label');
                andFilterResults.push(hasSelectedCompany);
            }
            return !andFilterResults.includes(false);
        })
    }, [stateMultiselectItems])

    const preselectCompany = useCallback((companyId, companies) => {
        return companies.map(company => {
            const active = (company.id === companyId)
            return changer.changeImmutable(company, 'active', active)
        });
    }, [])

    const responseDataToPageData = (response) => {
        const pageData = {
            locations: [],
            cantons: [],
            categories: [],
            companies: [],
            items: [],
        }
        response.data.forEach(responseItem => {
            // TODO remove this line when the API is ready.
            responseItem.title = {name:responseItem.title,lang:''}
            if (responseItem.city) { // todo, refactor nullables SW-215
                const wasLocationCollected = pageData.locations.find(location => location.id === responseItem.city);
                if (!wasLocationCollected) {
                    pageData.locations.push({ id: responseItem.city, label: responseItem.city, name: responseItem.city })
                }
            }
            if (responseItem.states) {
                responseItem.states.forEach(responseCanton => {
                    const wasCantonCollected = pageData.cantons.find(canton => canton.id === responseCanton.id);
                    if (!wasCantonCollected) {
                        const cantonLabel = `${responseCanton.name} (${responseCanton.code})`;
                        pageData.cantons.push({ id: responseCanton.id, label: cantonLabel, name: responseCanton.name })
                    }
                })
            }
            responseItem.categories.forEach(responseCategory => {
                const wasCategoryCollected = pageData.categories.find(category => category.id === responseCategory.id);
                if (!wasCategoryCollected) {
                    const categoryLabel = `${responseCategory.name} (${responseCategory.code})`;
                    const categoryName = responseCategory.name;
                    pageData.categories.push({ id: responseCategory.id, label: categoryLabel, name: categoryName })
                }
            })
            const wasCompanyCollected = pageData.companies.find(company => company.id === responseItem.member_id);
            if (!wasCompanyCollected) {
                const corporateName = responseItem.corporate;
                pageData.companies.push({ id: responseItem.member_id, label: corporateName, name: corporateName })
            }
            pageData.items.push(itemPreviewFactory.createFromResponseItem(responseItem));
        })
        return pageData
    }

    const initialFilterItems = useCallback((items) => {
        let filteredItems = cloner.clone(items);
        Object.keys(props.initialFilters).forEach(key => {
            const value = props.initialFilters[key];
            if (key === 'name') {
                filteredItems = filterItems(filteredItems, null, null, value);
                return;
            }
            filteredItems = filterItems(filteredItems, key, value, null);
        })
        return filteredItems
    }, [filterItems, props.initialFilters])

    useEffect(() => {
        if (!wasItemsFetchStarted && language) {
            // todo, loader disappears before items are rendered SW-167
            setWasItemsFetchStarted(true);
            webPlatformAdapter.getActiveReferenceObjects(language, (response) => {
                const pageData = responseDataToPageData(response);
                const items = sortItems(pageData.items, referenceGalleryPageArgs.chosenSort.id);
                let companies = cloner.clone(pageData.companies);
                let filteredItems = initialFilterItems(items);
                if (preselectedCompanyId && companies.find(company => (company.id === preselectedCompanyId))) {
                    companies = preselectCompany(preselectedCompanyId, companies);
                    filteredItems = filterItems(items, 'companies', companies, '');
                    props.setInitialFilters(safeStateChanger.changeValue(props.initialFilters, 'companies', companies))
                }
                let limitedItems = limitItems(filteredItems, targetPage, perPageLimit);
                if (isGoogleBot(navigator.userAgent)) {
                    limitedItems = limitItems(filteredItems, 1, items.length);
                }
                setName(props.initialFilters.name || '')
                setLocations(props.initialFilters.locations || pageData.locations)
                setCantons(props.initialFilters.cantons || pageData.cantons)
                setCategories(props.initialFilters.categories || pageData.categories)
                setCompanies(props.initialFilters.companies || companies)
                setItems(items)
                setFilteredItems(filteredItems)
                setLimitedItems(limitedItems)
            })
        }
    }, [wasItemsFetchStarted, filterItems, limitItems, sortItems, preselectCompany,
        preselectedCompanyId, props, targetPage, perPageLimit, initialFilterItems, referenceGalleryPageArgs.chosenSort.id, language])

    const oneOfInArray = (oneOfItems, inArray, byProperty = 'id') => {
        return Boolean(oneOfItems.find(oneOfItem => {
            return Boolean(inArray.find(inArrayItem => inArrayItem[byProperty] === oneOfItem[byProperty]))
        }));
    }

    const enrichToRenderableItems = (items) => {
        return items.map(item => {
            item.url = `${baseUrl.replace(/\/\d+$/, '')}/project/${item.id}`
            const newItem = cloner.clone(item)
            return newItem
        })
    }

    const getToFilterAvailableNamesOf = (multiselectName, filteredItems) => {
        switch (multiselectName) {
            case 'locations':
                return filteredItems.map(filteredItem => filteredItem.responseItem.city)
            case 'cantons':
                const availableCantonNames = [];
                filteredItems.forEach(filteredItem => {
                    if (filteredItem.responseItem.states) {
                        filteredItem.responseItem.states.forEach(canton => {
                            availableCantonNames.push(canton.name)
                        })
                    }
                })
                return availableCantonNames;
            case 'categories':
                const categoryNames = [];
                filteredItems.forEach(filteredItem => {
                    filteredItem.responseItem.categories.forEach(category => {
                        categoryNames.push(category.name)
                    })
                })
                return categoryNames;
            case 'companies':
                return filteredItems.map(filteredItem => filteredItem.responseItem.corporate)
            default:
                return [];
        }
    }

    const disableUnavailableMultiselectItems = (multiselectItems, relevantNames) => {
        return multiselectItems.map(multiselectItem => {
            const newMultiselectItem = cloner.clone(multiselectItem);
            newMultiselectItem.disabled = !relevantNames.includes(multiselectItem.name);
            return newMultiselectItem;
        })
    }
    const onClickMultiselectItem = (filteringMultiselectName, updatedMultiselectItems) => {
        const newTargetPage = 1;
        const filteredItems = filterItems(items, filteringMultiselectName, updatedMultiselectItems, name);
        const limitedItems = limitItems(filteredItems, newTargetPage, perPageLimit);
        setFilteredItems(filteredItems)
        setLimitedItems(limitedItems)
        // setTargetPage(newTargetPage);
        setReferenceGalleryPageArgs(state => ({ ...state, targetPage: newTargetPage }))
        setMultiselectItems(filteringMultiselectName, updatedMultiselectItems)

        props.setInitialFilters(safeStateChanger.changeValue(
            props.initialFilters,
            filteringMultiselectName,
            updatedMultiselectItems
        ))
    }
    const onOpenFilterDropdown = (dropdownName) => {
        const availableNames = getToFilterAvailableNamesOf(dropdownName, filteredItems);
        const availabilityConsideredMultiselectItems = disableUnavailableMultiselectItems(
            stateMultiselectItems[dropdownName],
            availableNames
        );
        setMultiselectItems(dropdownName, availabilityConsideredMultiselectItems)
    }
    const setMultiselectItems = (name, multiselectItems) => {
        switch (name) {
            case 'locations':
                setLocations(multiselectItems);
                break;
            case 'cantons':
                setCantons(multiselectItems);
                break;
            case 'categories':
                setCategories(multiselectItems);
                break;
            case 'companies':
                setCompanies(multiselectItems);
                break;
            default:
        }
    }

    const renderLoginLink = () => {
        const afterLoginRedirectUrl = window.location.origin + pathFactory.create('reference-gallery/manage');
        return <Link withIcon={true}
            /**
             * Using callback target instead of string, otherwise it causes:
             * - error in keycloak-js, probably lifecycle/timing problem
             * - infinite reload
             */
            target={() => window.location.href = IDPAdapter.createLoginUrl(i18n.language, afterLoginRedirectUrl)}>
            {translate('reference-gallery.search.login')}
        </Link>
    }

    const toggleDropdowns = (singleToggleCallback) => {
        setIsLocationsOpen(false)
        setIsCantonsOpen(false)
        setIsCategoriesOpen(false)
        setIsCompaniesOpen(false)
        singleToggleCallback()
    }

    return <Page contentNavigation={<ReferenceGalleryContentNavigation setReferenceGalleryPageArgs={setReferenceGalleryPageArgs} />} title={'reference-gallery.name'}>
        <HelmetProvider>

            {isGoogleBot(navigator.userAgent) && language !== 'de' ?
                <Helmet>
                    <link rel="canonical" href="https://web.sia.ch/de/reference-gallery/search/0" />
                </Helmet>
                : null
            }
            <Content className={'reference-gallery'}>
                <AppContent>
                    <p>{translate('reference-gallery.search.description')}</p>
                    <p>{translate('reference-gallery.search.as-company-member')}</p>
                    {!user && renderLoginLink()}
                </AppContent>
                <div className={'filters-line'}>
                    <TextInput id={'name'} className={'name'}
                        placeholder={translate('reference-gallery.filter.name')}
                        value={name}
                        onChange={(event) => onFilter(() => {
                            const nameFilter = event.target.value;
                            const filteredItems = filterItems(items, null, null, nameFilter);
                            const limitedItems = limitItems(filteredItems, targetPage, perPageLimit);
                            setName(nameFilter)
                            setFilteredItems(filteredItems)
                            setLimitedItems(limitedItems)
                            props.setInitialFilters(safeStateChanger.changeValue(props.initialFilters, 'name', nameFilter))
                        })}
                        icon={<Search24 />} />
                    <div className={'one-third'}>
                        <MultiselectDropdown id="locations"
                            label={translate('reference-gallery.location')}
                            isOpen={isLocationsOpen}
                            toggle={() => {
                                toggleDropdowns(() => setIsLocationsOpen(!isLocationsOpen))
                                onOpenFilterDropdown('locations')
                            }}
                            items={locations}
                            onClickItem={(event, item, updatedItems) => onClickMultiselectItem('locations', updatedItems)}
                        />
                    </div>
                </div>
                <div className={'filters-line'}>
                    <div className={'one-third'}>
                        <MultiselectDropdown id="cantons"
                            label={translate('reference-gallery.filter.canton')}
                            isOpen={isCantonsOpen}
                            toggle={() => {
                                toggleDropdowns(() => setIsCantonsOpen(!isCantonsOpen))
                                onOpenFilterDropdown('cantons')
                            }}
                            items={cantons}
                            onClickItem={(event, item, updatedItems) => onClickMultiselectItem('cantons', updatedItems)}
                        />
                    </div>
                    <div className={'one-third'}>
                        <MultiselectDropdown id="categories"
                            label={translate('reference-gallery.filter.category')}
                            isOpen={isCategoriesOpen}
                            toggle={() => {
                                toggleDropdowns(() => setIsCategoriesOpen(!isCategoriesOpen))
                                onOpenFilterDropdown('categories')
                            }}
                            items={categories}
                            onClickItem={(event, item, updatedItems) => onClickMultiselectItem('categories', updatedItems)}
                        />
                    </div>
                    <div className={'one-third'}>
                        <MultiselectDropdown id="companies"
                            label={translate('reference-gallery.filter.company')}
                            isOpen={isCompaniesOpen}
                            toggle={() => {
                                toggleDropdowns(() => setIsCompaniesOpen(!isCompaniesOpen))
                                onOpenFilterDropdown('companies')
                            }}
                            items={companies}
                            onClickItem={(event, item, updatedItems) => onClickMultiselectItem('companies', updatedItems)}
                        />
                    </div>
                </div>
                <Dropdown id='sort' className={'sort'} items={sortDropdownItems} label={translate('sort.by')}
                    value={chosenSort}
                    onChange={(event, item) => {
                        const sortedItems = sortItems(items, item.id)
                        const filteredItems = filterItems(sortedItems, null, null, name);
                        const limitedItems = limitItems(filteredItems, 1, perPageLimit);
                        setItems(sortedItems)
                        setFilteredItems(filteredItems)
                        setLimitedItems(limitedItems)
                        setReferenceGalleryPageArgs((state) => ({ ...state, chosenSort: item, targetPage: 1 }))
                    }}
                    inline={true}
                />

                <ReferenceGalleryItemPreviews items={enrichToRenderableItems(limitedItems)} translate={translate} elementToRender={RouterLink} />
                {
                    isGoogleBot(userAgent) && preselectedCompanyId === 0 ?
                        null :
                        <Pagination items={filteredItems} onChange={onPaginate} translate={translate}
                            page={targetPage} pageSize={perPageLimit} />
                }

            </Content>
        </HelmetProvider>
    </Page>;
}
ReferenceGalleryPage.propTypes = {
    initialFilters: PropTypes.object.isRequired,
    setInitialFilters: PropTypes.func.isRequired,
};
export default ReferenceGalleryPage;
