import errorUrlMappings from "./errorsUrlconf";

class HttpErrorsHandler {
  constructor() {
    this.handlerError = this.handleError.bind(this);
  }

  handleError(error, navigationCallBack, errorCallBack, pageSpecificErrorHandler) {
    const ERROR_PAGE_URL = "error";

    const { status, statusText } = error.response || {};
    const endpointUrl = error?.config?.url;

    const endpointName = Object.keys(errorUrlMappings).find((endpoint) => endpointUrl?.includes(endpoint));

    if (pageSpecificErrorHandler && endpointName) {
      const mapValue = errorUrlMappings[endpointName];
      if (mapValue && mapValue[status] === null) {
        pageSpecificErrorHandler();
        return;
      }
    }

    errorCallBack((err) => ({ ...err, error, status, statusText }));

    const mapValue = errorUrlMappings[endpointName];

    if (endpointName && mapValue && mapValue[status]) {
      navigationCallBack(mapValue[status]["redirectUrl"]);
    } else {
      navigationCallBack(ERROR_PAGE_URL, { status, statusText });
    }
  }
}

const httpErrorsHandler = new HttpErrorsHandler();
export default httpErrorsHandler;
