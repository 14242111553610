import { Link, phoneFormatter, LogoXing32, LogoLinkedin32 } from "@sia/style-guide";
import FragmentTitle from "./FragmentTitle";
const Address = ({ address, phone, mobile, email, website, title, xing, linkedin }) => {
  return (
    <div className="Address">
      {address && address.length > 2 && (
        <>
          <FragmentTitle title={title} />
          {address && (
            <p className="Address-Title">
              {address.map((address_line, idx) => (
                <span className="sia-text-08" key={idx}>
                  {address_line}
                  <br />
                </span>
              ))}
            </p>
          )}
        </>
      )}

      {phone && (
        <p className="Address-Phone">
          <Link target={phone}>T {phoneFormatter.format(phone)}</Link>
        </p>
      )}
      {mobile && (
        <p className="Address-Phone">
          <Link target={mobile}>M {phoneFormatter.format(mobile)}</Link>
        </p>
      )}
      {email && (
        <p className="Address-Email">
          <Link target={email}>{email}</Link>
        </p>
      )}
      {website && (
        <p className="Address-Website">
          <Link target={website}>{website}</Link>
        </p>
      )}
      {(xing || linkedin) && (
        <div className="Address-SocialLinks">
          {xing && (
            <Link key={"xing"} target={xing}>
              <LogoXing32 height="20px" width="20px" />
            </Link>
          )}
          {linkedin && (
            <Link key={"linkedin"} target={linkedin}>
              <LogoLinkedin32 height="20px" width="20px" />
            </Link>
          )}
        </div>
      )}
    </div>
  );
};
export default Address;
