import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslator } from "common/Translation/Translator";
import { AppHeader, ContentNeck } from "@sia/style-guide";
import GlobalContext from "contexts/GlobalContext";

const Page = (props) => {
  const translate = useTranslator();
  const { user } = useContext(GlobalContext);
  const createBrowserTitle = () => {
    const browserTitle = translate("browser.title");
    if (props.title) {
      const pageTitle = translate(props.title);
      return `${pageTitle} - ${browserTitle}`;
    }
    return browserTitle;
  };

  useEffect(() => {
    document.title = createBrowserTitle();
  });

  const renderAppHeader = () => {
    const appHeaders = [
      {
        name: "SIA-Account",
        urlPart: "account",
      },
      {
        name: "SIA-Reference-gallery",
        urlPart: "reference-gallery",
      },
      { name: "SIA-Jobs", urlPart: "jobs" },
      {
        name: "SIA-Directory",
        urlPart: "directory",
      },
      { name: "SIA-Wizard", urlPart: "wizard" },
      { name: "SIA-Agenda", urlPart: "agenda" },
    ];
    let currentUrlPart = window.location.pathname.split("/")[2];
    const appHeader = appHeaders.find((appHeader) => appHeader.urlPart === currentUrlPart);
    if (appHeader) {
      if (appHeader.name === "account" && user) {
        const info = [user.siaId, user.originalMemberType];
        return <AppHeader appName={appHeader.name} translate={translate} info={info} />;
      }
      return <AppHeader appName={appHeader.name} translate={translate} />;
    }
  };
  return (
    <div id={"page"} className={props.className}>
      <ContentNeck>
        {renderAppHeader()}
        {props.contentNavigation}
      </ContentNeck>

      {props.children}
    </div>
  );
};
Page.propTypes = {
  contentNavigation: PropTypes.object,
  title: PropTypes.string,
  className: PropTypes.string,
};
export default Page;
