import React from 'react';
import {Content} from '@sia/style-guide';
import {useTranslator} from "common/Translation/Translator";
import Page from "../Page";

const NotLoggedInPage = () => {
    const translate = useTranslator();
    return <Page>
        <Content>
            <h1>{translate('routing.private.not-logged-in.title')}</h1>
            <p>{translate('routing.private.not-logged-in.text')}</p>
        </Content>
    </Page>;
};
export default NotLoggedInPage;