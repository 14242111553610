export default class CustomerDataTile {

    #title;
    #content;
    #link;
    #memberTypeGroups;

    constructor(title, content, link, memberTypeGroups) {
        this.#title = title;
        this.#content = content;
        this.#link = link;
        this.#memberTypeGroups = memberTypeGroups;
    }

    getTitle() {
        return this.#title;
    }

    getContent() {
        return this.#content;
    }

    getLink() {
        return this.#link;
    }

    getMemberTypeGroups() {
        return this.#memberTypeGroups;
    }
}