export default class User {
  /**
   * todo, how to typecheck in JS? with typescript?
   * @param {string} siaId
   * @param {MemberType} memberType
   * @param {string} locale
   */
  constructor(siaId, memberType, locale, role, originalMemberType, hasSubsidiaries) {
    this.siaId = siaId;
    this.memberType = memberType;
    this.locale = locale;
    this.role = role;
    this.originalMemberType = originalMemberType;
    this.hasSubsidiaries = hasSubsidiaries
  }

  getSiaId() {
    return this.siaId;
  }

  getMemberType() {
    return this.memberType;
  }

  isMemberTypeGroupOf(name) {
    return this.memberType.getGroup() === name;
  }

  getLocale() {
    return this.locale;
  }
  getRole() {
    return this.role;
  }
  getOriginalMemberType() {
    return this.originalMemberType
  }
  hasSubsidiaries() {
    return this.hasSubsidiaries
  }
}
