import MemberType from "./MemberType";

// todo, move memberType out of adapters context

const memberTypes = [
    {id: 42, group: 'single', name: 'single'},
    {id: 43, group: 'single', name: 'student'},
    {id: 44, group: 'single', name: 'honorary'},
    {id: 45, group: 'single', name: 'senior'},
    {id: 49, group: 'single', name: 'noMemberPrivate'},
    {id: 51, group: 'single', name: 'junior'},
    {id: 46, group: 'corporate', name: 'corporate'},
    {id: 47, group: 'corporate', name: 'subsidiaryCompany'},
    {id: 48, group: 'corporate', name: 'partner'},
    {id: 50, group: 'corporate', name: 'noMemberCompany'},
];

class MemberTypeRepository {

    getById(id) {
        const foundType = memberTypes.find(memberType => memberType.id === id);
        return foundType ? new MemberType(foundType.id, foundType.group, foundType.name) : null;
    }

}

const memberTypeRepository = new MemberTypeRepository();
export default memberTypeRepository;