import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { SIAPage } from "@sia/style-guide";
import cmsAdapter from "../../common/Adapters/CMSAdapter/CMSAdapter";
import { scrollToElement } from "./scrollToElement.js";
import { useTranslator } from "../../common/Translation/Translator";
import useFormMethods from "./useFormMethods";
import useContentMethods from "./useContentMethods";

const CMSSectionWrapper = ({ languageKey }) => {
  const history = useHistory();
  const { alias, section } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [siaContentProps, setSiaContentProps] = useState({});
  const [cachedValues, setCachedValues] = useState({});
  const [contentType, setContentType] = useState("");
  const translate = useTranslator();

  if (siaContentProps.section && section !== siaContentProps.section) {
    history.push(`/not-found`);
  }
  const { getContentByNodeID } = useContentMethods(
    cmsAdapter,
    cachedValues,
    setCachedValues,
    languageKey,
    history,
    setContentType,
    setSiaContentProps,
    setIsLoading,
    alias
  );
  const { fetchForm, onSubmitForm } = useFormMethods(cmsAdapter, cachedValues, setCachedValues);

  const loadPage = () => {
    setIsLoading(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    if (section) return getContentByNodeID();
    else history.push(`/not-found`);
  };

  useEffect(() => {
    !isLoading && scrollToElement(history);
    // eslint-disable-next-line
  }, [isLoading]);

  useEffect(() => {
    section && alias && loadPage();
    // eslint-disable-next-line
  }, [section, alias]);

  // useEffect(() => {
  //   const createBrowserTitle = () => {
  //     const browserTitle = translate("browser.title");
  //     if (siaContentProps.title) {
  //       return `${siaContentProps.title} - ${browserTitle}`;
  //     }
  //     return browserTitle;
  //   };
  //   document.title = createBrowserTitle();
  // }, [siaContentProps.title, translate]);
  return (
    <>
      {!isLoading && (
        <div>
          {contentType === "sektion_page" && (
            <SIAPage
              translate={(value, options) => translate(value, options)}
              key={siaContentProps?.title}
              {...siaContentProps}
              lang={languageKey}
              history={history}
              inFormMethods={{
                fetchForm,
                onSubmitForm,
              }}
              captchaKey={process.env.REACT_APP_CAPTCHA_KEY}
            />
          )}
        </div>
      )}
    </>
  );
};

export default CMSSectionWrapper;
