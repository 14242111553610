import Tag from "@sia/style-guide/dist/components/Tag/Tag";
import FragmentTitle from "./FragmentTitle";
const InfoList = ({ listItems, listTitle, onTagClick }) => {
  const filteredBranches = listItems.filter((e) => e.name);
  const isThereAnyFilteredBranches = filteredBranches.length > 0;
  return (
    isThereAnyFilteredBranches && (
      <div className="Branch">
        <FragmentTitle title={listTitle} />
        {listItems.map((item, idx) => {
          return (
            item.name && (
              <Tag
                label={item.name}
                type={"tertiary"}
                key={idx}
                onClick={() => {
                  onTagClick && onTagClick(item);
                }}
              />
            )
          );
        })}
      </div>
    )
  );
};
export default InfoList;
