import {PhoneInput as StyleguidePhoneInput} from "@sia/style-guide";
import {useTranslator} from "common/Translation/Translator";

export const PhoneInput = (props) => {
    const translate = useTranslator();
    const invalidPatternText = translate(
        'form.input.error.invalid.with-example',
        {example: translate('form.input.error.invalid.example.phone')}
    );
    const enrichingProps = {invalidPatternText: invalidPatternText};
    const enrichedProps = {...props, ...enrichingProps};
    return <StyleguidePhoneInput {...enrichedProps}/>;
};