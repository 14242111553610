import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslator } from "common/Translation/Translator";
import Page from "pages/Page";
import "./Directory.scss";
import { Content, MultiselectDropdown, Dropdown, Search24, TextInput, Checkbox, DirectoryPreviews, Accordion } from "@sia/style-guide";
import { Link as RouterLink } from "react-router-dom";
import { Pagination } from "@sia/style-guide";
import pgAdapter from "common/Adapters/PGAdapter/PGAdapter";
import GlobalContext from "contexts/GlobalContext";
import { useHistory } from "react-router-dom";
const DirectoryPage = (props) => {
  let {
    selectedFilters,
    setSelectedFilters,
    setCorporateMembersTargetPage,
    setSingleMembersTragetPage,
    setJuniorMembersTagrgetPage,
    setHonoraryMembersTargetPage,
    setSeniorMembersTargetPage,
    setStudentMembersTargetPage,
    setSpecialistMembersTargetPage,
    corporateMembersPerPageLimit,
    corporateMembersTargetPage,
    singleMembersPerpageLimit,
    singleMembersTragetPage,
    juniorMembersPerPageLimit,
    juniorMembersTargetPage,
    honoraryMembersPerPageLimit,
    honoraryMembersTargetPage,
    seniorMembersPerPageLimit,
    seniorMembersTargetPage,
    studentMembersPerPageLimit,
    studentMembersTargetPage,
    chosenSortItem,
    setChosenSortItem,
    expandedAccordions,
    setExpandedAccordions,
    paginatedSingleMembers,
    setPaginatedSingleMembers,
    paginatedJuniorMembers,
    setPaginatedJuniorMembers,
    paginatedHonoraryMembers,
    setPaginatedHonoraryMembers,
    paginatedSeniorMembers,
    setPaginatedSeniorMembers,
    paginatedStudentMembers,
    setPaginatedStudentMembers,
    paginatedCorporateMembers,
    setPaginatedCorporateMembers,
    isCorporateExpanded,
    setIsCorporateExpanded,
    isSingleExpanded,
    setIsSingleExpanded,
    isJuniorExpanded,
    setIsJuniorExpanded,
    isHonoraryExpanded,
    setIsHonoraryExpanded,
    isSeniorExpanded,
    setIsSeniorExpanded,
    isStudentExpanded,
    setIsStudentExpanded,
    isSpecialistExpanded,
    setIsSpecialistExpanded,
    shouldFetchSingleMembers,
    setShouldFetchSingleMembers,
    shouldFetchJuniorMembers,
    setShouldFetchJuniorMembers,
    shouldFetchHonoraryMembers,
    setShouldFetchHonoraryMembers,
    shouldFetchSeniorMembers,
    setShoudFetchSeniorMembers,
    shouldFetchStudentMembers,
    setShoudFetchStudentMembers,
    shouldFetchCorporateMembers,
    setShouldFetchCorporateMembers,
    paginatedSpecialistMembers,
    setPaginatedSpecialistMembers,
    shouldFetchSpecialist,
    setShouldFetchSpecialists,
    specialistMembersPerPageLimit,
    specialistMembersTargetPage,
  } = props;
  const translate = useTranslator();

  const history = useHistory();

  const [initialFilters, setInitialFilters] = useState({});

  const [isUserDoneTyping, setIsUserDoneTyping] = useState(true);

  const { language } = useContext(GlobalContext);

  const [genders, setGenders] = useState([]);
  const [isGendersOpen, setIsGendersOpen] = useState(false);

  const [cities, setCities] = useState([]);
  const [isCitiesOpen, setIsCitiesOpen] = useState(false);

  const [states, setStates] = useState([]);
  const [isStatesOpen, setIsStatesOpen] = useState(false);

  const [countries, setCountries] = useState([]);
  const [isCountriesOpen, setIsCountriesOpen] = useState(false);

  const [memberTypes, setMemberTypes] = useState([]);
  const [isMemberTypesOpen, setIsMemberTypesOpen] = useState(false);

  const [sections, setSections] = useState([]);
  const [isSectionsOpen, setIsSectionsOpen] = useState(false);

  const [professionalGroups, setProfessionalGroups] = useState([]);
  const [isProfessionalGroupsOpen, setIsProfessionalGroupsOpen] = useState(false);

  const [workSpecialisations, setWorkSpecialisations] = useState([]);
  const [isWorkSpecialisationsOpen, setIsWorkSpecialisationOpen] = useState(false);

  const [workBranches, setWorkBranches] = useState([]);
  const [isWorkBranchesOpen, setIsWorkBranchesOpen] = useState(false);

  const [commissions, setCommissions] = useState([]);
  const [isCommissionsOpen, setIsCommissionsOpen] = useState(false);

  const [specialists, setSpecialists] = useState([]);
  const [isSpecialistsOpen, setIsSpecialistsOpen] = useState(false);

  const [singleMembersLength, setSingleMembersLength] = useState(0);

  const [juniorMembersLength, setJuniorMembersLength] = useState(0);

  const [honoraryMembersLength, setHonoraryMembersLength] = useState(0);

  const [seniorMembersLength, setSeniorMembersLength] = useState(0);

  const [studentMembersLength, setStudentMembersLength] = useState(0);

  const [specialistMembersLength, setSpecialistMembersLength] = useState(0);

  const [corporateMembersLength, setCorporateMembersLength] = useState(0);

  const updateFetches = useCallback(() => {
    setShouldFetchSingleMembers(true);
    setShouldFetchJuniorMembers(true);
    setShouldFetchHonoraryMembers(true);
    setShoudFetchSeniorMembers(true);
    setShoudFetchStudentMembers(true);
    setShouldFetchCorporateMembers(true);
    setShouldFetchSpecialists(true);
  }, [
    setShouldFetchSingleMembers,
    setShouldFetchJuniorMembers,
    setShouldFetchHonoraryMembers,
    setShoudFetchSeniorMembers,
    setShoudFetchStudentMembers,
    setShouldFetchCorporateMembers,
    setShouldFetchSpecialists,
  ]);
  const sortDropdownItems = [
    { id: 1, label: translate("directory.sort.name.ascending"), sortMapping: 1 },
    { id: 2, label: translate("directory.sort.name.descending"), sortMapping: 2 },
    { id: 3, label: translate("directory.sort.location.ascending"), sortMapping: 3 },
    { id: 4, label: translate("directory.sort.location.descending"), sortMapping: 4 },
  ];

  useEffect(() => {
    if (language) {
      pgAdapter.getInitialFilters(language, (response) => {
        let data = response.data;
        data.cities = data.cities.filter((e) => e);
        setInitialFilters(data);

        let {
          genders,
          cities,
          states,
          countries,
          member_types,
          sections,
          professional_groups,
          work_specialisations,
          work_branches,
          sia_commissions,
          specialists,
        } = data;

        let mappedGenders = mapStringBasedObjects(genders);
        setGenders(mappedGenders);

        let mappedCities = mapStringBasedObjects(cities.filter((e) => e));
        setCities(mappedCities);

        let mappedStates = mapIdOrCodeBasedObjects(states);
        setStates(mappedStates);

        let mappedCountries = mapIdOrCodeBasedObjects(countries);
        setCountries(mappedCountries);

        let mappedMemberTypes = mapIdOrCodeBasedObjects(member_types);
        setMemberTypes(mappedMemberTypes);

        let mappedSections = mapIdOrCodeBasedObjects(sections);
        setSections(mappedSections);

        let mappedProfessionalGroups = mapIdOrCodeBasedObjects(professional_groups);
        setProfessionalGroups(mappedProfessionalGroups);

        let mappedWorkProfessionalGroups = mapIdOrCodeBasedObjects(work_specialisations);
        setWorkSpecialisations(mappedWorkProfessionalGroups);

        let mappedWorkBranches = mapIdOrCodeBasedObjects(work_branches);
        setWorkBranches(mappedWorkBranches);

        let mappedCommissions = mapIdOrCodeBasedObjects(sia_commissions);
        setCommissions(mappedCommissions);

        let mappedSpecialists = mapIdOrCodeBasedObjects(specialists);
        setSpecialists(mappedSpecialists);
      });
    }
  }, [language]);

  const castSingleMembers = useCallback(
    (members) => {
      let castedMembers = members.map((member) => {
        let quinaryTags = member.professional_groups.map((pg_id) => initialFilters.professional_groups.find((e) => pg_id === e.id).code);
        let quaternaryTags = member?.sections.map((section_id) => initialFilters.sections.find((e) => section_id === e.id).code);
        return {
          academic_title: member.academic_title,
          title_translated: member.title_translated,
          firstname: member.firstname,
          lastname: member.lastname,
          member_type: initialFilters.member_types.find((e) => member.member_type_id === e.id).name,
          city: member.city,
          quinaryTags,
          quaternaryTags,
          url: `directory/member/${member.hashed_member_id}`,
          onClick: () => updateFetches(),
        };
      });
      return castedMembers;
    },
    [initialFilters, updateFetches]
  );

  const castCorporateMembers = useCallback(
    (members) => {
      let castedMembers = members.map((member) => {
        let branches = member.branches.map((branch_id) => initialFilters.work_branches.find((e) => branch_id === e.id).name);
        return {
          firstname: member.firstname || member.name,
          lastname: member.lastname || "",
          member_type: initialFilters.member_types.find((e) => member.member_type_id === e.id).name,
          city: member.city,
          tertiaryTags: branches,
          url: `directory/member/${member.hashed_member_id}`,
          onClick: () => updateFetches(),
        };
      });
      return castedMembers;
    },
    [initialFilters, updateFetches]
  );

  function mapStringBasedObjects(items) {
    return items.map((item, index) => ({ id: index, label: item }));
  }

  function mapIdOrCodeBasedObjects(items, isCommissions = false) {
    if (isCommissions) return items.map(({ id, name, code }) => ({ id, label: code }));
    return items.map(({ id, name, code }) => ({ id, label: name || code }));
  }

  const updateTotalNumbers = useCallback((total_members) => {
    setCorporateMembersLength(total_members.corporates);
    setSingleMembersLength(total_members.singles);
    setJuniorMembersLength(total_members.juniors);
    setHonoraryMembersLength(total_members.honorarys);
    setSeniorMembersLength(total_members.seniors);
    setStudentMembersLength(total_members.students);
    setSpecialistMembersLength(total_members.specialists);
  }, []);

  const fetchSingleMembers = useCallback(
    (memberType, item_per_page, page) => {
      const data = {
        item_per_page,
        page,
        name: selectedFilters.name,
        "gender[]": `{${selectedFilters.genders.join(",")}}`,
        "city[]": `{${selectedFilters.cities.join(",")}}`,
        "state[]": `{${selectedFilters.states.join(",")}}`,
        "country[]": `{${selectedFilters.countries.join(",")}}`,
        "member_type[]": `{${selectedFilters.member_types.join(",")}}`,
        "section[]": `{${selectedFilters.sections.join(",")}}`,
        "professional_group[]": `{${selectedFilters.professional_groups.join(",")}}`,
        "commission[]": `{${selectedFilters.sia_commissions.join(",")}}`,
        "specialist[]": `{${selectedFilters.specialists.join(",")}}`,
        "work_specialisation[]": `{${selectedFilters.work_specialisations.join(",")}}`,
        "work_branch[]": `{${selectedFilters.work_branches.join(",")}}`,
        is_specialist: Array.isArray(memberType) ? true : selectedFilters.is_specialist,
        has_references: selectedFilters.has_ref_objects,
        has_jobs: selectedFilters.has_jobs,
        chosen_sort: chosenSortItem.sortMapping,
        "current_selected_type[]": `{${Array.isArray(memberType) ? memberType.join(",") : memberType}}`,
      };

      const updateStringBasedFilter = (filterName, list) => {
        list = list || [];
        let initialFilterValues = initialFilters[filterName];
        let mappedInitialFilters = mapStringBasedObjects(initialFilterValues);
        let currentActiveFilters = mappedInitialFilters.map((filter) => ({
          ...filter,
          active: selectedFilters[filterName].includes(filter.label) && list.includes(filter.label),
          disabled: !list.includes(filter.label),
        }));
        return currentActiveFilters;
      };

      const updateIdBasedFilter = (filterName, list) => {
        list = list || [];
        let initialFilterValues = initialFilters[filterName];
        let mappedInitialFilters = mapIdOrCodeBasedObjects(initialFilterValues, filterName === "sia_commissions");
        let currentActiveFilters = mappedInitialFilters.map((filter) => ({
          ...filter,
          active: selectedFilters[filterName].includes(filter.id) && list.includes(filter.id),
          disabled: !list.includes(filter.id),
        }));
        return currentActiveFilters;
      };

      pgAdapter.getIndividualMembers(data, (response) => {
        let { data } = response;
        let {
          genders,
          cities,
          states,
          countries,
          member_types,
          sections,
          professional_groups,
          work_specialisations,
          work_branches,
          commissions,
          specialists,
        } = data.filters;
        if (Object.keys(initialFilters).length > 0) {
          let currentActiveGenders = updateStringBasedFilter("genders", genders);
          setGenders(currentActiveGenders);
          let currentActiveCities = updateStringBasedFilter("cities", cities);
          setCities(currentActiveCities);
          let currentActiveStates = updateIdBasedFilter("states", states);
          setStates(currentActiveStates);
          let currentActiveCountries = updateIdBasedFilter("countries", countries);
          setCountries(currentActiveCountries);
          let currentActiveMemberTypes = updateIdBasedFilter("member_types", member_types);
          setMemberTypes(currentActiveMemberTypes);
          let currentActiveSections = updateIdBasedFilter("sections", sections);
          setSections(currentActiveSections);
          let currentActiveProfessionalGroups = updateIdBasedFilter("professional_groups", professional_groups);
          setProfessionalGroups(currentActiveProfessionalGroups);
          let currentActiveWorkSpecialisations = updateIdBasedFilter("work_specialisations", work_specialisations);
          setWorkSpecialisations(currentActiveWorkSpecialisations);
          let currentActiveWorkBranches = updateIdBasedFilter("work_branches", work_branches);
          setWorkBranches(currentActiveWorkBranches);
          let currentActiveCommissions = updateIdBasedFilter("sia_commissions", commissions);
          setCommissions(currentActiveCommissions);
          let currentActiveSpecialists = updateIdBasedFilter("specialists", specialists);
          setSpecialists(currentActiveSpecialists);
          let { members, total_members } = data;
          updateTotalNumbers(total_members);
          // if (!Array.isArray(memberType)) {
          // } else {
          //   setSpecialistMembersLength(total_members.specialists);
          // }
          if (memberType === 42) {
            if (members && total_members.singles > 0) {
              setPaginatedSingleMembers(castSingleMembers(members));
            } else if (!members && total_members.singles > 0) {
              setSingleMembersTragetPage(0);
            } else {
              setSingleMembersTragetPage(0);
              setPaginatedSingleMembers([]);
            }
          }

          if (memberType === 51) {
            if (members && total_members.juniors > 0) {
              setPaginatedJuniorMembers(castSingleMembers(members));
            } else if (!members && total_members.juniors > 0) {
              setJuniorMembersTagrgetPage(0);
            } else {
              setJuniorMembersTagrgetPage(0);
              setPaginatedJuniorMembers([]);
            }
          }

          if (memberType === 44) {
            if (members && total_members.honorarys > 0) {
              setPaginatedHonoraryMembers(castSingleMembers(members));
            } else if (!members && total_members.honorarys > 0) {
              setHonoraryMembersTargetPage(0);
            } else {
              setHonoraryMembersTargetPage(0);
              setPaginatedHonoraryMembers([]);
            }
          }

          if (memberType === 45) {
            if (members && total_members.seniors > 0) {
              setPaginatedSeniorMembers(castSingleMembers(members));
            } else if (!members && total_members.seniors > 0) {
              setSeniorMembersTargetPage(0);
            } else {
              setSeniorMembersTargetPage(0);
              setPaginatedSeniorMembers([]);
            }
          }

          if (memberType === 43) {
            if (members && total_members.students > 0) {
              setPaginatedStudentMembers(castSingleMembers(members));
            } else if (!members && total_members.students > 0) {
              setStudentMembersTargetPage(0);
            } else {
              setStudentMembersTargetPage(0);
              setPaginatedStudentMembers([]);
            }
          }
          if (Array.isArray(memberType)) {
            if (members && total_members.specialists > 0) {
              setPaginatedSpecialistMembers(castSingleMembers(members));
            } else if (!members && total_members.students > 0) {
              setSpecialistMembersTargetPage(0);
            } else {
              setSpecialistMembersTargetPage(0);
              setPaginatedSpecialistMembers([]);
            }
          }
        }
      });
    },
    [
      initialFilters,
      selectedFilters,
      setPaginatedSingleMembers,
      setPaginatedJuniorMembers,
      setSingleMembersTragetPage,
      setJuniorMembersTagrgetPage,
      setHonoraryMembersTargetPage,
      setSeniorMembersTargetPage,
      setStudentMembersTargetPage,
      castSingleMembers,
      chosenSortItem,
      updateTotalNumbers,
      setPaginatedHonoraryMembers,
      setPaginatedSeniorMembers,
      setPaginatedStudentMembers,
      setPaginatedSpecialistMembers,
      setSpecialistMembersTargetPage,
    ]
  );

  const fetchCorporateMembers = useCallback(
    (memberType, item_per_page, page) => {
      const data = {
        item_per_page,
        page,
        name: selectedFilters.name,
        "gender[]": `{${selectedFilters.genders.join(",")}}`,
        "city[]": `{${selectedFilters.cities.join(",")}}`,
        "state[]": `{${selectedFilters.states.join(",")}}`,
        "country[]": `{${selectedFilters.countries.join(",")}}`,
        "member_type[]": `{${selectedFilters.member_types.join(",")}}`,
        "section[]": `{${selectedFilters.sections.join(",")}}`,
        "professional_group[]": `{${selectedFilters.professional_groups.join(",")}}`,
        "commission[]": `{${selectedFilters.sia_commissions.join(",")}}`,
        "specialist[]": `{${selectedFilters.specialists.join(",")}}`,
        "work_specialisation[]": `{${selectedFilters.work_specialisations.join(",")}}`,
        "work_branch[]": `{${selectedFilters.work_branches.join(",")}}`,
        is_specialist: `${selectedFilters.is_specialist}`,
        has_references: selectedFilters.has_ref_objects,
        has_jobs: selectedFilters.has_jobs,
        chosen_sort: chosenSortItem.sortMapping,
        "current_selected_type[]": `{${memberType.join(",")}}`,
      };

      const updateStringBasedFilter = (filterName, list) => {
        list = list || [];
        let initialFilterValues = initialFilters[filterName];
        let mappedInitialFilters = mapStringBasedObjects(initialFilterValues);
        let currentActiveFilters = mappedInitialFilters.map((filter) => ({
          ...filter,
          active: selectedFilters[filterName].includes(filter.label) && list.includes(filter.label),
          disabled: !list.includes(filter.label),
        }));
        return currentActiveFilters;
      };

      const updateIdBasedFilter = (filterName, list) => {
        list = list || [];
        let initialFilterValues = initialFilters[filterName];
        let mappedInitialFilters = mapIdOrCodeBasedObjects(initialFilterValues, filterName === "sia_commissions");
        let currentActiveFilters = mappedInitialFilters.map((filter) => ({
          ...filter,
          active: selectedFilters[filterName].includes(filter.id) && list.includes(filter.id),
          disabled: !list.includes(filter.id),
        }));
        return currentActiveFilters;
      };

      pgAdapter.getCorporateMembers(data, (response) => {
        let { data } = response;
        let {
          genders,
          cities,
          states,
          countries,
          member_types,
          sections,
          professional_groups,
          work_specialisations,
          work_branches,
          commissions,
          specialists,
        } = data.filters;
        if (Object.values(initialFilters).length > 0) {
          let currentActiveGenders = updateStringBasedFilter("genders", genders);
          setGenders(currentActiveGenders);
          let currentActiveCities = updateStringBasedFilter("cities", (cities && cities.filter((e) => e)) || []);
          setCities(currentActiveCities);
          let currentActiveStates = updateIdBasedFilter("states", states);
          setStates(currentActiveStates);
          let currentActiveCountries = updateIdBasedFilter("countries", countries);
          setCountries(currentActiveCountries);
          let currentActiveMemberTypes = updateIdBasedFilter("member_types", member_types);
          setMemberTypes(currentActiveMemberTypes);
          let currentActiveSections = updateIdBasedFilter("sections", sections);
          setSections(currentActiveSections);
          let currentActiveProfessionalGroups = updateIdBasedFilter("professional_groups", professional_groups);
          setProfessionalGroups(currentActiveProfessionalGroups);
          let currentActiveWorkSpecialisations = updateIdBasedFilter("work_specialisations", work_specialisations);
          setWorkSpecialisations(currentActiveWorkSpecialisations);
          let currentActiveWorkBranches = updateIdBasedFilter("work_branches", work_branches);
          setWorkBranches(currentActiveWorkBranches);
          let currentActiveCommissions = updateIdBasedFilter("sia_commissions", commissions);
          setCommissions(currentActiveCommissions);
          let currentActiveSpecialists = updateIdBasedFilter("specialists", specialists);
          setSpecialists(currentActiveSpecialists);
          let { members, total_members } = data;
          updateTotalNumbers(total_members);
          if (members && total_members.corporates > 0) {
            setPaginatedCorporateMembers(castCorporateMembers(members));
          } else if (!members && total_members.corporates > 0) {
            setCorporateMembersTargetPage(0);
          } else {
            setPaginatedCorporateMembers([]);
            setCorporateMembersTargetPage(0);
          }
        }
      });
    },
    [
      initialFilters,
      selectedFilters,
      setCorporateMembersTargetPage,
      castCorporateMembers,
      chosenSortItem,
      updateTotalNumbers,
      setPaginatedCorporateMembers,
    ]
  );

  const fetchSiaMembers = useCallback(() => {
    const tabMemberTypeArray = [
      [
        0,
        {
          memberType: [46, 47],
          item_per_page: corporateMembersPerPageLimit,
          page: corporateMembersTargetPage,
          isAccordionExpanded: isCorporateExpanded,
          shouldFetch: shouldFetchCorporateMembers,
        },
      ],
      [
        1,
        {
          memberType: 42,
          item_per_page: singleMembersPerpageLimit,
          page: singleMembersTragetPage,
          isAccordionExpanded: isSingleExpanded,
          shouldFetch: shouldFetchSingleMembers,
        },
      ],
      [
        2,
        {
          memberType: 51,
          item_per_page: juniorMembersPerPageLimit,
          page: juniorMembersTargetPage,
          isAccordionExpanded: isJuniorExpanded,
          shouldFetch: shouldFetchJuniorMembers,
        },
      ],
      [
        3,
        {
          memberType: 44,
          item_per_page: honoraryMembersPerPageLimit,
          page: honoraryMembersTargetPage,
          isAccordionExpanded: isHonoraryExpanded,
          shouldFetch: shouldFetchHonoraryMembers,
        },
      ],
      [
        4,
        {
          memberType: 45,
          item_per_page: seniorMembersPerPageLimit,
          page: seniorMembersTargetPage,
          isAccordionExpanded: isSeniorExpanded,
          shouldFetch: shouldFetchSeniorMembers,
        },
      ],
      [
        5,
        {
          memberType: 43,
          item_per_page: studentMembersPerPageLimit,
          page: studentMembersTargetPage,
          isAccordionExpanded: isStudentExpanded,
          shouldFetch: shouldFetchStudentMembers,
        },
      ],
      [
        6,
        {
          memberType: [42, 51, 44, 45, 43],
          item_per_page: specialistMembersPerPageLimit,
          page: specialistMembersTargetPage,
          isAccordionExpanded: isSpecialistExpanded,
          shouldFetch: shouldFetchSpecialist,
        },
      ],
    ];

    const tabToMemberTypeMap = new Map(tabMemberTypeArray);

    let isInitialLoad = Object.values(initialFilters).length === 0;
    if (expandedAccordions.length === 0 && shouldFetchCorporateMembers && !isInitialLoad) {
      fetchCorporateMembers([46, 47], corporateMembersPerPageLimit, corporateMembersTargetPage);
      setShouldFetchCorporateMembers(false);
    }

    for (let key of expandedAccordions) {
      let memberType = tabToMemberTypeMap.get(key).memberType;
      let item_per_page = tabToMemberTypeMap.get(key).item_per_page;
      let page = tabToMemberTypeMap.get(key).page;
      let isAccordionExpanded = tabToMemberTypeMap.get(key).isAccordionExpanded;
      let shouldFetch = tabToMemberTypeMap.get(key).shouldFetch;
      if (Array.isArray(memberType) && memberType.length === 2) {
        if (!isInitialLoad && isUserDoneTyping && isAccordionExpanded && shouldFetch) {
          fetchCorporateMembers(memberType, item_per_page, page);
        }
      } else {
        if (isUserDoneTyping && !isInitialLoad && isAccordionExpanded && shouldFetch) {
          fetchSingleMembers(memberType, item_per_page, page);
        }
      }
    }
  }, [
    singleMembersPerpageLimit,
    singleMembersTragetPage,
    juniorMembersPerPageLimit,
    juniorMembersTargetPage,
    honoraryMembersPerPageLimit,
    honoraryMembersTargetPage,
    seniorMembersPerPageLimit,
    seniorMembersTargetPage,
    studentMembersPerPageLimit,
    studentMembersTargetPage,
    corporateMembersPerPageLimit,
    corporateMembersTargetPage,
    fetchSingleMembers,
    fetchCorporateMembers,
    initialFilters,
    isUserDoneTyping,
    expandedAccordions,
    isCorporateExpanded,
    isSingleExpanded,
    isJuniorExpanded,
    isHonoraryExpanded,
    isSeniorExpanded,
    isStudentExpanded,
    shouldFetchSingleMembers,
    shouldFetchJuniorMembers,
    shouldFetchHonoraryMembers,
    shouldFetchSeniorMembers,
    shouldFetchStudentMembers,
    shouldFetchCorporateMembers,
    setShouldFetchCorporateMembers,
    isSpecialistExpanded,
    shouldFetchSpecialist,
    specialistMembersTargetPage,
    specialistMembersPerPageLimit,
  ]);

  useEffect(() => {
    if (language) {
      const baseDirectoryUrl = `/${language}/directory?`;
      let stateEntries = Object.entries(selectedFilters);
      let stateString = stateEntries
        .filter(([_, value]) => (Array.isArray(value) ? value.length > 0 : value))
        .map(([key, value]) => `${key}=${value}`)
        .join("&");
      let selectedSortString = `sort=${chosenSortItem.sortMapping}`;
      let searchUrl = [stateString, selectedSortString].filter((e) => e).join("&");
      let url = `${baseDirectoryUrl}${searchUrl}`;
      history.push(url, { shallow: true });
    }
  }, [selectedFilters, history, language, chosenSortItem]);

  const updateSelectedFilter = (filterName, updatedItems, item) => {
    if (filterName !== focusedFilter) {
      setFocusedFilter(filterName);
    }
    const nonIdBasedFilterNames = ["genders", "cities"];

    setSelectedFilters((filtersState) => ({
      ...filtersState,
      [filterName]: updatedItems.filter((item) => item.active).map((item) => (nonIdBasedFilterNames.includes(filterName) ? item.label : item.id)),
    }));
    switch (filterName) {
      case "genders":
        setGenders(updatedItems);
        break;
      case "cities":
        setCities(updatedItems);
        break;
      case "states":
        setStates(updatedItems);
        break;
      case "countries":
        setCountries(updatedItems);
        break;
      case "member_types":
        setMemberTypes(updatedItems);
        break;
      case "sections":
        setSections(updatedItems);
        break;
      case "professional_groups":
        setProfessionalGroups(updatedItems);
        break;
      case "work_specialisations":
        setWorkSpecialisations(updatedItems);
        break;
      case "work_branches":
        setWorkBranches(updatedItems);
        break;
      case "sia_commissions":
        setCommissions(updatedItems);
        break;
      case "specialists":
        setSpecialists(updatedItems);
        break;
      default:
        break;
    }
  };
  const [isUserAbleToToggleCheckBoxes, setIsUserAbleToToggleCheckBoxes] = useState(true);
  const [focusedFilter, setFocusedFilter] = useState(null);
  const [shouldFetchMembers, setShouldFetchMembers] = useState(true);

  const areAllInputClosed = () => {
    let inputs = [
      isGendersOpen,
      isCitiesOpen,
      isStatesOpen,
      isCountriesOpen,
      isMemberTypesOpen,
      isSectionsOpen,
      isProfessionalGroupsOpen,
      isWorkSpecialisationsOpen,
      isWorkBranchesOpen,
      isCommissionsOpen,
      isSpecialistsOpen,
    ];
    return inputs.every((e) => !e);
  };

  useEffect(() => {
    if (shouldFetchMembers) {
      fetchSiaMembers();
    }
  }, [fetchSiaMembers, shouldFetchMembers]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setIsUserDoneTyping(true);
      updateFetches();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [props.selectedFilters.name, updateFetches]);

  const updateName = (name) => {
    setSelectedFilters((state) => ({ ...state, name: name }));
    setIsUserDoneTyping(false);
  };

  const updateExpandedAccordion = useCallback(
    (index) => {
      let stateCopy = [...expandedAccordions];
      let targetIndex = stateCopy.findIndex((e) => e === index);
      if (targetIndex < 0) {
        stateCopy.push(index);
      } else {
        stateCopy.splice(targetIndex, 1);
      }
      setExpandedAccordions(stateCopy);
      // const hastStateChanged = expandedAccordions.every((e) => stateCopy.includes(e));
      // if (hastStateChanged) {
      // }
    },
    [expandedAccordions, setExpandedAccordions]
  );

  return (
    <Page title={"directory.name"}>
      <Content className={"Directory"} width={"full"}>
        <div className="bx--grid">
          <div className={"bx--row"}>
            <div className="bx--col-lg-8 bx--col-md-8 Directory-Name">
              <TextInput
                id={"name"}
                className="Directory-Search"
                placeholder={translate("directory.filter.name")}
                value={props.selectedFilters.name}
                onChange={(event) => {
                  let name = event.target.value;
                  updateName(name);
                }}
                icon={<Search24 />}
              />
            </div>
            <div className="bx--col-lg-4 bx--col-md-4">
              <MultiselectDropdown
                id="genders"
                label={translate("directory.filter.gender")}
                isOpen={isGendersOpen}
                toggle={() => {
                  if (!isGendersOpen) {
                    setFocusedFilter("genders");
                    setIsGendersOpen(true);
                  }
                }}
                onClose={() => {
                  setShouldFetchMembers(true);
                  updateFetches();
                  setIsGendersOpen(false);
                }}
                items={genders}
                onClickItem={(event, item, updatedItems) => {
                  if (!areAllInputClosed() && focusedFilter !== "genders") {
                    return;
                  }
                  updateSelectedFilter("genders", updatedItems, item);
                  updateFetches();
                  if (isGendersOpen) {
                    setShouldFetchMembers(false);
                  }
                }}
              />
            </div>
            <div className="bx--col-lg-4 bx--col-md-4 Directory-Even">
              <MultiselectDropdown
                id="directory-locations"
                label={translate("directory.filter.location")}
                isOpen={isCitiesOpen}
                toggle={() => {
                  if (!isCitiesOpen) {
                    setFocusedFilter("cities");
                    setIsCitiesOpen(true);
                  }
                }}
                onClose={() => {
                  setShouldFetchMembers(true);
                  updateFetches();
                  setIsCitiesOpen(false);
                }}
                items={cities}
                onClickItem={(event, item, updatedItems) => {
                  if (!areAllInputClosed() && focusedFilter !== "cities") {
                    return;
                  }
                  updateSelectedFilter("cities", updatedItems, item);
                  updateFetches();
                  if (isCitiesOpen) {
                    setShouldFetchMembers(false);
                  }
                }}
              />
            </div>
          </div>
          <div className={"bx--row"}>
            <div className="bx--col-lg-4 bx--col-md-4">
              <MultiselectDropdown
                id="states"
                label={translate("directory.filter.canton")}
                isOpen={isStatesOpen}
                toggle={() => {
                  if (!isStatesOpen) {
                    setFocusedFilter("states");
                    setIsStatesOpen(true);
                  }
                }}
                onClose={() => {
                  setShouldFetchMembers(true);
                  updateFetches();
                  setIsStatesOpen(false);
                }}
                items={states}
                onClickItem={(event, item, updatedItems) => {
                  if (!areAllInputClosed() && focusedFilter !== "states") {
                    return;
                  }
                  updateSelectedFilter("states", updatedItems);
                  updateFetches();
                  if (isStatesOpen) {
                    setShouldFetchMembers(false);
                  }
                }}
              />
            </div>
            <div className="bx--col-lg-4 bx--col-md-4 Directory-Even">
              <MultiselectDropdown
                id="countries"
                label={translate("directory.filter.land")}
                isOpen={isCountriesOpen}
                toggle={() => {
                  if (!isCountriesOpen) {
                    setFocusedFilter("countries");
                    setIsCountriesOpen(true);
                  }
                }}
                onClose={() => {
                  setShouldFetchMembers(true);
                  updateFetches();
                  setIsCountriesOpen(false);
                }}
                items={countries}
                onClickItem={(event, item, updatedItems) => {
                  if (!areAllInputClosed() && focusedFilter !== "countries") {
                    return;
                  }
                  updateSelectedFilter("countries", updatedItems, item);
                  updateFetches();
                  if (isCountriesOpen) {
                    setShouldFetchMembers(false);
                  }
                }}
              />
            </div>
            <div className="bx--col-lg-4 bx--col-md-4">
              <MultiselectDropdown
                id="memberTypes"
                label={translate("directory.filter.member-type")}
                isOpen={isMemberTypesOpen}
                toggle={() => {
                  if (!isMemberTypesOpen) {
                    setFocusedFilter("member_types");
                    setIsMemberTypesOpen(true);
                  }
                }}
                onClose={() => {
                  setShouldFetchMembers(true);
                  updateFetches();
                  setIsMemberTypesOpen(false);
                }}
                items={memberTypes}
                onClickItem={(event, item, updatedItems) => {
                  if (!areAllInputClosed() && focusedFilter !== "member_types") {
                    return;
                  }
                  updateSelectedFilter("member_types", updatedItems, item);
                  updateFetches();
                  if (isMemberTypesOpen) {
                    setShouldFetchMembers(false);
                  }
                }}
              />
            </div>
            <div className="bx--col-lg-4 bx--col-md-4 Directory-Even">
              <MultiselectDropdown
                id="sections"
                label={translate("directory.filter.sections")}
                isOpen={isSectionsOpen}
                toggle={() => {
                  if (!isSectionsOpen) {
                    setFocusedFilter("sections");
                    setIsSectionsOpen(true);
                  }
                }}
                onClose={() => {
                  setShouldFetchMembers(true);
                  updateFetches();
                  setIsSectionsOpen(false);
                }}
                items={sections}
                onClickItem={(event, item, updatedItems) => {
                  if (!areAllInputClosed() && focusedFilter !== "sections") {
                    return;
                  }
                  updateSelectedFilter("sections", updatedItems);
                  updateFetches();
                  if (isSectionsOpen) {
                    setShouldFetchMembers(false);
                  }
                }}
              />
            </div>
          </div>
          <div className={"bx--row"}>
            <div className="bx--col-lg-4 bx--col-md-4">
              <MultiselectDropdown
                id="professionalGroups"
                label={translate("directory.filter.professional-groups")}
                isOpen={isProfessionalGroupsOpen}
                toggle={() => {
                  if (!isProfessionalGroupsOpen) {
                    setFocusedFilter("professional_groups");
                    setIsProfessionalGroupsOpen(true);
                  }
                }}
                onClose={() => {
                  setShouldFetchMembers(true);
                  updateFetches();
                  setIsProfessionalGroupsOpen(false);
                }}
                items={professionalGroups}
                onClickItem={(event, item, updatedItems) => {
                  if (!areAllInputClosed() && focusedFilter !== "professional_groups") {
                    return;
                  }
                  updateSelectedFilter("professional_groups", updatedItems);
                  updateFetches();
                  if (isProfessionalGroupsOpen) {
                    setShouldFetchMembers(false);
                  }
                }}
              />
            </div>
            <div className="bx--col-lg-4 bx--col-md-4 Directory-Even">
              <MultiselectDropdown
                id="workSpecialisations"
                label={translate("directory.filter.activities")}
                isOpen={isWorkSpecialisationsOpen}
                toggle={() => {
                  if (!isWorkSpecialisationsOpen) {
                    setFocusedFilter("work_specialisations");
                    setIsWorkSpecialisationOpen(true);
                  }
                }}
                onClose={() => {
                  setShouldFetchMembers(true);
                  updateFetches();
                  setIsWorkSpecialisationOpen(false);
                }}
                items={workSpecialisations}
                onClickItem={(event, item, updatedItems) => {
                  if (!areAllInputClosed() && focusedFilter !== "work_specialisations") {
                    return;
                  }
                  updateSelectedFilter("work_specialisations", updatedItems);
                  updateFetches();
                  if (isWorkSpecialisationsOpen) {
                    setShouldFetchMembers(false);
                  }
                }}
              />
            </div>
            <div className="bx--col-lg-4 bx--col-md-4">
              <MultiselectDropdown
                id="workBranches"
                label={translate("directory.filter.branches")}
                isOpen={isWorkBranchesOpen}
                toggle={() => {
                  if (!isWorkBranchesOpen) {
                    setFocusedFilter("work_branches");
                    setIsWorkBranchesOpen(true);
                  }
                }}
                onClose={() => {
                  setShouldFetchMembers(true);
                  updateFetches();
                  setIsWorkBranchesOpen(false);
                }}
                items={workBranches}
                onClickItem={(event, item, updatedItems) => {
                  if (!areAllInputClosed() && focusedFilter !== "work_branches") {
                    return;
                  }
                  updateSelectedFilter("work_branches", updatedItems);
                  updateFetches();
                  if (isWorkBranchesOpen) {
                    setShouldFetchMembers(false);
                  }
                }}
              />
            </div>
            <div className="bx--col-lg-4 bx--col-md-4 Directory-Even">
              <MultiselectDropdown
                id="sia_commissions"
                label={translate("directory.filter.commissions")}
                isOpen={isCommissionsOpen}
                toggle={() => {
                  if (!isCommissionsOpen) {
                    setFocusedFilter("sia_commissions");
                    setIsCommissionsOpen(true);
                  }
                }}
                onClose={() => {
                  setShouldFetchMembers(true);
                  updateFetches();
                  setIsCommissionsOpen(false);
                }}
                items={commissions}
                onClickItem={(event, item, updatedItems) => {
                  if (!areAllInputClosed() && focusedFilter !== "sia_commissions") {
                    return;
                  }
                  updateSelectedFilter("sia_commissions", updatedItems);
                  updateFetches();
                  if (isCommissionsOpen) {
                    setShouldFetchMembers(false);
                  }
                }}
              />
            </div>
          </div>
          <div className={"bx--row"}>
            <div className="bx--col-lg-4 bx--col-md-4"></div>
            <div className="bx--col-lg-4 Directory-CheckBoxes bx--col-md-4 Directory-Even">
              <div className={"check-box-wrapper"}>
                <div
                  onClick={(e) => {
                    setFocusedFilter("refs");
                    let areClosed = areAllInputClosed();
                    if (!areClosed) {
                      setIsUserAbleToToggleCheckBoxes(false);
                    }
                  }}
                >
                  <Checkbox
                    id={"refs"}
                    label={translate("directory.filter.checkbox.reference-objects")}
                    value={selectedFilters.has_ref_objects}
                    onChange={(event, value) => {
                      if (isUserAbleToToggleCheckBoxes) {
                        setSelectedFilters((state) => ({ ...state, has_ref_objects: !state.has_ref_objects }));
                      }
                      updateFetches();
                      setIsUserAbleToToggleCheckBoxes(true);
                    }}
                  />
                </div>
                <div
                  onClick={(e) => {
                    setFocusedFilter("jobs");
                    let areClosed = areAllInputClosed();
                    if (!areClosed) {
                      setIsUserAbleToToggleCheckBoxes(false);
                    }
                  }}
                >
                  <Checkbox
                    id={"jobs"}
                    label={translate("directory.filter.checkbox.job-offers")}
                    value={selectedFilters.has_jobs}
                    onChange={(event, value) => {
                      if (isUserAbleToToggleCheckBoxes) {
                        setSelectedFilters((state) => ({ ...state, has_jobs: !state.has_jobs }));
                      }
                      updateFetches();
                      setIsUserAbleToToggleCheckBoxes(true);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="bx--col-lg-4 Directory-CheckBoxes bx--col-md-4 Directory-Even">
              <div className={"check-box-wrapper"}>
                <label className="Directory-CheckBoxes-Expert bx--label">{translate("directory.filter.expert")}</label>
                <div
                  onClick={(e) => {
                    setFocusedFilter("specialaist");
                    let areClosed = areAllInputClosed();
                    if (!areClosed) {
                      setIsUserAbleToToggleCheckBoxes(false);
                    }
                  }}
                >
                  <Checkbox
                    id={"specialaist"}
                    label={translate("directory.filter.expert.label")}
                    value={selectedFilters.is_specialist}
                    onChange={(event, value) => {
                      if (isUserAbleToToggleCheckBoxes) {
                        setSelectedFilters((state) => ({ ...state, is_specialist: !state.is_specialist }));
                      }
                      if (!value && specialists.filter((e) => e.active).length > 0) {
                        let resetedSpecialists = specialists.map((e) => ({ ...e, active: false }));
                        setSpecialists(resetedSpecialists);
                        setSelectedFilters((state) => ({ ...state, specialists: [] }));
                      }
                      updateFetches();
                      setIsUserAbleToToggleCheckBoxes(true);
                    }}
                  />
                </div>
              </div>
              <MultiselectDropdown
                id="sia_specialist"
                label={translate("directory.filter.specialist")}
                isOpen={isSpecialistsOpen}
                toggle={() => {
                  if (!isSpecialistsOpen) {
                    setFocusedFilter("specialists");
                    setIsSpecialistsOpen(true);
                  }
                }}
                onClose={() => {
                  setShouldFetchMembers(true);
                  updateFetches();
                  setIsSpecialistsOpen(false);
                }}
                items={specialists}
                onClickItem={(event, item, updatedItems) => {
                  if (!areAllInputClosed() && focusedFilter !== "specialists") {
                    return;
                  }
                  updateSelectedFilter("specialists", updatedItems);
                  updateFetches();
                  if (isSpecialistsOpen) {
                    setShouldFetchMembers(false);
                  }
                }}
                disabled={!selectedFilters.is_specialist}
              />
            </div>
          </div>

          <Dropdown
            id="sort"
            className={"sort"}
            items={sortDropdownItems}
            label={translate("sort.by")}
            value={chosenSortItem}
            onChange={(event, item) => {
              let selectedSort = sortDropdownItems.find((e) => e.id === item.id);
              setChosenSortItem(selectedSort);
              expandedAccordions.forEach((e) => {
                switch (e) {
                  case 0:
                    setShouldFetchCorporateMembers(true);
                    setCorporateMembersTargetPage(0);
                    break;
                  case 1:
                    setShouldFetchSingleMembers(true);
                    setSingleMembersTragetPage(0);
                    break;
                  case 2:
                    setShouldFetchJuniorMembers(true);
                    setJuniorMembersTagrgetPage(0);
                    break;
                  case 3:
                    setShouldFetchHonoraryMembers(true);
                    setHonoraryMembersTargetPage(0);
                    break;
                  case 4:
                    setShoudFetchSeniorMembers(true);
                    setSeniorMembersTargetPage(0);
                    break;
                  case 5:
                    setShoudFetchStudentMembers(true);
                    setStudentMembersTargetPage(0);
                    break;
                  case 6:
                    setShouldFetchSpecialists(true);
                    setSpecialistMembersTargetPage(0);
                    break;
                  default:
                    break;
                }
              });
            }}
            inline={true}
          />
        </div>

        <div className="sia-margin-04-top sia-margin-04-bottom">
          <Accordion
            items={[
              {
                title: `${translate("directory.content-nav.corporates")} (${corporateMembersLength})`,
                content: (
                  <>
                    <DirectoryPreviews members={paginatedCorporateMembers} elementToRender={RouterLink} />
                    <Pagination
                      totalItems={corporateMembersLength}
                      items={paginatedCorporateMembers}
                      pageSize={props.corporateMembersPerPageLimit}
                      pageSizes={[12, 24, 36, 48]}
                      page={props.corporateMembersTargetPage + 1}
                      translate={translate}
                      onChange={(targetPage, perPageLimit) => {
                        props.setCorporateMembersPerPageLimit(perPageLimit);
                        props.setCorporateMembersTargetPage(targetPage - 1);
                        if (!shouldFetchCorporateMembers) {
                          setShouldFetchCorporateMembers(true);
                        }
                      }}
                    />
                  </>
                ),
                onHeadingClick: () => {
                  updateExpandedAccordion(0);
                  if (paginatedCorporateMembers.length > 0) setShouldFetchCorporateMembers(false);
                  else setShouldFetchCorporateMembers(true);
                  setIsCorporateExpanded(!isCorporateExpanded);
                },
                open: isCorporateExpanded,
              },
              {
                title: `${translate("directory.content-nav.single")} (${singleMembersLength})`,
                content: (
                  <>
                    <DirectoryPreviews members={paginatedSingleMembers} elementToRender={RouterLink} />
                    <Pagination
                      totalItems={singleMembersLength}
                      items={paginatedSingleMembers}
                      pageSize={props.singleMembersPerpageLimit}
                      pageSizes={[12, 24, 36, 48]}
                      page={props.singleMembersTragetPage + 1}
                      translate={translate}
                      onChange={(targetPage, perPageLimit) => {
                        props.setSingleMembersPerPageLimit(perPageLimit);
                        props.setSingleMembersTragetPage(targetPage - 1);
                        if (!shouldFetchSingleMembers) {
                          setShouldFetchSingleMembers(true);
                        }
                      }}
                    />
                  </>
                ),
                onHeadingClick: () => {
                  updateExpandedAccordion(1);
                  if (!shouldFetchSingleMembers) setShouldFetchSingleMembers(true);
                  setIsSingleExpanded(!isSingleExpanded);
                },
                open: isSingleExpanded,
              },
              {
                title: `${translate("directory.content-nav.junior")} (${juniorMembersLength})`,
                content: (
                  <>
                    <DirectoryPreviews members={paginatedJuniorMembers} elementToRender={RouterLink} />
                    <Pagination
                      totalItems={juniorMembersLength}
                      items={paginatedJuniorMembers}
                      pageSize={props.juniorMembersPerPageLimit}
                      pageSizes={[12, 24, 36, 48]}
                      page={props.juniorMembersTargetPage + 1}
                      translate={translate}
                      onChange={(targetPage, perPageLimit) => {
                        props.setJuniorPerPageLimit(perPageLimit);
                        props.setJuniorMembersTagrgetPage(targetPage - 1);
                      }}
                    />
                  </>
                ),
                onHeadingClick: () => {
                  updateExpandedAccordion(2);
                  if (!shouldFetchJuniorMembers) setShouldFetchJuniorMembers(true);
                  setIsJuniorExpanded(!isJuniorExpanded);
                },
                open: isJuniorExpanded,
              },
              {
                title: `${translate("directory.content-nav.honorary")} (${honoraryMembersLength})`,
                content: (
                  <>
                    <DirectoryPreviews members={paginatedHonoraryMembers} elementToRender={RouterLink} />
                    <Pagination
                      totalItems={honoraryMembersLength}
                      items={paginatedHonoraryMembers}
                      pageSize={props.honoraryMembersPerPageLimit}
                      pageSizes={[12, 24, 36, 48]}
                      page={props.honoraryMembersTargetPage + 1}
                      translate={translate}
                      onChange={(targetPage, perPageLimit) => {
                        props.setHonoraryMembersPerPageLimit(perPageLimit);
                        props.setHonoraryMembersTargetPage(targetPage - 1);
                      }}
                    />
                  </>
                ),
                onHeadingClick: () => {
                  updateExpandedAccordion(3);
                  if (!shouldFetchHonoraryMembers) setShouldFetchHonoraryMembers(true);
                  setIsHonoraryExpanded(!isHonoraryExpanded);
                },
                open: isHonoraryExpanded,
              },
              {
                title: `${translate("directory.content-nav.senior")} (${seniorMembersLength})`,
                content: (
                  <>
                    <DirectoryPreviews members={paginatedSeniorMembers} elementToRender={RouterLink} />
                    <Pagination
                      totalItems={seniorMembersLength}
                      items={paginatedSeniorMembers}
                      pageSize={props.seniorMembersPerPageLimit}
                      pageSizes={[12, 24, 36, 48]}
                      page={props.seniorMembersTargetPage + 1}
                      translate={translate}
                      onChange={(targetPage, perPageLimit) => {
                        props.setSeniorMembersPerPageLimit(perPageLimit);
                        props.setSeniorMembersTargetPage(targetPage - 1);
                      }}
                    />
                  </>
                ),
                onHeadingClick: () => {
                  updateExpandedAccordion(4);
                  if (!shouldFetchSeniorMembers) setShoudFetchSeniorMembers(true);
                  setIsSeniorExpanded(!isSeniorExpanded);
                },
                open: isSeniorExpanded,
              },
              {
                title: `${translate("directory.content-nav.student")} (${studentMembersLength})`,
                content: (
                  <>
                    <DirectoryPreviews members={paginatedStudentMembers} elementToRender={RouterLink} />
                    <Pagination
                      totalItems={studentMembersLength}
                      items={paginatedStudentMembers}
                      pageSize={props.studentMembersPerPageLimit}
                      pageSizes={[12, 24, 36, 48]}
                      page={props.studentMembersTargetPage + 1}
                      translate={translate}
                      onChange={(targetPage, perPageLimit) => {
                        props.setStudentMembersPerPageLimit(perPageLimit);
                        props.setStudentMembersTargetPage(targetPage - 1);
                      }}
                    />
                  </>
                ),
                onHeadingClick: () => {
                  updateExpandedAccordion(5);
                  if (!shouldFetchStudentMembers) setShoudFetchStudentMembers(true);
                  setIsStudentExpanded(!isStudentExpanded);
                },
                open: isStudentExpanded,
              },
              {
                title: `${translate("directory.content-nav.specialist")} (${specialistMembersLength})`,
                content: (
                  <>
                    <DirectoryPreviews members={paginatedSpecialistMembers} elementToRender={RouterLink} />
                    <Pagination
                      totalItems={specialistMembersLength}
                      items={paginatedSpecialistMembers}
                      pageSize={props.specialistMembersPerPageLimit}
                      pageSizes={[12, 24, 36, 48]}
                      page={props.specialistMembersTargetPage + 1}
                      translate={translate}
                      onChange={(targetPage, perPageLimit) => {
                        props.setSpecialistMembersPerPageLimit(perPageLimit);
                        props.setSpecialistMembersTargetPage(targetPage - 1);
                      }}
                    />
                  </>
                ),
                onHeadingClick: () => {
                  updateExpandedAccordion(6);
                  if (!shouldFetchSpecialist) setShouldFetchSpecialists(true);
                  setIsSpecialistExpanded(!isSpecialistExpanded);
                },
                open: isSpecialistExpanded,
              },
            ]}
          ></Accordion>
        </div>
      </Content>
    </Page>
  );
};

export default DirectoryPage;
