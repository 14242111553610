export default class Address {
    constructor(
        id,
        domicile,
        firstName,
        lastName,
        department,
        gender,
        useHeadquarters,
        label,
        removable,
        additionalInfo,
        primaryStreetWithNumber,
        secondaryStreetWithNumber,
        postOfficeBox,
        zip,
        location,
        canton,
        country,
        email,
        phone,
        mobile,
        website,
        preformattedLines,
    ) {
        this.id = id;
        this.domicile = domicile;
        this.firstName = firstName;
        this.lastName = lastName;
        this.department = department;
        this.gender = gender;
        this.useHeadquarters = useHeadquarters;
        this.label = label;
        this.removable = removable;
        this.additionalInfo = additionalInfo;
        this.primaryStreetWithNumber = primaryStreetWithNumber;
        this.secondaryStreetWithNumber = secondaryStreetWithNumber;
        this.postOfficeBox = postOfficeBox;
        this.zip = zip;
        this.location = location;
        this.canton = canton;
        this.country = country;
        this.email = email;
        this.phone = phone;
        this.mobile = mobile;
        this.website = website;
        this.preformattedLines = preformattedLines;
    }

    getId() {
        return this.id;
    }

    getFirstName() {
        return this.firstName;
    }

    getLastName() {
        return this.lastName;
    }

    getDepartment() {
        return this.department;
    }

    getGender() {
        return this.gender;
    }

    getUseHeadquarters() {
        return this.useHeadquarters;
    }

    isDomicile() {
        return this.domicile;
    }

    getLabel() {
        return this.label;
    }

    isRemovable() {
        return this.removable;
    }

    getAdditionalInfo() {
        return this.additionalInfo;
    }

    getPrimaryStreetWithNumber() {
        return this.primaryStreetWithNumber;
    }

    getSecondaryStreetWithNumber() {
        return this.secondaryStreetWithNumber;
    }

    getPostOfficeBox() {
        return this.postOfficeBox;
    }

    getZip() {
        return this.zip;
    }

    getLocation() {
        return this.location;
    }

    getCanton() {
        return this.canton;
    }

    getCountry() {
        return this.country;
    }

    getEmail() {
        return this.email;
    }

    getPhone() {
        return this.phone;
    }

    getMobile() {
        return this.mobile;
    }

    getWebsite() {
        return this.website;
    }

    getPreformattedLines() {
        return this.preformattedLines;
    }
}