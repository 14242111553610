import React from "react";
import { useTranslator } from "common/Translation/Translator";
import { usePathFactory } from "common/Path/PathFactoryHook";
import { useHistory } from "react-router-dom";
import { ContentNavigation } from "@sia/style-guide/dist/components";

export const JobsContentNavigation = ({ callBack, isSubscribedToJobs }) => {
  const translate = useTranslator();
  const pathFactory = usePathFactory();
  const history = useHistory();
  const routerItems = [];

  const offersTab = {
    label: translate("jobs.offers"),
    path: pathFactory.create("jobs/offers"),
  };

  const manageTab = {
    label: translate("jobs.manage"),
    path: pathFactory.create("jobs/manage"),
  };

  if (isSubscribedToJobs) {
    routerItems.push(offersTab, manageTab);
  }

  const contentNavigationItems = routerItems.map((routerItem) => {
    if (routerItem.label === translate("jobs.offers")) {
      return {
        label: routerItem.label,
        onClick: () => {
          if (callBack) callBack();
          history.push(routerItem.path);
        },
      };
    }

    return {
      label: routerItem.label,
      onClick: () => history.push(routerItem.path),
    };
  });
  const selectedItemIndex = routerItems.findIndex((routerItem) =>
    history.location.pathname.includes(routerItem.path)
  );

  return (
    <ContentNavigation
      items={contentNavigationItems}
      preselectedItemIndex={selectedItemIndex}
    />
  );
};

export default JobsContentNavigation;
