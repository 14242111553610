import {MultiInput as StyleguideMultiInput} from "@sia/style-guide";
import {useTranslator} from "common/Translation/Translator";

const MultiInput = (props) => {
    const translate = useTranslator();
    const enrichingProps = {translate: translate};
    const enrichedProps = {...props, ...enrichingProps};
    return <StyleguideMultiInput {...enrichedProps}/>;
};
MultiInput.defaultProps = {
    type: 'MultiInput', // todo, https://stackoverflow.com/q/72446985/4840661
    required: true,
};
export default MultiInput;