import React, { useContext  } from "react";
import { ContentNavigation } from "@sia/style-guide/dist/components";
import { useTranslator } from "common/Translation/Translator";
import { useHistory } from "react-router-dom";
import { usePathFactory } from "common/Path/PathFactoryHook";
import GlobalContext from "contexts/GlobalContext";

export const UserContentNavigation = () => {
  const translate = useTranslator();
  const history = useHistory();
  const pathFactory = usePathFactory();
  const { isSubscribedToAnyService } = useContext(GlobalContext);
  const routerItems = [
    {
      label: translate("account.customer-data"),
      path: pathFactory.create("account/customer-data"),
    },
    {
      label: translate("account.invoices"),
      path: pathFactory.create("account/invoices"),
    },
  ];

  if (isSubscribedToAnyService) {
    const serviceTab = {
      label: translate("account.services"),
      path: pathFactory.create("account/services"),
    };
    routerItems.splice(1, 0, serviceTab);
  }

  const contentNavigationItems = routerItems.map((routerItem) => {
    return {
      label: routerItem.label,
      onClick: () => history.push(routerItem.path),
    };
  });
  const selectedItemIndex = routerItems.findIndex((routerItem) =>
    history.location.pathname.includes(routerItem.path)
  );
  return (
    <ContentNavigation
      items={contentNavigationItems}
      preselectedItemIndex={selectedItemIndex}
    />
  );
};

export default UserContentNavigation;
