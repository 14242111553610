import React, { useState } from "react";
import { useErrorNavigator } from "common/ErrorsHandlers/ErrorNavigatorHook";
import httpClient from "common/HttpClient";

const defaultValue = {
    error: null,
    status: null,
    statusText: null,
    setError: () => null,
}
export const HttpErrorContext = React.createContext(defaultValue);

const HttpErrorContextProvider = (props) => {

    const [error, setError] = useState(defaultValue)
    httpClient.setErrorCallBack(setError)

    const navigate = useErrorNavigator()
    httpClient.setNavigationCallBack(navigate)

    return <HttpErrorContext.Provider value={{ ...error, setError }}>
        {props.children}
    </HttpErrorContext.Provider>
}

export default HttpErrorContextProvider;