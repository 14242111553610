import React, { useEffect, useState } from "react";
import Page from "../Page";
import { useTranslator } from "common/Translation/Translator";
import "./newsletter.scss";
import { useParams } from "react-router-dom";
import webPlatformAdapter from "common/Adapters/WebPlatformAdapter/WebPlatformAdapter";
const UnsubscribeFromNewsletter = () => {
  const translate = useTranslator();
  const { hashed_key, product_id } = useParams();
  const [result, setResult] = useState(null);
  useEffect(() => {
    const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL
    webPlatformAdapter.requestUrl(
      "POST",
      `${REACT_APP_API_BASE_URL}/newsletter/outsiders/delete`,
      {
        hashed_key,
        product_id,
      },
      (data) => {
        const success = data.success;
        if (success) setResult(translate("newsletter.unsubscribe.success"));
        else setResult(translate("newsletter.unsubscribe.faliure"));
      }
    );
  }, [hashed_key, product_id, translate]);
  return (
    <Page className="unsubscribe">
      <div className="newsletter">
        <p className="sia-text-04">{result}</p>
      </div>
    </Page>
  );
};

export default UnsubscribeFromNewsletter;
