import { useEffect, useReducer, useRef } from "react";

const useReducerWithMiddleWare = (reducer, initArg, init, middlewares, afterwares) => {
    const [state, dispatch] = useReducer(reducer, initArg, init)
    const actionRef = useRef(null)
    const dispatchWithMiddlwares = (action) => {
        middlewares.forEach(middleware => middleware(action));
        actionRef.current = action
        dispatch(action)
    }

    useEffect(() => {
        if (!actionRef.current)
            return
        afterwares.forEach(afterware => afterware(actionRef.current))
        actionRef.current = null
    }, [afterwares])

    return [state, dispatchWithMiddlwares]

}

export default useReducerWithMiddleWare;