export default class MembershipTableCategory {

    #id;
    #title;
    #groups;

    constructor(id, title, groups) {
        this.#id = id;
        this.#title = title;
        this.#groups = groups;
    }

    getId() {
        return this.#id;
    }

    getTitle() {
        return this.#title;
    }

    getGroups() {
        return this.#groups;
    }
}