import { getFooterContents } from "@sia/shared/js/contents/layout";
import { usePathFactory } from "../Path/PathFactoryHook";

export function useFooterContents(language) {
  const pathFactory = usePathFactory();
  return getFooterContents({
    links: {
      privacyPolicy: {
        target: pathFactory.create(process.env.REACT_APP_CMS_URL ? "cms/node/54" : "privacy-policy"),
      },
      vacancies: {
        target: process.env.REACT_APP_CMS_URL ? `/${language}/cms/node/168` : undefined,
      },
      media: {
        target: process.env.REACT_APP_CMS_URL ? `/${language}/cms/medien` : undefined,
      },
      newsletter: {
        target: process.env.REACT_APP_CMS_URL ? `/${language}/cms/newsletter` : undefined,
      },
    },
    contactUs: {
      target: process.env.REACT_APP_CMS_URL ? `/${language}/cms/beratung` : undefined,
    },
  });
}
