import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  SIAPage,
  SIALegalPolicy,
  SIAArticle,
  SIAVacancy,
  SIANewsletter,
  SIAHomepage,
  SIAEventOccurrence,
  SIAFAQ,
  SIAEventSpeaker,
} from "@sia/style-guide";
import cmsAdapter from "../../common/Adapters/CMSAdapter/CMSAdapter";
import { scrollToElement } from "./scrollToElement.js";
import { getNode } from "./getNode.js";
import { useTranslator } from "../../common/Translation/Translator";
import useContentMethods from "./useContentMethods";
import useFormMethods from "./useFormMethods";

const CMSContentWrapper = ({ languageKey, nodeId, isHash, menuItems, noMenuPages }) => {
  const history = useHistory();
  const alias = history.location.pathname.replace(`/${languageKey}/cms/`, "").replace("hash/", "");
  const [isLoading, setIsLoading] = useState(true);
  const [siaContentProps, setSiaContentProps] = useState({});
  const [cachedValues, setCachedValues] = useState({});
  const [contentType, setContentType] = useState("");
  const translate = useTranslator();
  const node_id = React.useMemo(() => {
    return nodeId || getNode({ alias, history, languageKey, menuItems, noMenuPages });
  }, [nodeId, alias, history, languageKey, menuItems, noMenuPages]);

  const { getContentByHash, getContentByNodeID } = useContentMethods(
    cmsAdapter,
    cachedValues,
    setCachedValues,
    languageKey,
    history,
    setContentType,
    setSiaContentProps,
    setIsLoading,
    node_id,
    alias
  );
  const { fetchForm, onSubmitForm, fetchTerms } = useFormMethods(cmsAdapter, cachedValues, setCachedValues);

  const getAgenda = (onSuccess, events_number) => {
    cmsAdapter.fetchAgenda(languageKey, onSuccess, translate, events_number);
  };

  const getParagraph = (onSuccess, languageKey, id) => {
    cmsAdapter.fetchParagraph(onSuccess, languageKey, id);
  };

  const loadPage = () => {
    setIsLoading(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    if (isHash) return getContentByHash();
    if (node_id > 0) return getContentByNodeID();
    else if (node_id !== -1) history.push(`/not-found`);
  };

  useEffect(() => {
    !isLoading && scrollToElement(history);
    // eslint-disable-next-line
  }, [isLoading]);

  useEffect(() => {
    (alias || nodeId) && loadPage();
    // eslint-disable-next-line
  }, [alias, nodeId]);

  useEffect(() => {
    const createBrowserTitle = () => {
      const browserTitle = translate("browser.title");
      if (siaContentProps.title) {
        return `${siaContentProps.title} - ${browserTitle}`;
      }
      return browserTitle;
    };
    document.title = createBrowserTitle();
  }, [siaContentProps.title, translate]);

  console.log(siaContentProps);
  return (
    <>
      {!isLoading && (
        <div>
          {(contentType === "sia_page" || contentType === "sektion_page") && (
            <SIAPage
              translate={(value, options) => translate(value, options)}
              key={siaContentProps?.title}
              {...siaContentProps}
              lang={languageKey}
              history={history}
              inFormMethods={{
                fetchForm,
                onSubmitForm,
                fetchTerms,
                fetchNode: (languageKey, node_id, onSuccess) => cmsAdapter.fetchNode(languageKey, node_id, onSuccess),
              }}
              captchaKey={process.env.REACT_APP_CAPTCHA_KEY}
              getParagraph={getParagraph}
            />
          )}
          {contentType === "sia_legalpolicy" && (
            <SIALegalPolicy
              translate={(value, options) => translate(value, options)}
              key={siaContentProps?.title}
              {...siaContentProps}
              history={history}
              lang={languageKey}
            />
          )}
          {contentType === "sia_article" && (
            <SIAArticle translate={(value, options) => translate(value, options)} lang={languageKey} item={siaContentProps.item} history={history} />
          )}
          {contentType === "sia_vacancies" && (
            <SIAVacancy translate={(value, options) => translate(value, options)} lang={languageKey} item={siaContentProps.item} history={history} />
          )}
          {contentType === "sia_newsletter" && (
            <SIANewsletter
              translate={(value, options) => translate(value, options)}
              lang={languageKey}
              item={siaContentProps.item}
              history={history}
            />
          )}
          {contentType === "homepage" && (
            <SIAHomepage
              translate={(value, options) => translate(value, options)}
              lang={languageKey}
              item={siaContentProps.item}
              history={history}
              getAgenda={getAgenda}
            />
          )}
          {contentType === "event_occurrence" && (
            <SIAEventOccurrence
              lang={languageKey}
              history={history}
              translate={(value, options) => translate(value, options)}
              formatName={siaContentProps.item?.event_format}
              eventOrganisatorName={siaContentProps.item?.event_organisator}
              eventLocations={siaContentProps.item?.event_locations}
              eventLanguages={siaContentProps.item?.languages}
              eventTitle={siaContentProps.item?.title}
              eventLead={siaContentProps.item?.event_lead}
              eventSublead={siaContentProps.item?.event_sublead}
              event_price={siaContentProps.item?.event_price}
              eventPageBottom={siaContentProps?.item.event_content}
              partners={siaContentProps.item?.partners}
              media={siaContentProps.item?.media}
              speakers={siaContentProps.item?.speaker}
            />
          )}
          {contentType === "speaker" && (
            <SIAEventSpeaker
              speaker={siaContentProps}
              lang={languageKey}
              history={history}
              translate={(value, options) => translate(value, options)}
              getAgenda={getAgenda}
            />
          )}
          {contentType === "sia_faq" && (
            <SIAFAQ
              translate={(value, options) => translate(value, options)}
              {...siaContentProps.item}
              lang={languageKey}
              history={history}
              inFormMethods={{
                fetchForm,
                onSubmitForm,
              }}
              captchaKey={process.env.REACT_APP_CAPTCHA_KEY}
              getParagraph={getParagraph}
            />
          )}
          {contentType === "event" && (
            <div className="sia-padding-04">
              <h2>
                TODO: Content type <b>event</b>
              </h2>
              <h2>{siaContentProps.item.title}</h2>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CMSContentWrapper;
