class AddressSorter {
    sort = (addresses) => {
        if (!addresses.length) {
            return addresses;
        }
        const domicileAddress = addresses.find(address => address.isDomicile());
        const commonAddresses = addresses.filter(address => !address.isDomicile());
        commonAddresses.sort((addressA, addressB) => {
            if (addressA.number > addressB.number) {
                return 1;
            }
            if (addressA.number < addressB.number) {
                return -1;
            }
            return 0;
        });
        return [domicileAddress].concat(commonAddresses);
    }
}

const addressSorter = new AddressSorter();
export default addressSorter;