import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { usePathFactory } from "common/Path/PathFactoryHook";
import {
  AppTitleBack,
  AppContent,
  changer,
  Content,
  Form,
  InfoBlock,
  merger,
  MultiselectDropdown,
  safeStateChanger,
  TextArea,
  Tile,
  TextInput,
  EmailInput,
  PhoneInput,
  Dropdown,
} from "@sia/style-guide";
import Page from "pages/Page";
import JobsContentNavigation from "../JobsContentNavigation";
import { useTranslator } from "common/Translation/Translator";
import webPlatformAdapter from "common/Adapters/WebPlatformAdapter/WebPlatformAdapter";
import i18n from "i18next";
import "./ManageOfferPage.scss";
import { useNavigator } from "common/Navigator";

const ManageOfferPage = ({ isSubscribedToJobs }) => {
  const { id } = useParams();
  const history = useHistory();
  const navigate = useNavigator();
  const pathFactory = usePathFactory();
  const translate = useTranslator();
  const pageTitle = id ? "jobs.manage.add.title" : "jobs.manage.add.title";
  const lang = i18n.language;
  const [titles, setTitles] = useState([]);
  const [title, setTitle] = useState(null);
  const [regions, setRegions] = useState([]);
  const [workLoads, setWorkLoads] = useState([]);
  const [education, setEducation] = useState([]);
  const [jobFunction, setJobFunctions] = useState("");
  const [experience, setExpiernce] = useState([]);
  const [cad, setCad] = useState([]);
  const [constructionsSoftware, setConstructionSoftware] = useState([]);
  const [visualizationSoftware, setVisualizationSoftware] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [contactPerson, setContactPerson] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState("");
  const [description, setDescription] = useState("");

  const [isRegionsOpen, setIsRegionsOpen] = useState(false);
  const [isTitlesOpen, setIsTitlesOpen] = useState(false);
  const [isWorkLoadsOpen, setIsWorkLoadsOpen] = useState(false);
  const [isEducationOpen, setIsEducationOpen] = useState(false);
  const [isExperienceOpen, setIsExperienceOpen] = useState(false);
  const [isLanguagesOpen, setIsLanguagesOpen] = useState(false);
  const [isCadOpen, setIsCadOpen] = useState(false);
  const [isConstuctionSoftwareOpen, setIsConstructionSoftwareOpen] =
    useState(false);
  const [isVsiualizationOpen, setIsVisualizationOpen] = useState(false);

  const [formErrors, setFormErrors] = useState({
    title: null,
    regions: null,
    workLoads: null,
    education: null,
    contactPerson: null,
    jobFunction: null,
    experience: null,
    languages: null,
    email: null,
    description: null,
  });
  const toggleDropdowns = (singleToggleCallback) => {
    setIsRegionsOpen(false);
    setIsTitlesOpen(false);
    setIsWorkLoadsOpen(false);
    setIsEducationOpen(false);
    setIsExperienceOpen(false);
    setIsLanguagesOpen(false);
    setIsCadOpen(false);
    setIsConstructionSoftwareOpen(false);
    setIsVisualizationOpen(false);

    singleToggleCallback();
  };

  const updateStateIfIdExists = useCallback(
    (
      titles,
      regions,
      workLoads,
      experience,
      education,
      languages,
      CAD,
      construction,
      visualisation
    ) => {
      webPlatformAdapter.getOwnerOpenPosition(id, lang, (response) => {
        const { data } = response;
        const activeTitles = titles.map((e) => {
          if (e.name === data[0]["title"]) {
            setTitle(e);
          }
          return e;
        });

        const regionIds = data[3].regions.map((e) => e.id);
        const activeRegions = regions.map((e) => ({
          ...e,
          active: regionIds.includes(e.id),
        }));

        const workLoadIds = data[2].workloads.map((e) => e.id);
        const activeWorkLoads = workLoads.map((e) => ({
          ...e,
          active: workLoadIds.includes(e.id),
        }));

        const educationIds = data[4].formations.map((e) => e.id);
        const acitveEducation = education.map((e) => ({
          ...e,
          active: educationIds.includes(e.id),
        }));

        const jobFunction = data[0].function;

        const experienceIds = data[5].professional_levels.map((e) => e.id);
        const activeExperience = experience.map((e) => ({
          ...e,
          active: experienceIds.includes(e.id),
        }));

        const languagesIds = data[1].language_skills.map((e) => e.id);
        const activeLanguages = languages.map((e) => ({
          ...e,
          active: languagesIds.includes(e.id),
        }));

        const CADIds = data[6].software?.CAD?.map((e) => e.id) || [];
        const activeCads = CAD.map((e) => ({
          ...e,
          active: CADIds.includes(e.id),
        }));

        const constructionIds =
          data[6].software?.construction?.map((e) => e.id) || [];
        const acitveConstruction = construction.map((e) => ({
          ...e,
          active: constructionIds.includes(e.id),
        }));

        const visualisationIds =
          data[6].software?.visualisation?.map((e) => e.id) || [];
        const acitveVisualitation = visualisation.map((e) => ({
          ...e,
          active: visualisationIds.includes(e.id),
        }));

        const contactPerson = data[0].contact_person;
        const contactEmail = data[0].contact_email;
        const contactPhone = data[0].contact_phone;
        const description = data[0].description;

        setTitles(activeTitles);
        setRegions(activeRegions);
        setWorkLoads(activeWorkLoads);
        setEducation(acitveEducation);
        setJobFunctions(jobFunction);
        setExpiernce(activeExperience);
        setLanguages(activeLanguages);
        setContactPerson(contactPerson);
        setEmail(contactEmail);
        setTelephone(contactPhone);
        setDescription(description);
        setCad(activeCads);
        setConstructionSoftware(acitveConstruction);
        setVisualizationSoftware(acitveVisualitation);
      },
      err => navigate('jobs/manage')
      );
    },
    [id, lang, navigate]
  );

  useEffect(() => {
    webPlatformAdapter.getJobFilterLists(lang, (response) => {
      const { data } = response;
      let titles = data.types.map((e) => ({ ...e, label: e.name }));
      let regions = data.regions.map((e) => ({ ...e, label: e.name }));
      let workLoads = data.workloads.map((e) => ({ ...e, label: e.name }));
      let education = data.formations.map((e) => ({ ...e, label: e.name }));
      let experience = data.professional_levels.map((e) => ({
        ...e,
        label: e.name,
      }));
      let languages = data.language_skills.map((e) => ({
        ...e,
        label: e.name,
      }));
      let { CAD, construction, visualitation: visualisation } = data.softwares;

      CAD = CAD.map((e) => ({
        ...e,
        label: e.name,
      }));

      construction = construction.map((e) => ({
        ...e,
        label: e.name,
      }));

      visualisation = visualisation.map((e) => ({
        ...e,
        label: e.name,
      }));
      if (id) {
        updateStateIfIdExists(
          titles,
          regions,
          workLoads,
          experience,
          education,
          languages,
          CAD,
          construction,
          visualisation
        );
      } else {
        setTitles(titles);
        setRegions(regions);
        setWorkLoads(workLoads);
        setEducation(education);
        setExpiernce(experience);
        setLanguages(languages);
        setCad(CAD);
        setConstructionSoftware(construction);
        setVisualizationSoftware(visualisation);
      }
    });
  }, [lang, id, updateStateIfIdExists]);

  const onSubmitErrors = (errors) => {
    setFormErrors(merger.mergeObjectsImmutable(formErrors, errors));
  };

  const onSubmit = () => {
    const type = title.id;
    const regionsIds = regions.filter((e) => e.active).map((e) => e.id);
    const workLoadsIds = workLoads.filter((e) => e.active).map((e) => e.id);
    const educationIds = education.filter((e) => e.active).map((e) => e.id);
    const experienceIds = experience.filter((e) => e.active).map((e) => e.id);
    const languagesIds = languages.filter((e) => e.active).map((e) => e.id);
    const softwares = cad
      .concat(constructionsSoftware, visualizationSoftware)
      .filter((e) => e.active)
      .map((e) => e.id);

    let data = {
      job_type: type,
      job_function: jobFunction,
      description,
      contact_email: email,
      contact_person: contactPerson,
      contact_phone: telephone,
      formations: educationIds,
      language_skills: languagesIds,
      professional_levels: experienceIds,
      regions: regionsIds,
      workloads: workLoadsIds,
      softwares,
    };
    if (id) {
      data = { job_id: id, ...data };
    }
    // job_type int,job_function text,description text,contact_email text,contact_person text,contact_phone text,formations json,language_skills json,professional_levels json,regions json,workloads json,softwares json;
    const endpoint = id
      ? "rpc/update_job_open_position"
      : "rpc/create_job_open_position";
    webPlatformAdapter.request("POST", endpoint, data, (response) => {
      navigate("jobs/manage");
    });
  };

  return (
    <Page
      contentNavigation={
        <JobsContentNavigation isSubscribedToJobs={isSubscribedToJobs} />
      }
      title={'jobs.name'}
    >
      <AppTitleBack
        title={translate(pageTitle)}
        onClickBack={() => history.push(pathFactory.create("jobs/manage"))}
      />
      <AppContent>
        <p>{translate("jobs.manage.add.description")}</p>
      </AppContent>
      <Content className={"manage-project"}>
        <Form
          primaryButtonLabel={translate("form.save")}
          onClickPrimaryButton={onSubmit}
          onErrors={onSubmitErrors}
        >
          <div className="fields-column bx--col-lg-8 bx--col-md-4 bx--col-sm-4 left">
            <Tile>
              <InfoBlock loose>
                <span className="label">
                  {translate("jobs.manage.form.description")}
                </span>
              </InfoBlock>

              <Dropdown
                id={"titles"}
                required
                label={translate("jobs.manage.form.job")}
                isOpen={isTitlesOpen}
                toggle={() => {
                  toggleDropdowns(() => setIsTitlesOpen(!isTitlesOpen));
                }}
                value={title}
                items={titles}
                onChange={(event, item) => {
                  setTitle(item);
                  setFormErrors(
                    changer.changeImmutable(formErrors, "title", null)
                  );
                }}
                invalidText={translate(formErrors.title)}
              />

              <MultiselectDropdown
                id={"regions"}
                label={translate("jobs.manage.form.region")}
                required
                isOpen={isRegionsOpen}
                toggle={() => {
                  toggleDropdowns(() => setIsRegionsOpen(!isRegionsOpen));
                }}
                items={regions}
                onClickItem={(event, item, updatedItems) => {
                  setRegions(updatedItems);
                  setFormErrors(
                    changer.changeImmutable(formErrors, "regions", null)
                  );
                }}
                invalidText={translate(formErrors.regions)}
              />

              <MultiselectDropdown
                id={"workLoads"}
                label={translate("jobs.manage.form.workload")}
                required
                isOpen={isWorkLoadsOpen}
                toggle={() => {
                  toggleDropdowns(() => setIsWorkLoadsOpen(!isWorkLoadsOpen));
                }}
                items={workLoads}
                onClickItem={(event, item, updatedItems) => {
                  setWorkLoads(updatedItems);
                  setFormErrors(
                    changer.changeImmutable(formErrors, "workLoads", null)
                  );
                }}
                invalidText={translate(formErrors.workLoads)}
              />

              <MultiselectDropdown
                id={"education"}
                label={translate("jobs.manage.form.education")}
                required
                isOpen={isEducationOpen}
                toggle={() => {
                  toggleDropdowns(() => setIsEducationOpen(!isEducationOpen));
                }}
                items={education}
                onClickItem={(event, item, updatedItems) => {
                  setEducation(updatedItems);
                  setFormErrors(
                    changer.changeImmutable(formErrors, "education", null)
                  );
                }}
                invalidText={translate(formErrors.education)}
              />

              <TextInput
                id={"jobFunction"}
                label={translate("jobs.manage.form.function")}
                required
                value={jobFunction}
                onChange={(e) => {
                  setJobFunctions(e.target.value);
                  setFormErrors(
                    changer.changeImmutable(formErrors, "jobFunction", null)
                  );
                }}
                invalidText={translate(formErrors.jobFunction)}
              />

              <MultiselectDropdown
                id={"experience"}
                label={translate("jobs.manage.form.experience")}
                required
                items={experience}
                isOpen={isExperienceOpen}
                toggle={() =>
                  toggleDropdowns(() => setIsExperienceOpen(!isExperienceOpen))
                }
                onClickItem={(event, item, updatedItems) => {
                  setExpiernce(updatedItems);
                  setFormErrors(
                    changer.changeImmutable(formErrors, "experience", null)
                  );
                }}
                invalidText={translate(formErrors.experience)}
              />

              <MultiselectDropdown
                id={"languages"}
                label={translate("jobs.manage.form.languages")}
                required
                items={languages}
                isOpen={isLanguagesOpen}
                toggle={() =>
                  toggleDropdowns(() => setIsLanguagesOpen(!isLanguagesOpen))
                }
                onClickItem={(event, item, updatedItems) => {
                  setLanguages(updatedItems);
                  setFormErrors(
                    changer.changeImmutable(formErrors, "languages", null)
                  );
                }}
                invalidText={translate(formErrors.languages)}
              />

              <MultiselectDropdown
                id={"cad"}
                label={translate("jobs.manage.form.cad")}
                items={cad}
                isOpen={isCadOpen}
                toggle={() => toggleDropdowns(() => setIsCadOpen(!isCadOpen))}
                onClickItem={(event, item, updatedItems) => {
                  setCad(updatedItems);
                  setFormErrors(
                    changer.changeImmutable(formErrors, "cad", null)
                  );
                }}
              />

              <MultiselectDropdown
                id={"visuzalization"}
                label={translate("jobs.manage.form.visualization")}
                items={visualizationSoftware}
                isOpen={isVsiualizationOpen}
                toggle={() =>
                  toggleDropdowns(() =>
                    setIsVisualizationOpen(!isVsiualizationOpen)
                  )
                }
                onClickItem={(event, item, updatedItems) => {
                  setVisualizationSoftware(updatedItems);
                  setFormErrors(
                    changer.changeImmutable(formErrors, "visualization", null)
                  );
                }}
              />

              <MultiselectDropdown
                id={"construction"}
                label={translate("jobs.manage.form.software")}
                items={constructionsSoftware}
                isOpen={isConstuctionSoftwareOpen}
                toggle={() =>
                  toggleDropdowns(() =>
                    setIsConstructionSoftwareOpen(!isConstuctionSoftwareOpen)
                  )
                }
                onClickItem={(event, item, updatedItems) => {
                  setConstructionSoftware(updatedItems);
                  setFormErrors(
                    changer.changeImmutable(formErrors, "construction", null)
                  );
                }}
              />
            </Tile>
          </div>

          <div className="fields-column bx--col-lg-8 bx--col-md-4 bx--col-sm-4 Description-Column">
            <Tile>
              <InfoBlock loose>
                <span className="label">
                  {translate("jobs.manage.form.contact")}
                </span>
              </InfoBlock>

              <TextInput
                id={"contactPerson"}
                label={translate("jobs.manage.form.contact-person")}
                required
                value={contactPerson}
                onChange={(e) => {
                  setContactPerson(e.target.value);
                  setFormErrors(
                    changer.changeImmutable(formErrors, "contactPerson", null)
                  );
                }}
                invalidText={translate(formErrors.contactPerson)}
              />

              <EmailInput
                required
                id={"email"}
                label={translate("jobs.manage.form.email")}
                value={email}
                onChange={(e, value, error) => {
                  setEmail(e.target.value);
                  setFormErrors(
                    changer.changeImmutable(formErrors, "email", error)
                  );
                }}
                placeholder={translate(
                  "form.input.error.invalid.example.email"
                )}
                invalidPatternText={translate(
                  "form.input.error.invalid.with-example",
                  {
                    example: translate(
                      "form.input.error.invalid.example.email"
                    ),
                  }
                )}
                invalidText={translate(formErrors.email)}
              />

              <PhoneInput
                id={"telephone"}
                label={translate("jobs.manage.form.telephone")}
                value={telephone}
                placeholder="+41 12 345 67 89"
                onChange={(e, formatedValue, error) => {
                  setTelephone(formatedValue);
                  setFormErrors(
                    safeStateChanger.changeValue(formErrors, "telephone", error)
                  );
                }}
                invalidPatternText={translate(
                  "form.input.error.invalid.example.phone"
                )}
                invalidText={translate(formErrors.telephone)}
              />
            </Tile>

            <TextArea
              id={"description"}
              required
              label={translate("jobs.manage.form.ad-text")}
              value={description}
              limit={10000}
              onChange={(event, value) => {
                setDescription(value);
                setFormErrors(
                  safeStateChanger.changeValue(formErrors, "description", null)
                );
              }}
              invalidText={translate(formErrors.description)}
            />
          </div>
        </Form>
      </Content>
    </Page>
  );
};
export default ManageOfferPage;
