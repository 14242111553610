import React, { useContext, useEffect, useState } from "react";
import { useTranslator } from "common/Translation/Translator";
import { useHistory } from "react-router-dom";
import UserContentNavigation from "pages/Account/UserContentNavigation";
import Page from "pages/Page";
import GlobalContext from "contexts/GlobalContext";
import { usePathFactory } from "common/Path/PathFactoryHook";
import webPlatformAdapter from "common/Adapters/WebPlatformAdapter/WebPlatformAdapter";
import erpAdapter from "adapters/ERPAdapter";
import { dateFormatter, InfoBlock, TextInput, Form, DateInput, Content } from "@sia/style-guide";
import { BackButton } from "@sia/style-guide/dist/components/BackButton/BackButton";

const PersonalDataPage = () => {
  const translate = useTranslator();
  const history = useHistory();
  const pathFactory = usePathFactory();
  const [wasCustomerDataFetchStarted, setWasCustomerDataFetchStarted] = useState(false);
  const { user, language } = useContext(GlobalContext);
  const [userInfo, setUserInfo] = useState(null);
  const [birthdate, setBirthdate] = useState("");
  const [professionalTitle, setProfessionalTitle] = useState("");
  const title = translate("account.customer-data.base-data.single-person");
  const REQUEST_DELAY = 2000;

  const onSave = () => {
    const data = { diploma_title: professionalTitle };
    if (birthdate) {
      data.birth_date = dateFormatter.formatForApi(birthdate);
    }
    erpAdapter.requestWithDelay(
      "patch",
      "appAccountPersonalDataSingle",
      data,
      () => {
        if (birthdate) {
          setBirthdate(""); // to make sure that it's not sent again after already saved
        }
        setWasCustomerDataFetchStarted(false);
      },
      null,
      REQUEST_DELAY
    );
  };

  useEffect(() => {
    if (user && user.getMemberType() && !wasCustomerDataFetchStarted && language) {
      setWasCustomerDataFetchStarted(true);
      webPlatformAdapter.getCustomerDataOfMemberTypeSingle(language, (response) => {
        setUserInfo({
          siaId: response.data[0].sia_id,
          firstName: response.data[0].firstname,
          lastName: response.data[0].lastname,
          gender: response.data[0].gender,
          birthdate: response.data[0].birthdate,
          contactLanguage: response.data[0].language, // todo, davide will serve it already translated
          academicTitle: response.data[0].academic_title?.name,
        });
        setProfessionalTitle(response.data[0].diploma_title);
      });
    }
  }, [user, wasCustomerDataFetchStarted, language]);

  const renderContent = () => {
    if (!userInfo) {
      return;
    }
    return (
      <>
        <InfoBlock>
          <span className={"label"}>{translate("account.attributes.sia-id")}</span>
          <p>{userInfo.siaId}</p>
          <span className={"label"}>{translate("account.attributes.first-name")}</span>
          <p>{userInfo.firstName}</p>
          <span className={"label"}>{translate("account.attributes.last-name")}</span>
          <p>{userInfo.lastName}</p>
          {userInfo.gender && (
            <>
              <span className={"label"}>{translate("account.attributes.gender")}</span>
              <p>{userInfo.gender}</p>
            </>
          )}
          {userInfo.birthdate && (
            <>
              <span className={"label"}>{translate("account.attributes.birthdate")}</span>
              <p>{dateFormatter.format(userInfo.birthdate)}</p>
            </>
          )}
          <span className={"label"}>{translate("account.attributes.contact-language")}</span>
          <p>{userInfo.contactLanguage}</p>
          {userInfo.academicTitle && (
            <>
              <span className={"label"}>{translate("account.attributes.academic-title")}</span>
              <p>{userInfo.academicTitle}</p>
            </>
          )}
        </InfoBlock>
        <Form primaryButtonLabel={translate("form.save")} onClickPrimaryButton={onSave}>
          {!userInfo.birthdate && (
            <DateInput
              id={"birthdate"}
              label={translate("account.attributes.birthdate")}
              onChange={(event, value) => setBirthdate(value)}
              value={birthdate}
              invalidPatternText={translate("form.input.error.invalid.with-example", { example: "d.m.yyyy" })}
              placeholder={"1.1.1950"}
            />
          )}
          <TextInput
            id={"professional-title"}
            label={translate("account.attributes.professional-title")}
            onChange={(event) => setProfessionalTitle(event.target.value)}
            value={professionalTitle}
          />
        </Form>
      </>
    );
  };

  return (
    <Page contentNavigation={<UserContentNavigation />} title={"account.name"}>
      <Content width={"slim"} centeredTexts={true}>
        <h1>
          <BackButton onClick={() => history.push(pathFactory.create("account/customer-data"))} />
          {title}
        </h1>
        {renderContent()}
      </Content>
    </Page>
  );
};
export default PersonalDataPage;
