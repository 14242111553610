import { TagsGroup } from "@sia/style-guide";
import "./MemberDetailsHeader.scss";

const MemberDetailsHeader = ({ iscompany, city, name, member_type, member_type_id, onTagClick }) => {
  const primaryTag = [{ label: member_type, type: "primary", onClick: () => onTagClick({ id: member_type_id }) }];

  // This Header is for Compannies only
  const headerWithImage = (
    <>
      <div className="bx--col-lg-16 bx--col-md-5 MemberDetailsHeader-Id">
        <span>{city}</span>
        <h2 className="MemberDetailsHeader-Name sia-padding-01-top">{name}</h2>
        <TagsGroup items={primaryTag} />
      </div>
    </>
  );

  // This Header is for everything that is not a Company
  const generalHeader = (
    <div className="bx--col-lg-16 bx--col-md-8 bx--col-sm-4 MemberDetailsHeader-Noimage">
      <span>{city}</span>
      <h2 className="MemberDetailsHeader-Name sia-padding-01-top">{name}</h2>
      <TagsGroup items={primaryTag} />
    </div>
  );

  return <div className="bx--row MemberDetailsHeader sia-padding-04-bottom">{iscompany ? headerWithImage : generalHeader}</div>;
};

export default MemberDetailsHeader;
