import React, {useCallback, useContext, useEffect, useState, useMemo} from 'react';
import {useTranslator} from "common/Translation/Translator";
import {useHistory} from "react-router-dom";
import UserContentNavigation from "pages/Account/UserContentNavigation";
import Page from "pages/Page";
import GlobalContext from "contexts/GlobalContext";
import {usePathFactory} from "common/Path/PathFactoryHook";
import webPlatformAdapter from "common/Adapters/WebPlatformAdapter/WebPlatformAdapter";
import sorter from "common/Sorter";
import './Memberships.scss';
import textFormatter from "common/TextFormatter";
import Cloner from "common/Cloner";
import {
    Content, currencyFormatter, dateFormatter,
    // Pdf24, // todo, temporary hidden by SW-399
    Table
} from "@sia/style-guide";
import {BackButton} from "@sia/style-guide/dist/components/BackButton/BackButton";
import {getLocale} from "common/Translation/i18n";
import MembershipTableCategory from "./MembershipTableCategory";
import filterer from "common/Filterer";
// import downloader from "common/Downloader"; // todo, temporary hidden by SW-399

const MembershipsPage = () => {
    const translate = useTranslator();
    const history = useHistory();
    const pathFactory = usePathFactory();
    const GlobalContextValue = useContext(GlobalContext);
    const user = GlobalContextValue.user;
    const [wasMembershipsFetchStarted, setWasMembershipsFetchStarted] = useState(false);
    const [membershipGroupCategories, setMembershipGroupCategories] = useState({});

    const title = translate('account.customer-data.memberships.title');
    const endpoints = useMemo(() => {
        return {
            single: 'rpc/get_memberships_single',
            corporate: 'rpc/get_memberships_corporate',
        }
    }, []);
    const orderedKeys = useMemo(() => {
        return {
            sia_member: ['sia_member'],
            sia_committee: ['sia_committee'],
            sections: [
                'sections_member',
                'sections_delegate',
                'sections_committee'
            ],
            professional_groups: [
                'professional_groups_member',
                'professional_groups_delegate',
                'professional_groups_council',
                'professional_groups_standeskommission',
                'professional_groups_committee',
            ],
            sia_commissions: ['sia_commissions_member'],
            trade_associations: ['trade_associations_member', 'trade_associations_directors']

        }
    }, []);
    const translationKeys = {
        title: {
            sections: 'account.customer-data.memberships.group.title.sections',
            professional_groups: 'account.customer-data.memberships.group.title.professional-groups',
            sia_commissions: 'account.customer-data.memberships.group.title.commissions',
            trade_associations: 'account.customer-data.memberships.group.title.trade-associations',
        },
        group: {
            sia_member: 'account.customer-data.memberships.group.membership.sia',
            sia_memberships: 'account.customer-data.memberships.group.membership.sia',
            sia_committee: 'account.customer-data.memberships.group.executive.sia',
            sections_member: 'account.customer-data.memberships.group.membership',
            sections_delegate: 'account.customer-data.memberships.group.delegate',
            sections_committee: 'account.customer-data.memberships.group.executive',
            professional_groups_member: 'account.customer-data.memberships.group.membership',
            professional_groups_delegate: 'account.customer-data.memberships.group.delegate',
            professional_groups_council: 'account.customer-data.memberships.group.council',
            // product owner says "standeskommission" comes so from API and is business expression
            professional_groups_standeskommission: 'account.customer-data.memberships.group.professional-commission',
            professional_groups_committee: 'account.customer-data.memberships.group.committee',
            sia_commissions_member: 'account.customer-data.memberships.group.membership',
            trade_associations_member: 'account.customer-data.memberships.group.membership',
            trade_associations_directors: 'account.customer-data.memberships.group.executive',
            trade_associations_memberships: 'account.customer-data.memberships.group.title.trade-associations',
            single_members_in_ogl: 'account.customer-data.memberships.group.single-members-in-ogl',
            ahv_entries: 'account.customer-data.memberships.group.ahv',
            subsidiaries: 'account.customer-data.memberships.group.subsidiaries',
            parent: 'account.customer-data.memberships.group.membership.sia.parent',
        },
        attribute: {
            type: 'account.customer-data.memberships.attribute.type',
            entryDate: 'account.customer-data.memberships.attribute.entry-date',
            exitDate: 'account.customer-data.memberships.attribute.exit-date',
            duration: 'account.customer-data.memberships.attribute.duration',
            function: 'account.customer-data.memberships.attribute.function',
            from: 'account.customer-data.memberships.attribute.from',
            to: 'account.customer-data.memberships.attribute.to',
            lastElectionDate: 'account.customer-data.memberships.attribute.last-election-date',
            section: 'account.customer-data.memberships.attribute.section',
            professionalGroup: 'account.customer-data.memberships.attribute.professional-group',
            commission: 'account.customer-data.memberships.attribute.commission',
            tradeAssociation: 'account.customer-data.memberships.attribute.trade-association',
            firstName: 'account.attributes.first-name',
            lastName: 'account.attributes.last-name',
            year: 'date.year',
            payroll: 'account.customer-data.memberships.attribute.payroll',
            employeeCount: 'account.customer-data.memberships.attribute.employee-count',
            category: 'account.customer-data.memberships.attribute.category',
            invoice: 'account.customer-data.memberships.attribute.invoice',
        }
    };
    const getTranslationKey = (type, key) => {
        if (!translationKeys[type]) {
            return key;
        }
        if (translationKeys[type][key]) {
            return translationKeys[type][key];
        }
        return key;
    }
    const getLargeTableColumns = (groupKey) => {
        switch (groupKey) {
            case 'sia_member':
                return [
                    {name: 'name', label: translate(getTranslationKey('attribute', 'type')), class: 'medium-up'},
                    {
                        name: 'fromDate',
                        label: translate(getTranslationKey('attribute', 'entryDate')),
                        class: 'medium-up entry-date'
                    },
                    {
                        name: 'toDate',
                        label: translate(getTranslationKey('attribute', 'exitDate')),
                        class: 'medium-up exit-date'
                    },
                    {
                        name: 'duration',
                        label: translate(getTranslationKey('attribute', 'duration')),
                        class: 'medium-up',
                        alignment: {right: true},
                    },
                ];

            case 'sia_committee':
                return [
                    {name: 'role', label: translate(getTranslationKey('attribute', 'function')), class: 'medium-up'},
                    {
                        name: 'fromDate',
                        label: translate(getTranslationKey('attribute', 'from')),
                        class: 'medium-up from-date'
                    },
                    {
                        name: 'toDate',
                        label: translate(getTranslationKey('attribute', 'to')),
                        class: 'medium-up to-date'
                    },
                    {
                        name: 'lastElectionDate',
                        label: translate(getTranslationKey('attribute', 'lastElectionDate')),
                        class: 'medium-up last-election-date'
                    },
                ];

            case 'sections_member':
                return [
                    {name: 'name', label: translate(getTranslationKey('attribute', 'section')), class: 'medium-up'},
                    {
                        name: 'fromDate',
                        label: translate(getTranslationKey('attribute', 'from')),
                        class: 'medium-up from-date'
                    },
                    {
                        name: 'toDate',
                        label: translate(getTranslationKey('attribute', 'to')),
                        class: 'medium-up to-date'
                    },
                ];
            case 'sections_delegate':
            case 'sections_committee':
                return [
                    {name: 'name', label: translate(getTranslationKey('attribute', 'section')), class: 'medium-up'},
                    {
                        name: 'role',
                        label: translate(getTranslationKey('attribute', 'function')),
                        class: 'medium-up function'
                    },
                    {
                        name: 'fromDate',
                        label: translate(getTranslationKey('attribute', 'from')),
                        class: 'medium-up from-date'
                    },
                    {
                        name: 'toDate',
                        label: translate(getTranslationKey('attribute', 'to')),
                        class: 'medium-up to-date'
                    },
                    {
                        name: 'lastElectionDate',
                        label: translate(getTranslationKey('attribute', 'lastElectionDate')),
                        class: 'medium-up last-election-date'
                    },
                ];

            case 'professional_groups_member':
                return [
                    {
                        name: 'name',
                        label: translate(getTranslationKey('attribute', 'professionalGroup')),
                        class: 'medium-up'
                    },
                    {
                        name: 'fromDate',
                        label: translate(getTranslationKey('attribute', 'from')),
                        class: 'medium-up from-date'
                    },
                    {
                        name: 'toDate',
                        label: translate(getTranslationKey('attribute', 'to')),
                        class: 'medium-up to-date'
                    },
                ];
            case 'professional_groups_delegate':
            case 'professional_groups_council':
            case 'professional_groups_standeskommission':
            case 'professional_groups_committee':
                return [
                    {
                        name: 'name',
                        label: translate(getTranslationKey('attribute', 'professionalGroup')),
                        class: 'medium-up'
                    },
                    {
                        name: 'role',
                        label: translate(getTranslationKey('attribute', 'function')),
                        class: 'medium-up function'
                    },
                    {
                        name: 'fromDate',
                        label: translate(getTranslationKey('attribute', 'from')),
                        class: 'medium-up from-date'
                    },
                    {
                        name: 'toDate',
                        label: translate(getTranslationKey('attribute', 'to')),
                        class: 'medium-up to-date'
                    },
                    {
                        name: 'lastElectionDate',
                        label: translate(getTranslationKey('attribute', 'lastElectionDate')),
                        class: 'medium-up last-election-date'
                    },
                ];

            case 'sia_commissions_member':
                return [
                    {name: 'name', label: translate(getTranslationKey('attribute', 'commission')), class: 'medium-up'},
                    {
                        name: 'role',
                        label: translate(getTranslationKey('attribute', 'function')),
                        class: 'medium-up function'
                    },
                    {
                        name: 'fromDate',
                        label: translate(getTranslationKey('attribute', 'from')),
                        class: 'medium-up from-date'
                    },
                    {
                        name: 'toDate',
                        label: translate(getTranslationKey('attribute', 'to')),
                        class: 'medium-up to-date'
                    },
                    {
                        name: 'lastElectionDate',
                        label: translate(getTranslationKey('attribute', 'lastElectionDate')),
                        class: 'medium-up last-election-date'
                    },
                ];

            case 'trade_associations_member':
                return [
                    {
                        name: 'name',
                        label: translate(getTranslationKey('attribute', 'tradeAssociation')),
                        class: 'medium-up'
                    },
                    {
                        name: 'fromDate',
                        label: translate(getTranslationKey('attribute', 'from')),
                        class: 'medium-up from-date'
                    },
                    {
                        name: 'toDate',
                        label: translate(getTranslationKey('attribute', 'to')),
                        class: 'medium-up to-date'
                    },
                ];
            case 'trade_associations_directors':
                return [
                    {
                        name: 'name',
                        label: translate(getTranslationKey('attribute', 'tradeAssociation')),
                        class: 'medium-up'
                    },
                    {
                        name: 'role',
                        label: translate(getTranslationKey('attribute', 'function')),
                        class: 'medium-up function'
                    },
                    {
                        name: 'fromDate',
                        label: translate(getTranslationKey('attribute', 'from')),
                        class: 'medium-up from-date'
                    },
                    {
                        name: 'toDate',
                        label: translate(getTranslationKey('attribute', 'to')),
                        class: 'medium-up to-date'
                    },
                    {
                        name: 'lastElectionDate',
                        label: translate(getTranslationKey('attribute', 'lastElectionDate')),
                        class: 'medium-up last-election-date'
                    },
                ];
            case 'sia_memberships':
                return [
                    {name: 'name', label: translate(getTranslationKey('attribute', 'type')), class: 'medium-up type'},
                    {
                        name: 'from_date',
                        label: translate(getTranslationKey('attribute', 'entryDate')),
                        class: 'medium-up entryDate',
                        format: value => dateFormatter.formatForTable(value)
                    },
                    {
                        name: 'to_date',
                        label: translate(getTranslationKey('attribute', 'exitDate')),
                        class: 'medium-up exitDate',
                        format: value => dateFormatter.formatForTable(value)
                    },
                    {
                        name: 'membership_duration',
                        label: translate(getTranslationKey('attribute', 'duration')),
                        class: 'medium-up',
                        format: value => `${value} ${translate('date.duration.years')}`,
                        alignment: {right: true},
                    },
                ];
            case 'single_members_in_ogl':
                return [
                    {name: 'sia_id', label: translate('account.attributes.sia-id'), class: 'medium-up siaId'},
                    {
                        name: 'firstname',
                        label: translate(getTranslationKey('attribute', 'firstName')),
                        class: 'medium-up firstName'
                    },
                    {
                        name: 'lastname',
                        label: translate(getTranslationKey('attribute', 'lastName')),
                        class: 'medium-up'
                    },
                ];
            case 'ahv_entries':
                return [
                    {
                        name: 'ahv_year',
                        label: translate(getTranslationKey('attribute', 'year')),
                        class: 'medium-up year'
                    },
                    /**
                     * such inconsistencies between translation key and property from api schema happens because
                     * currently api schema is defined after frontend development.
                     */
                    {
                        name: 'ahv_paysum',
                        label: translate(getTranslationKey('attribute', 'payroll')),
                        class: 'medium-up ahv_paysum',
                        format: value => currencyFormatter.format(value, false),
                        alignment: {right: true},
                    },
                    {
                        name: 'number_of_employees',
                        label: translate(getTranslationKey('attribute', 'employeeCount')),
                        class: 'medium-up',
                        alignment: {right: true},
                    },
                    {
                        name: 'ahv_category',
                        label: translate(getTranslationKey('attribute', 'category')),
                        class: 'medium-up',
                        alignment: {right: true},
                    },
                    // { // todo, temporary hidden by SW-399
                    //     name: 'ahv_certificate_pdf',
                    //     label: translate(getTranslationKey('attribute', 'invoice')),
                    //     class: 'medium-up invoice',
                    //     format: (value, item) => {
                    //         if (!value) {
                    //             return;
                    //         }
                    //         const downloadString = downloader.createBase64Href('pdf', item.ahv_certificate_pdf);
                    //         const groupName = translate(getTranslationKey('group', 'ahv_entries'));
                    //         const fileName = `${groupName}_${item.ahv_year}.pdf`;
                    //         return <a download={fileName} href={downloadString}><Pdf24/></a>
                    //     }
                    // },
                ];
            case 'parent':
            case 'subsidiaries':
                return [
                    {name: 'sia_id', label: translate('account.attributes.sia-id'), class: 'medium-up siaId'},
                    {name: 'name', label: translate('account.attributes.company-name'), class: 'medium-up'},
                    {
                        name: 'city',
                        label: translate('account.customer-data.addresses.address.location'),
                        class: 'medium-up'
                    },
                    {
                        name: 'admission_date',
                        label: translate('account.customer-data.memberships.attribute.entry-date'),
                        class: 'medium-up',
                        format: value => dateFormatter.formatForTable(value)
                    },
                ];
            case 'trade_associations_memberships':
                return [
                    {
                        name: 'code',
                        label: translate(getTranslationKey('attribute', 'tradeAssociation')),
                        class: 'medium-up tradeAssociation'
                    },
                    {
                        name: 'from_date',
                        label: translate(getTranslationKey('attribute', 'from')),
                        class: 'medium-up from',
                        format: value => dateFormatter.formatForTable(value)
                    },
                    {
                        name: 'to_date',
                        label: translate(getTranslationKey('attribute', 'to')),
                        class: 'medium-up to',
                        format: value => dateFormatter.formatForTable(value)
                    },
                    { // todo, adding this empty column because strangely on this table, if only 3 columns, the css widths are not applied.
                        name: '',
                        label: '',
                        class: 'medium-up',
                    },
                ];
            default:
                return [];
        }
    }
    const getTableActions = (groupKey) => {
        const todoCallback = (id, action) => alert(`TODO: ${action} "${groupKey}" (ID: ${id})`);
        switch (groupKey) {
            case 'sia_member':
                return [
                    {name: 'mutate', onClick: (id) => todoCallback(id, 'mutate')},
                    {name: 'remove', onClick: (id) => todoCallback(id, 'remove')},
                ];
            case 'sections_member':
                return [
                    {name: 'remove', onClick: (id) => todoCallback(id, 'remove')},
                ];
            case 'trade_associations_member':
            case 'trade_associations_directors':
                return [
                    {name: 'change', onClick: (id) => todoCallback(id, 'change')},
                    {name: 'remove', onClick: (id) => todoCallback(id, 'remove')},
                ];
            default:
                return [];
        }
    }
    const groupMemberships = (memberships) => {
        const membershipGroups = {};
        memberships.forEach(membership => {
            if (!membershipGroups[membership.group]) {
                membershipGroups[membership.group] = [];
            }
            membershipGroups[membership.group].push(membership);
        })
        return membershipGroups;
    }
    const categorizeMembershipGroups = useCallback((membershipGroups) => {
        const categories = {};
        const orderedCategoryKeys = Object.keys(orderedKeys);
        const getCategoryKey = (itemGroupKey) => orderedCategoryKeys.find(categoryKey => itemGroupKey.startsWith(categoryKey));
        Object.keys(membershipGroups).forEach(itemGroupKey => {
            const categoryKey = getCategoryKey(itemGroupKey);
            if (!categoryKey) {
                return;
            }
            if (!categories[categoryKey]) {
                categories[categoryKey] = {};
            }
            if (!categories[categoryKey][itemGroupKey]) {
                categories[categoryKey][itemGroupKey] = {};
            }
            categories[categoryKey][itemGroupKey] = membershipGroups[itemGroupKey];
        })
        return categories;
    }, [orderedKeys]);

    useEffect(() => {
        if (user && !wasMembershipsFetchStarted) {
            setWasMembershipsFetchStarted(true);
            webPlatformAdapter.request('get', endpoints[user.getMemberType().getGroup()], {lang: getLocale()}, (response) => {
                if (user.isMemberTypeGroupOf('single')) {
                    const memberships = response.data.map(item => {
                        return {
                            id: item.membership_id,
                            group: item.membership_group,
                            name: item.name,
                            role: item.role,
                            fromDate: dateFormatter.formatForTable(item.from_date),
                            toDate: dateFormatter.formatForTable(item.to_date),
                            duration: `${item.membership_duration} ${translate('date.duration.years')}`,
                            lastElectionDate: dateFormatter.formatForTable(item.last_election),
                            canBeCancelled: item.can_be_cancelled,
                            mutationTo: item.mutation_to,
                        }
                    });
                    const membershipGroups = groupMemberships(memberships);
                    const membershipGroupCategories = categorizeMembershipGroups(membershipGroups);
                    setMembershipGroupCategories(membershipGroupCategories);
                    return;
                }
                if (user.isMemberTypeGroupOf('corporate')) {
                    const membershipGroupCategories = {corporate: response.data};
                    setMembershipGroupCategories(membershipGroupCategories);
                }
            });
        }
    }, [user, wasMembershipsFetchStarted, categorizeMembershipGroups, translate, endpoints])

    const renderMobileCellValue = (largeTableItem, largeTableColumns) => {
        return Object.keys(largeTableItem).map(property => {
            const value = largeTableItem[property];
            if (!value) {
                return null;
            }
            const largeTableColumn = largeTableColumns.find(tableColumns => tableColumns.name === property);
            if (!largeTableColumn) {
                return null;
            }
            const formattedValue = largeTableColumn.format ? largeTableColumn.format(value, largeTableItem) : value;
            return <p key={property}>
                <label>{textFormatter.uppercaseFirst(largeTableColumn.label)}</label><br/>
                {formattedValue}
            </p>;
        });
    }
    const renderMembershipGroups = (membershipGroups, orderedGroupKeys = []) => {
        const membershipGroupKeys = Object.keys(membershipGroups).filter(key => membershipGroups[key].length > 0);
        const orderedMembershipGroupKeys = sorter.sortFromArray(membershipGroupKeys, orderedGroupKeys);
        return orderedMembershipGroupKeys.map(membershipGroupKey => {
            const memberships = membershipGroups[membershipGroupKey];
            const largeTableColumns = getLargeTableColumns(membershipGroupKey);
            const largeTableColumnNames = largeTableColumns.map(column => column.name);
            const largeTableItems = memberships.map(membership => {
                const getIdentifier = () => {
                    if (membership.id) {
                        return membership.id.toString();
                    }
                    if (membership.sia_id) {
                        return membership.sia_id.toString();
                    }
                }
                const item = {id: getIdentifier()};
                largeTableColumnNames.forEach(columnName => {
                    item[columnName] = membership[columnName];
                })
                return item;
            });
            const smallColumnLabel = translate('account.invoices.data.memberships.data-column-mobile');
            const smallTableColumn = {name: 'mobile', label: smallColumnLabel, class: 'small-only'};
            const tableColumns = [smallTableColumn].concat(largeTableColumns);
            const tableItems = largeTableItems.map(largeTableItem => {
                const tableItem = Cloner.clone(largeTableItem);
                tableItem['mobile'] = renderMobileCellValue(largeTableItem, largeTableColumns);
                return tableItem;
            });
            const tableActions = getTableActions('non-existing'); // todo, actions temporary hidden by SW-248
            return <React.Fragment key={membershipGroupKey}>
                <h3>{translate(getTranslationKey('group', membershipGroupKey))}</h3>
                <Table columns={tableColumns} items={tableItems}
                       className={membershipGroupKey}
                       sortable={false}
                       actions={tableActions}
                />
            </React.Fragment>;
        });
    }
    const createTableCategoriesForSingleMember = () => {
        const membershipGroupCategoryKeys = Object.keys(membershipGroupCategories);
        const orderedCategoryKeys = Object.keys(orderedKeys);
        const orderedMembershipGroupCategoryKeys = sorter.sortFromArray(membershipGroupCategoryKeys, orderedCategoryKeys);
        return orderedMembershipGroupCategoryKeys.map(membershipGroupCategoryKey => {
            const excludedTitles = ['sia_member', 'sia_committee'];
            const shouldRenderTitle = !excludedTitles.includes(membershipGroupCategoryKey);
            const categoryTitle = shouldRenderTitle ? translate(getTranslationKey('title', membershipGroupCategoryKey)) : null;
            const membershipGroups = membershipGroupCategories[membershipGroupCategoryKey];
            const orderedGroupKeys = orderedKeys[membershipGroupCategoryKey];
            const groups = renderMembershipGroups(membershipGroups, orderedGroupKeys);
            return new MembershipTableCategory(membershipGroupCategoryKey, categoryTitle, groups);
        })
    }
    const createTableCategoriesForCorporateMember = () => {
        const membershipGroupCategoryKeys = Object.keys(membershipGroupCategories);
        return membershipGroupCategoryKeys.map(membershipGroupCategoryKey => {
            const membershipGroups = membershipGroupCategories[membershipGroupCategoryKey];
            const groups = renderMembershipGroups(filterer.removeNulls(membershipGroups));
            return new MembershipTableCategory(membershipGroupCategoryKey, null, groups);
        })
    }
    const renderTableCategories = () => {
        const tableCategories = user.isMemberTypeGroupOf('single') ?
            createTableCategoriesForSingleMember() :
            createTableCategoriesForCorporateMember();

        return tableCategories.map(tableCategory => {
            return <React.Fragment key={tableCategory.getId()}>
                {tableCategory.getTitle() && <h2>{tableCategory.getTitle()}</h2>}
                {tableCategory.getGroups()}
            </React.Fragment>
        })
    }
    return <Page contentNavigation={<UserContentNavigation/>} title={'account.name'}>
        <Content className={'memberships'} centeredTitle={true}>
            <h1>
                <BackButton onClick={() => history.push(pathFactory.create('account/customer-data'))}/>
                {title}
            </h1>
            {renderTableCategories()}
        </Content>
    </Page>;
}
export default MembershipsPage;
