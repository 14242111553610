class LoginInformationDataFactory {

    createUserInfoByResponseData(responseData, memberType) {
        const siaId = responseData[0].sia_id;
        if (memberType === 'single') {
            return {
                siaId: siaId,
                firstName: responseData[0].firstname,
                lastName: responseData[0].lastname,
            };
        }

        if (memberType === 'corporate') {
            return {
                siaId: siaId,
                companyName: responseData[0].name,
                additionalName: responseData[0].additional_name,
            };
        }
    }
}

const loginInformationDataFactory = new LoginInformationDataFactory();
export default loginInformationDataFactory;