import React,{useState,useEffect} from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

// this was a bug see:
// https://github.com/react-dnd/react-dnd/issues/186#issuecomment-978206387

const DragAndDrop = React.memo((props) => {
  const [context, setContext] = useState(null);

  useEffect(() => {
    setContext(document.getElementById(props.id));
  }, [props.id]);

  return context ? (
    <DndProvider backend={HTML5Backend} options={{ rootElement: context }}>
      {props.children}
    </DndProvider>
  ) : null;
});

export default DragAndDrop;