import React, { useContext, useEffect, useState } from "react";
import { useTranslator } from "common/Translation/Translator";
import { useHistory } from "react-router-dom";
import UserContentNavigation from "pages/Account/UserContentNavigation";
import IDPAdapter from "common/Adapters/IDPAdapter/IDPAdapter";
import Page from "pages/Page";
import GlobalContext from "contexts/GlobalContext";
import loginInformationDataFactory from "./LoginInformationDataFactory";
import { usePathFactory } from "common/Path/PathFactoryHook";
import webPlatformAdapter from "common/Adapters/WebPlatformAdapter/WebPlatformAdapter";
import erpAdapter from "adapters/ERPAdapter";
import { InfoBlock, Form, Link, Content } from "@sia/style-guide";
import EmailInput from "components/EmailInput";
import { BackButton } from "@sia/style-guide/dist/components/BackButton/BackButton";

const LoginInformationPage = () => {
  const translate = useTranslator();
  const pathFactory = usePathFactory();
  const [userInfo, setUserInfo] = useState(null);
  const [email, setEmail] = useState("");
  const [wasCustomerDataFetchStarted, setWasCustomerDataFetchStarted] = useState(false);
  const [wasLoginInformationFetchStarted, setWasLoginInformationFetchStarted] = useState(false);
  const [invalidEmailText, setInvalidEmailText] = useState("");
  const history = useHistory();
  const GlobalContextValue = useContext(GlobalContext);
  const user = GlobalContextValue.user;
  const language = GlobalContextValue.language;
  const title = translate("account.customer-data.login-information");
  const REQUEST_DELAY = 2000;
  const userAttributeTranslationKeys = {
    siaId: "account.attributes.sia-id",
    firstName: "account.attributes.first-name",
    lastName: "account.attributes.last-name",
    companyName: "account.attributes.company-name",
    additionalName: "account.attributes.additional-name",
  };
  const onSave = () => {
    setInvalidEmailText("");
    const data = { website_login_email: email };
    erpAdapter.requestWithDelay(
      "patch",
      "appAccountTechnicalEmail",
      data,
      (response) => {
        return null;
      },
      () => setInvalidEmailText(translate("api.error.value.invalid")),
      REQUEST_DELAY
    );
  };
  useEffect(() => {
    if (!wasCustomerDataFetchStarted && user && user.getMemberType()) {
      setWasCustomerDataFetchStarted(true);
      webPlatformAdapter.getCustomerData(user, (response) => {
        setUserInfo(loginInformationDataFactory.createUserInfoByResponseData(response.data, user.getMemberType().getGroup()));
      });
    }
    if (!wasLoginInformationFetchStarted && user && language) {
      setWasLoginInformationFetchStarted(true);
      webPlatformAdapter.getLoginInformation(language, (response) => {
        const email = response.data[0].login_email;
        setEmail(email ? email : "");
      });
    }
  }, [wasCustomerDataFetchStarted, wasLoginInformationFetchStarted, user, language]);

  const content = !userInfo ? null : (
    <>
      <InfoBlock>
        <span className={"label"}>{translate(userAttributeTranslationKeys.siaId)}</span>
        <p>{userInfo.siaId}</p>

        {userInfo.firstName && (
          <>
            <span className={"label"}>{translate(userAttributeTranslationKeys.firstName)}</span>
            <p>{userInfo.firstName}</p>
          </>
        )}

        {userInfo.lastName && (
          <>
            <span className={"label"}>{translate(userAttributeTranslationKeys.lastName)}</span>
            <p>{userInfo.lastName}</p>
          </>
        )}

        {userInfo.companyName && (
          <>
            <span className={"label"}>{translate(userAttributeTranslationKeys.companyName)}</span>
            <p>{userInfo.companyName}</p>
          </>
        )}

        {userInfo.additionalName && (
          <>
            <span className={"label"}>{translate(userAttributeTranslationKeys.additionalName)}</span>
            <p>{userInfo.additionalName}</p>
          </>
        )}
      </InfoBlock>
      <p>
        <Link target={IDPAdapter.redirectToChangePasswordPage}>{translate("account.customer-data.login-information.change-password")}</Link>
      </p>
      <Form primaryButtonLabel={translate("form.save")} onClickPrimaryButton={onSave}>
        <EmailInput
          id={"change-email"}
          label={translate("account.customer-data.login-information.change-email")}
          onChange={(event, value, error) => {
            setEmail(value);
            setInvalidEmailText(error);
          }}
          value={email}
          helperText={translate("account.customer-data.login-information.change-email.helper-text")}
          invalidText={invalidEmailText}
        />
      </Form>
    </>
  );
  return (
    <Page contentNavigation={<UserContentNavigation />} title={"account.name"}>
      <Content width={"slim"}>
        <h1>
          <BackButton onClick={() => history.push(pathFactory.create("account/customer-data"))} />
          {title}
        </h1>
        {content}
      </Content>
    </Page>
  );
};
export default LoginInformationPage;
