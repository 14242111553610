import React, { useReducer } from "react";

export const initialWizardFormState = {
  // masterGraduationDate:"",
  // bachelorGraduationDate:"",
  // htlGraduationDate:"",
  single: {
    personalInfo: {
      gender: "",
      firstName: "",
      lastName: "",
      nationality: "",
      dateOfBirth: "",
      language: null,
      diplomTitle: "",
      academicTitle: null,
      additionalInfo: "",
      street: "",
      zip: "",
      city: "",
      country: null,
      canton: null,
      phone: "",
      mobile: "",
      email: "",
    },
    workplaceInfo: {
      hasWorkplace: false,
      currentAddress: null,
      companyName: "",
      department: "",
      street: "",
      zip: "",
      city: "",
      country: null,
      canton: null,
      phone: "",
      mobile: "",
      email: "",
      since: "",
      profession: null,
      jobFunction: null,
      branch: null,
      activities: null,
    },
    otherAddressesInfo: {
      contact: null,
      delivery: null,
      invoice: null,
    },
    educationInfo: {
      schoolName: null,
      studyType: null,
      graduationDate: "",
      diplomTitle: "",
      diplomFile: null,
      referencesFile: null,
      masterSchoolName: null,
      masterStudyType: null,
      masterGraduationDate: "",
      masterTitle: "",
      masterFile: null,
      doctorateSchoolName: null,
      doctorateStudyType: null,
      doctorateGaduationDate: "",
      doctorateTitle: "",
      doctorateFile: null,
    },
    sectionInfo: {
      price: null,
    },
    communicationInfo: {
      selectedMagazine: "",
      magazineFormatEpaper: false,
      magazineFormatPrinted: false,
      newsLetterGerman: false,
      newsLetterFrench: false,
    },
  },
};

const SINGLE_PERSONAL_INFO_CHANGED_ACTION = "SINGLE_PERSONAL_INFO_CHANGED_ACTION";
export const singlePersonalInfoActionCreator = (payload) => ({ type: SINGLE_PERSONAL_INFO_CHANGED_ACTION, payload });

const SINGLE_WORKPLACE_INFO_CHANGED_ACTION = "SINGLE_WORKPLACE_INFO_CHANGED_ACTION";
export const singleWorkPlaceInfoActionCreator = (payload) => ({ type: SINGLE_WORKPLACE_INFO_CHANGED_ACTION, payload });

const SINGLE_OTHER_ADDRESSES_INFO_CHANGED_ACTION = "SINGLE_OTHER_ADDRESSES_INFO_CHANGED_ACTION";
export const singleOtherAddressesInfoActionCreator = (payload) => ({ type: SINGLE_OTHER_ADDRESSES_INFO_CHANGED_ACTION, payload });

const SINGLE_EDUCATION_INFO_CHANGED_ACTION = "SINGLE_EDUCATION_INFO_CHANGED_ACTION";
export const singleEducationInfoActionCreator = (payload) => ({ type: SINGLE_EDUCATION_INFO_CHANGED_ACTION, payload });

const SINGLE_SECTION_INFO_CHANGED_ACTION = "SINGLE_SECTION_INFO_CHANGED_ACTION";
export const singleSectionInfoActionCreator = (payload) => ({ type: SINGLE_SECTION_INFO_CHANGED_ACTION, payload });

const SINGLE_COMMUNICATION_INFO_CHANGED_ACTION = "SINGLE_COMMUNICATION_INFO_CHANGED_ACTION";
export const singleCommunicationInfoActionCreator = (payload) => ({ type: SINGLE_COMMUNICATION_INFO_CHANGED_ACTION, payload });

const reducer = (state, action) => {
  switch (action.type) {
    case SINGLE_PERSONAL_INFO_CHANGED_ACTION:
      return { ...state, single: { ...state.single, personalInfo: { ...state.single.personalInfo, ...action.payload } } };

    case SINGLE_WORKPLACE_INFO_CHANGED_ACTION:
      return { ...state, single: { ...state.single, workplaceInfo: { ...state.single.workplaceInfo, ...action.payload } } };

    case SINGLE_OTHER_ADDRESSES_INFO_CHANGED_ACTION:
      return { ...state, single: { ...state.single, otherAddressesInfo: { ...state.single.otherAddressesInfo, ...action.payload } } };

    case SINGLE_EDUCATION_INFO_CHANGED_ACTION:
      return { ...state, single: { ...state.single, educationInfo: { ...state.single.educationInfo, ...action.payload } } };

    case SINGLE_SECTION_INFO_CHANGED_ACTION:
      return { ...state, single: { ...state.single, sectionInfo: { ...state.single.sectionInfo, ...action.payload } } };

    case SINGLE_COMMUNICATION_INFO_CHANGED_ACTION:
      return { ...state, single: { ...state.single, communicationInfo: { ...state.single.communicationInfo, ...action.payload } } };

    default:
      return state;
  }
};

export const WizarContext = React.createContext({ state: {}, dispatch: () => null });

const WizardProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialWizardFormState);
  return <WizarContext.Provider value={{ state, dispatch }}>{props.children}</WizarContext.Provider>;
};

export default WizardProvider;
