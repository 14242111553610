import IDPAdapter from "common/Adapters/IDPAdapter/IDPAdapter";

class Redirector {

    redirectToLoginForm(locale) {
        window.location = IDPAdapter.createLoginUrl(locale);
    };

}

const redirector = new Redirector();
export default redirector;