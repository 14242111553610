import React from 'react';
import PropTypes from "prop-types";
import {Content} from '@sia/style-guide';
import {useTranslator} from "common/Translation/Translator";
import Page from "../Page";

const NotFoundPage = (props) => {
    const translate = useTranslator();
    const message = props.message ? props.message : 'routing.page.not-found.text';
    return <Page>
        <Content>
            <h1>{translate('routing.page.not-found.title')}</h1>
            <p>{translate(message)}</p>
        </Content>
    </Page>;
};
NotFoundPage.propTypes = {
    message: PropTypes.string,
};
export default NotFoundPage;