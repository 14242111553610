import Country from "./Country";

const countries = [
    {id: 44, name: 'switzerland'},
];

class CountryRepository {

    find(id) {
        const country = countries.find(country => (country.id === id));
        return this.foundObjectToType(country);
    }

    findByName(name) {
        const country = countries.find(country => (country.name === name));
        return this.foundObjectToType(country);
    }

    foundObjectToType(object) {
        return object ? new Country(object.id, object.name) : null;
    }

}

const countryRepository = new CountryRepository();
export default countryRepository;