const getMedia = (gallery) => {
  if (!gallery) {
    return;
  }

  const media = {
    items: gallery?.map((item) => {
      const mediaItem = item[0];
      if (mediaItem.original) {
        return {
          src: mediaItem?.desktop?.url,
          srcTablet: mediaItem?.tablet?.url,
          srcMobile: mediaItem?.mobile?.url,
          type: "IMAGE",
          copyright: {
            title: mediaItem.title,
            copyright: mediaItem.copyright,
          },
        };
      }
      return {
        src: mediaItem.url,
        type: "VIDEO",
        imagePlaceholder: mediaItem.teaser_image[0]?.desktop?.url,
        imagePlaceholderTablet: mediaItem.teaser_image[0]?.tablet?.url,
        imagePlaceholderMobile: mediaItem.teaser_image[0]?.mobile?.url,
        copyright: {
          titleVideo: mediaItem.title,
          copyrightVideo: mediaItem.copyright,
          title: mediaItem.teaser_image[0]?.title,
          copyright: mediaItem.teaser_image[0]?.copyright,
        },
        start_second: mediaItem.start_second,
        stop_second: mediaItem.stop_second,
      };
    }),
    autoplay: false,
  };
  return media;
};

export const formatSIAPageCT = (page) => {
  const page_lead = page.sia_page_lead ? page.sia_page_lead[0] : "";
  const page_bottom = page.page_bottom ? page.page_bottom : [];

  const page_sub_lead = page.sia_page_sublead || [];
  const errors = [];
  const media = getMedia(page.sia_page_gallery);
  return {
    errors,
    title: page.title,
    page_top: {
      page_lead,
      page_sub_lead,
      media,
      bgColor: page.background_color?.name || "SIAwhite",
    },
    page_bottom,
    section: page.section,
    content_type: page.content_type,
  };
};

export const formatEventOccurrence = (event) => {
  const media = getMedia(event.event_data.pictures_gallery);
  const event_price = event.event_price ? event.event_price : [];
  const get_unique_locations = (event) => {
    const locations = new Set();

    if (event.event_generic_location) {
      locations.add(event.event_generic_location.name);
    }
    event.date_times.forEach((date_time) => {
      if (date_time.event_generic_location) {
        locations.add(date_time.event_generic_location.name);
      }
    });
    return Array.from(locations);
  };

  const event_locations = get_unique_locations(event);

  return {
    hash: event.hash,
    occurence_code: event.occurence_code,
    date_times: event.date_times,
    content_type: event.content_type,
    event_format: event.format.name,
    event_organisator: event.event_data.event_organisator.name,
    event_locations: event_locations,
    languages: event.event_data.language,
    title: event.event_data.title,
    event_lead: event.event_data.event_lead,
    event_sublead: event.event_data.event_sublead,
    event_content: event.event_data.event_content,
    pictures_gallery: media?.items,
    partners: Object.assign(
      event.event_data.event_partner?.map((partner) => ({
        src: partner.logo?.values?.[0]?.desktop?.url || "",
        href: partner.url?.url || "",
        width: "100%",
        height: "auto",
      })) || []
    ),
    event_price: event_price,
    speaker: event.speaker,
    media: media?.items,
  };
};

export const formatMenuItems = (data, history, language) => {
  const formatItem = (item) => {
    const key = item.alias ? `/${language}/cms${item.alias}` : `/${language}${item.url}`;
    if (item.children?.length > 0) {
      return {
        title: item.title,
        children: item.children
          .sort((el, el2) => parseInt(el.weight) - parseInt(el2.weight))
          .map(formatItem)
          .filter((el) => el.children?.length > 0 || el.action !== undefined),
      };
    }
    return {
      title: item.title,
      id: item.target_node_id,
      basePath: item.basePath,
      alias: item.alias,
      alias_de: item.alias_de,
      alias_fr: item.alias_fr,
      alias_it: item.alias_it,
      alias_en: item.alias_en,
      to: item.url?.includes("https:") ? item.url : key,
      action:
        item.url?.length > 0
          ? () => {
              if (item.url.includes("https:")) {
                window.open(item.url, "_blank").focus();
              } else {
                history.push(key);
              }
            }
          : undefined,
    };
  };
  const menuItems = data
    .sort((el, el2) => parseInt(el.weight) - parseInt(el2.weight))
    .map(formatItem)
    .filter((el) => el.children?.length > 0 || el.action !== undefined);
  return {
    menuItems,
  };
};

const formatMenuItem = (item, language, code) => {
  const section_code = code || item.section_code;
  const to = section_code ? `/${language}/sektion/${section_code}/${item.target_node_id}` : "";
  if (item.children?.length > 0) {
    return {
      title: item.title,
      children: item.children
        .sort((el, el2) => parseInt(el.weight) - parseInt(el2.weight))
        .map((item) => formatMenuItem(item, language, section_code))
        .filter((el) => el.children?.length > 0 || el.to),
    };
  }
  return {
    title: item.title,
    id: item.target_node_id,
    basePath: item.basePath,
    alias: item.alias,
    alias_de: item.alias_de,
    alias_fr: item.alias_fr,
    alias_it: item.alias_it,
    alias_en: item.alias_en,
    to: to ? to : item.url?.includes("https:") ? item.url : item.alias ? `${window.location.origin}/${language}/cms${item.alias}` : undefined,
  };
};

export const formatSectionMenu = (data, language) => {
  const menuItems = data
    .sort((el, el2) => parseInt(el.weight) - parseInt(el2.ght))
    .map((item) => formatMenuItem(item, language))
    .filter((el) => el.children?.length > 0 || el.to);
  return {
    menuItems: menuItems,
  };
};

const formatAgendaItem = (item, translate) => {
  const times = item.date_times || item.times;
  const month_number = times[0]?.date[0]?.split("-")[1];
  const event = item.occurrence_event_code && item.occurrence_event_code[0];
  const location =
    item.content_type === "event_occurrence" ? item.event_generic_location[0]?.name : item.generic_location && item.generic_location[0]?.name;
  const locations = [
    ...new Set([...(location ? [location] : []), ...(times ? times.filter((el) => el.generic_location)?.map((el) => el.generic_location) : [])]),
  ];
  return {
    ...item,
    organisator: item.event_organisator ? item.event_organisator : event?.event_organisator && event?.event_organisator[0]?.name,
    locations,
    language: event?.language && event?.language[0]?.name,
    languages: event?.language && event?.language.map((el) => el.name),
    occurence_suffix: item.occurence_suffix && item.occurence_suffix[0],
    name:
      (item?.title || "") +
      (event?.title || "") +
      (event?.event_lead || "") +
      (event?.event_sublead || "") +
      ((item.occurence_code && item.occurence_code[0]) || ""),
    title: event?.title || item.title,
    theme: event?.sia_core_topics?.map((el) => el.name),
    month: translate(`cms.agenda.month.${month_number}`),
    type: item.format && item.format[0]?.name,
    partner: event?.event_partner && event?.event_partner[0]?.name,
    code: item.occurence_code && item.occurence_code[0],
    date_seconds: new Date(times[0]?.date[0]).getTime() / 1000,
    status: event?.event_occurrence_status || item?.event_occurrence_status,
  };
};
export const formatAgenda = (data, translate) => {
  const items = data.map((item) => formatAgendaItem(item, translate));
  const response = {
    event_occurrences: items,
    organisators: [...new Set(items.map((item) => item.organisator).filter((item) => !!item))],
    languages: [
      ...new Set(
        items
          .map((item) => item.languages)
          .filter((item) => !!item)
          .flat()
          .filter((item) => !!item)
      ),
    ],
    locations: [
      ...new Set(
        items
          .map((item) => item.locations)
          .filter((item) => !!item)
          .flat()
          .filter((item) => !!item)
      ),
    ],
    themes: [
      ...new Set(
        items
          .map((item) => item.theme)
          .filter((item) => !!item)
          .flat()
          .filter((item) => !!item)
      ),
    ],
    months: [...new Set(items.map((item) => item.month).filter((item) => !!item))],
    types: [...new Set(items.map((item) => item.type).filter((item) => !!item))],
    partners: [...new Set(items.map((item) => item.partner).filter((item) => !!item))],
  };
  return response;
};
