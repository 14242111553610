import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useTranslator} from "common/Translation/Translator";
import UserContentNavigation from "../UserContentNavigation";
import Page from "pages/Page";
import GlobalContext from "contexts/GlobalContext";
import webPlatformAdapter from "common/Adapters/WebPlatformAdapter/WebPlatformAdapter";
import "./Invoices.scss";
import {Content, Table, dateFormatter, currencyFormatter, sorter, AppContent} from "@sia/style-guide";

const InvoicesPage = () => {
    const translate = useTranslator();
    const [wasInvoicesFetchStarted, setWasInvoicesFetchStarted] = useState(false);
    const [items, setItems] = useState([]);
    const GlobalContextValue = useContext(GlobalContext);
    const user = GlobalContextValue.user;
    const language = GlobalContextValue.language;
    const columns = [
        {
            name: 'date',
            label: translate('account.invoices.date'),
            class: 'medium-up',
            format: value => dateFormatter.formatForTable(value)
        },
        {name: 'mobile', label: translate('account.invoices.data-column-mobile'), sortable: false, class: 'small-only'},
        {name: 'number', label: translate('account.invoices.number'), class: 'medium-up'},
        {name: 'title', label: translate('account.invoices.title'), class: 'medium-up'},
        {
            name: 'brutto',
            label: translate('account.invoices.brutto'),
            format: value => currencyFormatter.format(value, false),
            alignment: {right: true},
        },
        {
            name: 'status',
            label: translate('account.invoices.status'),
            class: 'medium-up',
            format: value => translate(statusTranslationKeys[value])
        },
    ];

    const statusTranslationKeys = useMemo(() => {
        return {
            paid: translate('account.invoices.status.paid')
        }
    }, [translate])

    const renderMobileCellValue = useCallback((row) => {
        return <>
            <p>
                <label>{translate('account.invoices.date')}</label><br/>
                {dateFormatter.formatForTable(row.creation_date)}
            </p>
            <p>
                <label>{translate('account.invoices.number')}</label><br/>
                {row.number}
            </p>
            <p>
                <label>{translate('account.invoices.title')}</label><br/>
                {row.title}
            </p>
            <p>
                <label>{translate('account.invoices.status')}</label><br/>
                {translate(statusTranslationKeys[row.status])}
            </p>
        </>
    }, [statusTranslationKeys, translate])

    useEffect(() => {
        if (user && !wasInvoicesFetchStarted) {
            setWasInvoicesFetchStarted(true);
            webPlatformAdapter.getInvoices(language, (response) => {
                const items = response.data.map(row => {
                    return {
                        id: row.number,
                        date: row.creation_date,
                        mobile: renderMobileCellValue(row),
                        number: row.number,
                        title: row.title,
                        brutto: row.amount,
                        status: row.status
                    }
                })
                setItems(sorter.sortByProperty(items, 'date').reverse());
            })
        }
    }, [user, wasInvoicesFetchStarted, translate, renderMobileCellValue, statusTranslationKeys,language])

    return <Page contentNavigation={<UserContentNavigation/>} title={'account.name'}>
        <Content className={'invoices'}>
            <AppContent>
                {Boolean(items.length) && <p>{translate('account.invoices.description')}.</p>}
                {!Boolean(items.length) && <p>{translate('account.invoices.not-existing')}.</p>}
            </AppContent>
            {Boolean(items.length) && <Table columns={columns} items={items}/>}
        </Content>
    </Page>;
}

export default InvoicesPage;
