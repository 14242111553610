import React, { useContext, useEffect } from "react";
import GlobalContext from "contexts/GlobalContext";
import { useHistory, useParams } from "react-router-dom";
import cmsAdapter from "../../common/Adapters/CMSAdapter/CMSAdapter";
import { useState } from "react";
import { SIASearch } from "@sia/style-guide";
import { useTranslator } from "../../common/Translation/Translator";

const CMSSearch = () => {
  const { language: languageKey } = useContext(GlobalContext);
  const [data, setData] = useState(undefined);
  const { text } = useParams();
  const history = useHistory();
  const translate = useTranslator();

  useEffect(() => {
    text && languageKey && cmsAdapter.globalSearch((data) => setData(data), languageKey, text);
  }, [text, languageKey]);

  useEffect(() => {
    const createBrowserTitle = () => {
      const browserTitle = translate("browser.title");
      if (text) {
        return `${text}  - ${browserTitle}`;
      }
      return browserTitle;
    };
    document.title = createBrowserTitle();
  }, [translate, text]);

  return data !== undefined ? <SIASearch lang={languageKey} history={history} data={data} text={text} translate={translate} /> : <></>;
};

export default CMSSearch;
