import React, { useContext, useEffect } from "react";
import GlobalContext from "contexts/GlobalContext";
import { SIACatalog } from "@sia/style-guide";
import { useHistory } from "react-router-dom";
import { useTranslator } from "../../common/Translation/Translator";
import cmsAdapter from "../../common/Adapters/CMSAdapter/CMSAdapter";

const Catalog = () => {
  const { language } = useContext(GlobalContext);
  const history = useHistory();
  const translate = useTranslator();

  const getArticles = (params, onSuccess) => {
    cmsAdapter.searchArticles(language, params, onSuccess);
  };
  const getFilters = (onSuccess) => {
    cmsAdapter.articlesFilters(language, onSuccess);
  };
  useEffect(() => {
    const createBrowserTitle = () => {
      const browserTitle = translate("browser.title");
      return `${translate("header.app-switcher.catalog.name")} - ${browserTitle}`;
    };
    document.title = createBrowserTitle();
  }, [translate]);
  return (
    language && (
      <SIACatalog
        lang={language}
        history={history}
        translate={(value, options) => translate(value, options)}
        getArticles={getArticles}
        getFilters={getFilters}
      ></SIACatalog>
    )
  );
};

export default Catalog;
