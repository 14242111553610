import React from 'react';
import {Content} from '@sia/style-guide';
import {useTranslator} from "common/Translation/Translator";
import Page from "../Page";

const PermissionDeniedPage = () => {
    const translate = useTranslator();
    return <Page>
        <Content>
            <h1>{translate('routing.page.permission-denied.title')}</h1>
            <p>{translate('routing.page.permission-denied.text')}</p>
        </Content>
    </Page>;
};
export default PermissionDeniedPage;