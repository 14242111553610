class Filterer {
    removeNulls(object) {
        const keys = Object.keys(object);
        const newObject = {};
        keys.forEach(key => {
            if (object[key] !== null) {
                newObject[key] = object[key];
            }
        })
        return newObject;
    }
}

const filterer = new Filterer();
export default filterer;