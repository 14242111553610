import {useRef} from 'react'
import PropTypes from 'prop-types';
import {useTranslator} from "common/Translation/Translator";
import {useDrag, useDrop} from 'react-dnd'
import {Draggable32, NoImage32, TextInput, TrashCan32} from "@sia/style-guide";
import './ManagableImage.scss';

export const ManagableImage = (props) => {
    const translate = useTranslator();
    const ref = useRef(null);
    const [{handlerId}, drop] = useDrop({
        accept: 'box',
        hover(item) {
            const dragIndex = item.index;
            const dropIndex = props.index;
            props.onDrop(dragIndex, dropIndex);
            item.index = dropIndex; // mutating, because it's how react-dnd works. see https://codesandbox.io/s/github/react-dnd/react-dnd/tree/gh-pages/examples_js/04-sortable/simple?from-embed=&file=/src/Card.js
        }
    })
    const [{isDragging}, drag, preview] = useDrag({
        type: 'box',
        item: () => {
            const id = props.image.id;
            const index = props.index;
            return {id, index};
        },
        collect: (monitor) => {
            return {isDragging: monitor.isDragging()};
        },
    })

    drag(drop(ref));

    const classes = [
        'managable-image',
        isDragging ? 'dragging' : null
    ];

    return <div className={classes.join(' ')} ref={preview} data-handler-id={handlerId}>
        <div className={'left-icon-actions'}>
            <div ref={ref} className={'draggable'}><Draggable32/></div>
            <div className={'placeholder'}>
                {props.image.data && <img src={props.image.data} alt={''}/>}
                {!props.image.data && <>
                    <NoImage32/>
                    <label>{translate('reference-gallery.project.image')}</label>
                </>}
            </div>
        </div>
        <div className={'right-area'}>
            <TextInput id={props.image.id.toString()}
                       label={translate('reference-gallery.project.image.copyright')}
                       value={props.image.copyright}
                       placeholder={translate('reference-gallery.project.image.copyright.placeholder')}
                       onChange={(event, value) => props.onChangeImageText(props.image.id, value)}
            />
            <TrashCan32 className={'remove'} onClick={() => props.onRemoveImage(props.image.id)}/>
        </div>
    </div>
}
ManagableImage.propTypes = {
    index: PropTypes.number.isRequired,
    onDrop: PropTypes.func.isRequired,
    image: PropTypes.object.isRequired,
    onChangeImageText: PropTypes.func.isRequired,
    onRemoveImage: PropTypes.func.isRequired,
};
export default ManagableImage;