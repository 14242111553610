import React from "react";
import IDPAdapter from "common/Adapters/IDPAdapter/IDPAdapter";
import { getHeaderContents } from "@sia/shared/js/contents/layout";
import { Header } from "@sia/style-guide";

const HeaderClient = ({
  pathFactory,
  history,
  setReferenceGalleryFilters,
  setReferenceGalleryPageArgs,
  referenceGalleryInitialFilters,
  referenceGalleryPageInitialsArgs,
  jobOffersInitialFilters,
  jobOffersPageInitialArgs,
  setJobOffersFilters,
  setJobOffersPageArgs,
  user,
  menuItems,
  i18n,
  openedHeaderDropdowns,
  setOpenedHeaderDropdowns,
  createLanguageKeyReplacedUrl,
  translate,
  headerUser,
  customLogos,
  defaultAppSwitcherValue,
  language,
}) => {
  const switcherItems = React.useMemo(() => {
    const headerContentsConfig = {
      links: {
        account: {
          target: pathFactory.create("account"),
          onClick: (e) => {
            e.preventDefault();
            history.push(pathFactory.create("account/customer-data"));
          },
        },
        referenceGallery: {
          target: pathFactory.create(`reference-gallery`),
          onClick: (e) => {
            e.preventDefault();
            setReferenceGalleryFilters(referenceGalleryInitialFilters);
            setReferenceGalleryPageArgs(referenceGalleryPageInitialsArgs);
            history.push(pathFactory.create("reference-gallery/search"));
          },
        },
        jobs: {
          target: pathFactory.create(`jobs`),
          onClick: (e) => {
            e.preventDefault();
            setJobOffersFilters(jobOffersInitialFilters);
            setJobOffersPageArgs(jobOffersPageInitialArgs);
            history.push(pathFactory.create("jobs/offers"));
          },
        },
        directory: {
          target: pathFactory.create(`directory`),
          onClick: (e) => {
            e.preventDefault();
            history.push(pathFactory.create("directory"));
          },
        },
        agenda: {
          target: pathFactory.create(`agenda`),
          onClick: (e) => {
            e.preventDefault();
            history.push(pathFactory.create("agenda"));
          },
        },
        catalog: {
          target: pathFactory.create("catalog"),
          onClick: (e) => {
            e.preventDefault();
            history.push(pathFactory.create("catalog"));
          },
        },
        cloud: {
          target: process.env.REACT_APP_CLOUD_IDP_LINK,
        },
      },
    };
    let items = { ...getHeaderContents(headerContentsConfig) };
    if (user?.isMemberTypeGroupOf("corporate")) {
      items = {
        ...items,
        appSwitcherLinks: items.appSwitcherLinks.filter((e) => e.name !== "cloud"),
      };
    }
    return items;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  const isUserLoggedIn = IDPAdapter.isLoggedIn();
  return (
    <Header
      menuItems={menuItems}
      sharedContents={switcherItems}
      selectedLanguageKey={i18n.language}
      logoLinking={() => history.push("/")}
      openedDropdowns={openedHeaderDropdowns}
      toggleDropdowns={(updatedOpenedDropdowns) => setOpenedHeaderDropdowns(updatedOpenedDropdowns)}
      onChangeLanguage={(languageKey) => {
        localStorage.setItem("sia.webPlatform.languageKey", languageKey);
        const languageKeyReplacedUrl = createLanguageKeyReplacedUrl(languageKey);
        window.location.replace(languageKeyReplacedUrl);
      }}
      onClickLogin={() => {
        window.location.href = IDPAdapter.createLoginUrl(i18n.language);
      }}
      onClickLogout={() => IDPAdapter.logout()}
      translate={translate}
      isUserLoggedIn={isUserLoggedIn}
      user={headerUser}
      customLogo={customLogos.desktop}
      customLogoMobile={customLogos.mobile}
      defaultAppSwitcherValue={defaultAppSwitcherValue}
      search={true}
      history={history}
      lang={language}
    />
  );
};

export default HeaderClient;
