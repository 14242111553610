import React, { useContext } from "react";
import GlobalContext from "contexts/GlobalContext";
import CMSContentWrapper from "./CMSContentWrapper";
import CMSSectionWrapper from "./CMSSectionWrapper";
import "./CMSContent.scss";
const CMSContent = ({ nodeId, isHash, isSection, isLoadingPages }) => {
  const { language: languageKey, menuItems, noMenuPages } = useContext(GlobalContext);
  return (
    <div className="cms-content">
      {languageKey && isSection ? (
        !isLoadingPages && <CMSSectionWrapper languageKey={languageKey} />
      ) : (
        <>
          {languageKey && menuItems?.length > 0 && noMenuPages?.length > 0 && (
            <CMSContentWrapper nodeId={nodeId} isHash={isHash} languageKey={languageKey} menuItems={menuItems} noMenuPages={noMenuPages} />
          )}
        </>
      )}
    </div>
  );
};

export default CMSContent;
