import React, { Suspense, useContext, useEffect, useState } from "react";
import { useHistory, useLocation, useParams, Link as RouterLink } from "react-router-dom";
import Page from "pages/Page";
import ReferenceGalleryContentNavigation from "../ReferenceGalleryContentNavigation";
import { usePathFactory } from "common/Path/PathFactoryHook";
import {
  AppBack,
  Content,
  InfoBlock,
  Link,
  PicturesSlider,
  ReferenceGalleryItemPreviews,
  renderReferenceGalleryItemPreviewTags,
} from "@sia/style-guide";
import { useTranslator } from "common/Translation/Translator";
import ItemPreviewFactory from "../ItemPreviewFactory";
import webPlatformAdapter from "common/Adapters/WebPlatformAdapter/WebPlatformAdapter";
import "./ProjectPage.scss";
import GlobalContext from "contexts/GlobalContext";
import projectImageLoader from "../ProjectImageLoader";
import downloader from "common/Downloader";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useNavigator } from "common/Navigator";
const ProjectPage = ({ setReferenceGalleryPageArgs }) => {
  const location = useLocation();
  const isPreviewPage = location.pathname.endsWith("preview");
  const { id } = useParams();
  const [projectId, setProjectId] = useState(id);
  const history = useHistory();
  const pathFactory = usePathFactory();
  const translate = useTranslator();
  const GlobalContextValue = useContext(GlobalContext);
  const user = GlobalContextValue.user;
  const language = GlobalContextValue.language;
  const [shouldFetch, setShouldFetch] = useState(true);
  const [images, setImages] = useState([]);
  const [sliderImages, setSliderImages] = useState([]);
  const [infos, setInfos] = useState({});
  const [links, setLinks] = useState({});
  const [texts, setTexts] = useState({
    corporate: "",
    title: "",
    description: "",
  });
  const [relatedProjects, setRelatedProjects] = useState([]);
  const [relatedProjectsImages, setRelatedProjectsImages] = useState([]);
  const [tagGroupData, setTagGroupData] = useState(null);
  const isManagement = history.location.pathname.includes("/manage/");
  const shouldWaitForUserInitialization = isManagement && !user;
  const translateInfoLabel = (key) => {
    const translations = {
      client: translate("reference-gallery.project.client"),
      location_map_url: translate("reference-gallery.project.location_map_url"),
      realisation_date: translate("reference-gallery.project.realisation_date"),
      street: translate("address.street"),
      zip: translate("address.zip"),
      city: translate("address.city"),
    };
    return translations[key] ? translations[key] : null;
  };
  const navigate = useNavigator();
  useEffect(() => {
    window.scrollTo(0, -50);
    if (!shouldWaitForUserInitialization && shouldFetch && language) {
      setShouldFetch(false);
      const filters = { project_id: Number(projectId), lang: webPlatformAdapter.getLocale(language) };
      // todo SW-381, avoid redundant endpoints
      const endpoint = isManagement ? "rpc/get_owner_reference_object" : "rpc/get_reference_object";
      webPlatformAdapter.request("get", endpoint, filters, (response) => {
        setImages(response.data.pictures);
        const imageIds = response.data.pictures.map((image) => image.id);
        projectImageLoader.loadImagesByIds(imageIds, setSliderImages);
        setTexts({
          corporate: response.data.base.corporate,
          title: response.data.base.title,
          description: response.data.base.description,
        });
        setInfos(response.data.info);
        setLinks(response.data.links);
        setTagGroupData({
          categories: response.data.categories.map((item) => item.name),
          location: response.data.info.city,
          cantons: response.data.states.map((state) => state.computed_name),
        });

        // TODO remove this line when the API is ready.
        const projectsWithTitle = response.data.related_projects.map((project) => ({ ...project, title: { name: project.title, lang: "" } }));
        setRelatedProjects(projectsWithTitle);
        if (response.data.related_projects) {
          const relatedProjectsImageIds = response.data.related_projects.map((relatedProject) => relatedProject.thumbnail_picture_id);
          projectImageLoader.loadImagesByIds(relatedProjectsImageIds, setRelatedProjectsImages);
        }
      });
    }
  }, [shouldFetch, projectId, isManagement, shouldWaitForUserInitialization, language, navigate]);
  useEffect(() => {
    window.scrollTo(0, -50);
    setImages([]);
    setSliderImages([]);
    setRelatedProjectsImages([]);
    setProjectId(id);
    setShouldFetch(true);
  }, [history.location.pathname, id]);

  const createRenderableProjects = () => {
    return relatedProjects.map((relatedProject) => {
      // debugger
      const item = ItemPreviewFactory.createFromResponseItem(relatedProject);
      const image = relatedProjectsImages.find((image) => image.id === item.responseItem.thumbnail_picture_id);
      const base64Identifier = ";base64,";
      const base64Offset = image.data.indexOf(base64Identifier) + base64Identifier.length;
      item.image = image.data.substring(base64Offset);

      if (isPreviewPage) {
        const [baseUrl] = location.pathname.split(/\/\d+/g);
        item.url = `${baseUrl}/${item.id}/preview`;
      } else {
        const [baseUrl] = location.pathname.split(/\/\d+$/g);
        item.url = `${baseUrl}/${item.id}`;
      }
      return item;
    });
  };

  const onClickBack = () => {
    if (!history.location.state) {
      if (isPreviewPage) {
        history.push(pathFactory.create("reference-gallery/manage"));
      } else {
        history.push(pathFactory.create("reference-gallery/search"));
      }
      return;
    }
    if (history.location.state.canGoBack) {
      history.goBack();
    }
  };

  const getInfoBlockContent = () => {
    return Object.keys(infos).filter((key) => infos[key]);
  };

  const renderInfoBlockContent = () => {
    const infoBlockContent = getInfoBlockContent();
    if (!Boolean(infoBlockContent.length)) {
      return;
    }
    return (
      <InfoBlock loose>
        {infoBlockContent.map((key) => {
          return (
            <React.Fragment key={key}>
              <span className={"label"}>{translateInfoLabel([key])}</span>
              <p>{infos[key]}</p>
            </React.Fragment>
          );
        })}
      </InfoBlock>
    );
  };

  const renderLinks = () => {
    return (
      <div className={"links"}>
        {Object.keys(links)
          .filter((key) => links[key])
          .map((key) => {
            const label = key === "dossier_pdf" ? `${translate("reference-gallery.project.dossier")}_${id}.pdf` : links[key];
            const target = key === "dossier_pdf" ? downloader.createBase64Href(links[key], "pdf") : links[key];
            const downloadName = key === "dossier_pdf" ? label : null;
            return (
              <p key={key}>
                <Link key={key} withIcon={true} target={target} downloadName={downloadName}>
                  {label}
                </Link>
              </p>
            );
          })}
      </div>
    );
  };

  const createEnrichedSliderImages = () => {
    if (!sliderImages.length) {
      return [];
    }
    return images.map((image) => {
      const sliderImage = sliderImages.find((sliderImage) => sliderImage.id === image.id);
      return { src: sliderImage.data, copyright: image.copyright };
    });
  };
  const enrichedSliderImages = createEnrichedSliderImages();

  function decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }
  return (
    <Page
      contentNavigation={<ReferenceGalleryContentNavigation setReferenceGalleryPageArgs={setReferenceGalleryPageArgs} />}
      title={"reference-gallery.name"}
      key={id}
    >
      <HelmetProvider>
        <Suspense fallback={null}>
          <Helmet>
            <meta name="description" content={`${texts.corporate} - ${decodeHtml(texts.description.substring(0, 200).replace("\n", " "))}`} />
            {tagGroupData && (
              <meta name="keywords" content={decodeHtml(`${tagGroupData.categories}, ${tagGroupData.location}, ${tagGroupData.cantons}`)} />
            )}
          </Helmet>
        </Suspense>
      </HelmetProvider>
      <AppBack onClick={onClickBack} />

      {sliderImages.length > 0 && <PicturesSlider translate={translate} images={enrichedSliderImages} />}

      <Content className={"project-page"} width={"full"}>
        <div className="bx--grid reset-spacings">
          <div className="bx--row">
            <div className="bx--col-lg-2"></div>
            <div className="bx--col-lg-12">
              <p className={"subtitle corporate"} onClick={() => navigate(`directory?name=${texts.corporate}&tab=0&sort=1`)}>
                {texts.corporate}
              </p>
              <h2>{texts.title}</h2>
              {tagGroupData && <>{renderReferenceGalleryItemPreviewTags(tagGroupData.categories, tagGroupData.location, tagGroupData.cantons)}</>}
            </div>
            <div className="bx--col-lg-2"></div>
          </div>
          <div className="bx--row">
            <div className="bx--col-lg-2 bx--col-md-0"></div>
            <div className="bx--col-lg-8 bx--col-md-5">
              <p
                className={"description"}
                // needs dangerouslySetInnerHTML because description has html formattings
                // backend makes sure that description not contains malicious script
                dangerouslySetInnerHTML={{ __html: texts.description }}
              ></p>
            </div>
            <div className="bx--col-lg-4 bx--col-md-3">
              {renderInfoBlockContent()}
              {renderLinks()}
            </div>
            <div className="bx--col-lg-2 bx--col-md-0"></div>
          </div>
          <div className="bx--row">
            <div className="bx--col-lg-2"></div>
            <div className="bx--col-lg-12">
              {Boolean(relatedProjects.length) && (
                <p className={"subtitle more-projects"}>
                  {translate("reference-gallery.project.more_projects_of_corporate", { corporate: texts.corporate })}
                </p>
              )}
              {Boolean(relatedProjectsImages.length) && (
                <ReferenceGalleryItemPreviews items={createRenderableProjects()} translate={translate} elementToRender={RouterLink} />
              )}
            </div>
            <div className="bx--col-lg-2"></div>
          </div>
        </div>
      </Content>
    </Page>
  );
};
export default ProjectPage;
