import httpClientAuthorizer from "adapters/IDPAdapter/HttpClientAuthorizer";
import keycloakIDPAdapter from "../IDPAdapter/KeycloakIDPAdapter";
import IDPAdapter from "../IDPAdapter/IDPAdapter";

const apiUrl = process.env.REACT_APP_WEB_PLATFORM_API_URL;

export default class HttpWebPlatformAdapter {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  // todo, use only request() in the future, otherwise all adapters end up to have all http methods as wrappers
  get(relativePath, data, onSuccess, onError) {
    const fixedRelativePath = !relativePath.includes("/") ? `rpc/${relativePath}` : relativePath; // todo pass api type before
    this.request("get", fixedRelativePath, data, onSuccess, onError);
  }
  getWithDelay(relativePath, data, onSuccess, onError, delay) {
    const fixedRelativePath = !relativePath.includes("/") ? `rpc/${relativePath}` : relativePath; // todo pass api type before
    this.requestWithDelay("get", fixedRelativePath, data, onSuccess, onError, delay);
  }

  request(method, relativePath, data, onSuccess, onError) {
    const url = `${apiUrl}/${relativePath}`;
    const requestHttp = () => {
      this.httpClient.request(method, url, data, onSuccess, onError, httpClientAuthorizer.authorizeRequestConfig({}));
    };
    if (keycloakIDPAdapter.isLoggedIn()) {
      keycloakIDPAdapter
        .getClient()
        .updateToken(0)
        .then((result) => {
          requestHttp();
        })
        .catch((error) => {
          IDPAdapter.logout(window.location.href);
          return;
        });
      return;
    }
    requestHttp();
  }

  requestWithDelay(method, relativePath, data, onSuccess, onError, delay) {
    const url = `${apiUrl}/${relativePath}`;
    const requestHttp = () => {
      this.httpClient.requestWithDelay(method, url, data, onSuccess, onError, httpClientAuthorizer.authorizeRequestConfig({}), false, delay);
    };
    if (keycloakIDPAdapter.isLoggedIn()) {
      keycloakIDPAdapter
        .getClient()
        .updateToken(0)
        .then((result) => {
          requestHttp();
        })
        .catch((error) => {
          IDPAdapter.logout(window.location.href);
          return;
        });
      return;
    }
    requestHttp();
  }

  requestUrl(method, url, data, onSuccess, onError) {
    const requestHttp = () => {
      this.httpClient.request(method, url, data, onSuccess, onError, httpClientAuthorizer.authorizeRequestConfig({}));
    };
    if (keycloakIDPAdapter.isLoggedIn()) {
      keycloakIDPAdapter
        .getClient()
        .updateToken(0)
        .then((result) => {
          requestHttp();
        })
        .catch((error) => {
          IDPAdapter.logout(window.location.href);
          return;
        });
      return;
    }
    requestHttp();
  }

  getLoginInformation(shortLocale, onSuccess) {
    const data = { lang: this.getLocale(shortLocale) };
    this.get("get_login_information", data, onSuccess);
  }

  getCustomerData(user, onSuccess) {
    if (user.isMemberTypeGroupOf("single")) {
      this.getCustomerDataOfMemberTypeSingle(user.getLocale(), onSuccess);
    }
    if (user.isMemberTypeGroupOf("corporate")) {
      this.getCustomerDataOfMemberTypeCorporate(user.getLocale(), onSuccess);
    }
  }

  getCustomerDataOfMemberTypeSingle(shortLocale, onSuccess) {
    const data = { lang: this.getLocale(shortLocale) };
    this.get("get_customer_data_single", data, onSuccess);
  }

  getCustomerDataOfMemberTypeCorporate(shortLocale, onSuccess) {
    const data = { lang: this.getLocale(shortLocale) };
    this.get("get_customer_data_corporate", data, onSuccess);
  }

  getAddressesForMemberTypeSingle(shortLocale, onSuccess) {
    const data = { lang: this.getLocale(shortLocale) };
    this.get("get_addresses_single", data, onSuccess);
  }

  getAddressesForMemberTypeCorporate(shortLocale, onSuccess) {
    const data = { lang: this.getLocale(shortLocale) };
    this.get("get_addresses_corporate", data, onSuccess);
  }

  getSubsidiaryAddressById(id, shortLocale, onSuccess) {
    const data = { sia_id: id, lang: this.getLocale(shortLocale) };
    this.get("get_addresses_subsidiaries", data, onSuccess);
  }

  getCountries(shortLocale, onSuccess) {
    const data = { lang: this.getLocale(shortLocale) };
    this.get("get_countries_list", data, onSuccess);
  }

  getStates(shortLocale, onSuccess) {
    const data = { lang: this.getLocale(shortLocale) };
    this.get("get_states_list", data, onSuccess);
  }

  getIndustrySectors(shortLocale, onSuccess) {
    const data = { lang: this.getLocale(shortLocale) };
    this.get("get_work_branches_list", data, onSuccess);
  }

  getAreasOfActivity(shortLocale, onSuccess) {
    const data = { lang: this.getLocale(shortLocale) };
    this.get("get_work_specialisation_list", data, onSuccess);
  }

  getInvoices(shortLocale, onSuccess) {
    const data = { lang: this.getLocale(shortLocale) };
    this.get("get_invoices", data, onSuccess);
  }

  getProfessionalNetworksSingle(shortLocale, onSuccess) {
    const data = { lang: this.getLocale(shortLocale) };
    this.get("get_professional_networks_single", data, onSuccess);
  }

  getFormationSingle(shortLocale, onSuccess) {
    const data = { lang: this.getLocale(shortLocale) };
    this.get("get_formation_single", data, onSuccess);
  }

  getWorkSingle(shortLocale, onSuccess) {
    const data = { lang: this.getLocale(shortLocale) };
    this.get("get_work_single", data, onSuccess);
  }

  getWorkBranchesSingle(workId, shortLocale, onSuccess) {
    const data = {
      lang: this.getLocale(shortLocale),
      professional_career_id: workId,
    };
    this.get("get_work_branches_single", data, onSuccess);
  }

  getWorkSpecializationsSingle(workId, shortLocale, onSuccess) {
    const data = {
      lang: this.getLocale(shortLocale),
      professional_career_id: workId,
    };
    this.get("get_work_specialisations_single", data, onSuccess);
  }

  getActiveReferenceObjects(shortLocale, onSuccess) {
    const data = { lang: this.getLocale(shortLocale) };
    this.get("get_active_reference_objects", data, onSuccess);
  }

  getOwnerReferenceObjects(shortLocale, onSuccess) {
    const data = { lang: this.getLocale(shortLocale) };
    this.get("get_owner_reference_objects", data, onSuccess);
  }

  getJobOffers(shortLocale, onSuccess) {
    const data = { lang: this.getLocale(shortLocale) };
    this.get("get_open_positions", data, onSuccess);
  }

  getJobTypes(shortLocale, onSuccess) {
    const data = { lang: this.getLocale(shortLocale) };
    this.get("get_job_type", data, onSuccess);
  }

  getJobRegions(shortLocale, onSuccess) {
    const data = { lang: this.getLocale(shortLocale) };
    this.get("get_job_region", data, onSuccess);
  }

  getJobWorkload(shortLocale, onSuccess) {
    const data = { lang: this.getLocale(shortLocale) };
    this.get("get_job_workload", data, onSuccess);
  }

  getOpenPosition(postionId, shortLocale, onSuccess, onError) {
    const data = {
      lang: this.getLocale(shortLocale),
      open_position_id: postionId,
    };
    this.get("get_open_position", data, onSuccess, onError);
  }

  getIsJobSubscribed(onSuccess) {
    const data = null;
    this.get("is_job_subscribed", data, onSuccess);
  }

  getOwnerOpenPositions(shortLocale, onSuccess) {
    const data = {
      lang: this.getLocale(shortLocale),
    };
    this.get("get_owner_open_positions", data, onSuccess);
  }
  getJobFilterLists(shortLocale, onSuccess) {
    const data = {
      lang: this.getLocale(shortLocale),
    };
    this.get("get_job_filter_lists", data, onSuccess);
  }
  getOwnerOpenPosition(id, shortLocale, onSuccess, onError) {
    const data = {
      id: id,
      lang: this.getLocale(shortLocale),
    };
    this.get("get_owner_open_position", data, onSuccess, onError);
  }
  getServiceSubscriptions(shortLocale, onSuccess) {
    const data = {
      lang: this.getLocale(shortLocale),
    };
    this.get("get_services_subscriptions", data, onSuccess);
  }
  getCorporateOptions(shortLocale, onSuccess) {
    const data = {
      lang: this.getLocale(shortLocale),
    };
    this.get("get_corporate_options", data, onSuccess, null);
  }
  getSingleOptions(shortLocale, onSuccess) {
    const data = {
      lang: this.getLocale(shortLocale),
    };
    this.get("get_single_options", data, onSuccess, null);
  }
  // getWizardSession(member_hash_id, onSuccess) {
  //   const data = { member_hash_id };
  //   this.get("member_subscription_get_session", data, onSuccess);
  // }
  getWizardSession(member_hash_id, onSuccess) {
    return new Promise((res) =>
      setTimeout(() => {
        res(JSON.parse(localStorage.getItem("session") === null ? "{}" : localStorage.getItem("session")));
      }, 500)
    ).then(onSuccess);
  }
  // setWizardSession(member_hash_id, session, onSuccess) {
  //   const data = { member_hash_id, session };
  //   this.request("post", "member_subscription_set_session", data, onSuccess);
  // }
  setWizardSession(member_hash_id, session, onSuccess) {
    return new Promise((res) =>
      setTimeout(() => {
        res(localStorage.setItem("session", JSON.stringify(session)));
      }, 500)
    ).then(onSuccess);
  }

  getLocale(shortLocale) {
    // todo, use short/long locales more consistently. user locale switches from short to long.
    const locales = {
      de: "de_DE",
      en: "en_US",
      fr: "fr_CH",
      it: "it_IT",
    };
    return locales[shortLocale];
  }
}
