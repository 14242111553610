export default class IDPUser {

    constructor(username, locale, role) {
        this.username = username;
        this.locale = locale;
        this.role = role
    }

    getUsername() {
        return this.username;
    }

    getLocale() {
        return this.locale;
    }

    getRole(){
        return this.role
    }
}