function checkItem(alias, item, languageKey) {
  let nodeIDAux, redirectAux;
  if (item?.children) {
    for (let i = 0; i < item.children.length; i++) {
      const subItem = item.children[i];
      const { node_id, redirect } = checkItem(alias, subItem, languageKey);
      if (node_id) nodeIDAux = node_id;
      if (redirect) redirectAux = redirect;
    }
  } else {
    if (item.alias === `/${alias}`) nodeIDAux = item.id;
    else if ([item.alias_de, item.alias_fr, item.alias_it, item.alias_en].includes(`/${alias}`)) {
      redirectAux = item.alias;
    } else if (alias === `node/${item.id}`) {
      redirectAux = item.alias;
    }
  }
  return { node_id: nodeIDAux, redirect: redirectAux };
}

export function getNode({ alias, history, languageKey, menuItems, noMenuPages }) {
  let node_id;
  let redirect;
  const items = [...menuItems, ...noMenuPages];
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    const response = checkItem(alias, item, languageKey);
    if (response.node_id) {
      node_id = response.node_id;
      break;
    }
    if (response.redirect) {
      redirect = response.redirect;
      break;
    }
  }
  if (node_id) return node_id;
  if (redirect) {
    const hash = window.location.hash ? window.location.hash : "";
    const search = window.location.search ? window.location.search : "";
    history.push(`/${languageKey}/cms${redirect}${search}${hash}`);
    return -1;
  }
  const node = alias?.replace("node/", "");
  return node;
}
