import React, {useCallback, useEffect, useState, useLayoutEffect, useContext} from 'react';
import {useHistory, useParams} from "react-router-dom";
import {usePathFactory} from "common/Path/PathFactoryHook";
import {
    AppTitleBack,
    changer,
    cloner,
    Content,
    Dropdown,
    fileLoader,
    FileUploader,
    FileUploaderFile,
    Form,
    InfoBlock, merger,
    MultiselectDropdown, safeStateChanger,
    TextArea,
    TextInput,
    Tile
} from "@sia/style-guide";
import Page from "pages/Page";
import ReferenceGalleryContentNavigation from "../ReferenceGalleryContentNavigation";
import {useTranslator} from "common/Translation/Translator";
import './ManageProjectPage.scss';
import DragAndDrop from "common/DragAndDrop/DragAndDrop";
import ManagableImages from "./ManagableImages/ManagableImages";
import webPlatformAdapter from "common/Adapters/WebPlatformAdapter/WebPlatformAdapter";
import projectImageLoader from "../ProjectImageLoader";
import downloader from "common/Downloader";
import {v4 as uuid} from 'uuid';
import {useNavigator} from "common/Navigator";
import {WebsiteInput} from "components/WebsiteInput";
import GlobalContext from 'contexts/GlobalContext';
const ManageProjectPage = ({setReferenceGalleryPageArgs}) => {
    const {id} = useParams();
    const history = useHistory();
    const pathFactory = usePathFactory();
    const translate = useTranslator();
    const navigate = useNavigator();
    const pageTitle = id ?
        'reference-gallery.manage.change' :
        'reference-gallery.manage.add';
    const maximumImagesCount = 3;
    const {language} = useContext(GlobalContext)
    const minimumImageDimensionInPixels = 1300;
    const createInitialImage = () => ({id: uuid(), data: null, copyright: ''});
    const createInitialImages = useCallback(() => {
        const images = [];
        for (let loops = 1; loops <= maximumImagesCount; loops++) {
            images.push(createInitialImage());
        }
        return images;
    }, [])
    const lang = webPlatformAdapter.getLocale();
    const [title, setTitle] = useState('');
    const [cantons, setCantons] = useState([]);
    const [street, setStreet] = useState('');
    const [zip, setZip] = useState('');
    const [location, setLocation] = useState('');
    const [client, setClient] = useState('');
    const [dossierFiles, setDossierFiles] = useState([]);
    const [realisation, setRealisation] = useState('');
    const [website, setWebsite] = useState('');
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState(null);
    const [images, setImages] = useState(createInitialImages());
    const [description, setDescription] = useState('');
    const [wasCantonsFetchStarted, setWasCantonsFetchStarted] = useState(false);
    const [wasCategoriesFetchStarted, setWasCategoriesFetchStarted] = useState(false);
    const [shouldFetchProject, setShouldFetchProject] = useState(Boolean(id));
    const [isCantonsOpen, setIsCantonsOpen] = useState(false)
    const [formErrors, setFormErrors] = useState({
        name: null,
        canton: null,
        dossier: null,
        category: null,
        images: null,
    });
    const dndContainerId = "ManageProjectPage-dndContainerId";
    const populateImagesFromResponse = useCallback((responseImages) => {
        if (!responseImages.length) {
            return;
        }
        const responseIds = responseImages.map(item => item.id).slice(0, maximumImagesCount);
        projectImageLoader.loadImagesByIds(responseIds, (loadedImages) => {
            const newImages = createInitialImages();
            for (let index = 0; index < responseIds.length; index++) {
                const id = responseIds[index];
                const responseImage = responseImages[index];
                newImages[index].id = id;
                newImages[index].data = loadedImages.find(item => item.id === id).data;
                newImages[index].copyright = responseImage.copyright || '';
            }
            setImages(newImages)
        })
    }, [createInitialImages])

    useEffect(() => {
        if (!wasCantonsFetchStarted) {
            setWasCantonsFetchStarted(true)
            webPlatformAdapter.request('GET', 'rpc/get_reference_states', {lang: webPlatformAdapter.getLocale(language)}, response => {
                const newCantons = response.data.map(canton => ({id: canton.id, label: canton.name}));
                setCantons(newCantons);
            })
        }
        if (!wasCategoriesFetchStarted) {
            setWasCategoriesFetchStarted(true)
            webPlatformAdapter.request('GET', 'rpc/get_reference_categories', {lang: webPlatformAdapter.getLocale(language)}, response => {
                const newCategories = response.data.map(category => ({id: category.id, label: category.name}));
                setCategories(newCategories);
            })
        }
        if (shouldFetchProject && cantons.length && categories.length && language) {
            setShouldFetchProject(false)
            webPlatformAdapter.request('GET', 'rpc/get_owner_reference_object', {
                project_id: Number(id),
                lang: webPlatformAdapter.getLocale(language)
            }, response => {
                const assignedCantonIds = response.data.states.map(item => item.id);
                const newCantons = cantons.map(item => changer.changeImmutable(item, 'active', assignedCantonIds.includes(item.id)))
                // todo, category can only be one (business rule). but api currently returns array. SW-455
                const responseCategory = response.data.categories[0];
                setTitle(response.data.base.title)
                setCantons(newCantons)
                setCategory({id: responseCategory.id, label: responseCategory.name})
                setStreet(response.data.info.street)
                setZip(response.data.info.zip)
                setLocation(response.data.info.city)
                setClient(response.data.info.client)
                setRealisation(response.data.info.realisation_date)
                if (response.data.links.dossier_pdf) {
                    const base64DossierPdf = response.data.links.dossier_pdf;
                    const fileName = `${translate('reference-gallery.project.dossier')}_${id}.pdf`;
                    const file = new File([], fileName);
                    const fileUploaderFile = new FileUploaderFile(uuid(), file, base64DossierPdf);
                    setDossierFiles([fileUploaderFile])
                }
                setWebsite(response.data.links.website)
                populateImagesFromResponse(response.data.pictures)
                setDescription(response.data.base.description)
            }
            )
        }
    }, [wasCantonsFetchStarted, wasCategoriesFetchStarted, shouldFetchProject, cantons, categories, id, lang,
        populateImagesFromResponse, translate, navigate, language])

    const stretchTextareaToAlignColumns = () => {
        const textarea = document.querySelector('.fields-column textarea')
        textarea.style.height = null;
        const leftColumn = document.querySelector('.fields-column.left .tile')
        const uploader = document.querySelector('.fields-column .info-block')
        const managableImages = document.querySelector(`#${dndContainerId}`)
        const remainingClientHeight = leftColumn.clientHeight - uploader.clientHeight - managableImages.clientHeight;
        const remainingHeight = remainingClientHeight - 61.5;
        textarea.style.height = `${remainingHeight}px`;
    }

    useLayoutEffect(() => {
        window.addEventListener('resize', stretchTextareaToAlignColumns);
        stretchTextareaToAlignColumns();
        return () => window.removeEventListener('resize', stretchTextareaToAlignColumns);
    })

    const onChangeDossier = (files, error) => {
        if (!files.length) {
            return;
        }
        const originalDossierFile = files[0].getOriginalFile();
        const base64Promise = fileLoader.loadBase64(originalDossierFile);
        base64Promise.then(base64 => {
            const dossierFile = new FileUploaderFile(uuid(), originalDossierFile, base64);
            setDossierFiles([dossierFile]);
            setFormErrors(changer.changeImmutable(formErrors, 'dossier', error));
        })
    }
    const hasMinimumOneImage = (images) => {
        return Boolean(images.filter(image => image.data).length);
    }
    const onSubmitErrors = (errors) => {
        setFormErrors(merger.mergeObjectsImmutable(formErrors, errors))
    }
    const onSubmit = () => {
        const isFormValid = hasMinimumOneImage(images);
        if (!isFormValid) {
            refreshInvalidImagesText(images);
            return;
        }
        const data = {
            title: title,
            street: street,
            zip: zip,
            city: location,
            description: description,
            dossier_pdf: dossierFiles[0] ? dossierFiles[0].getBase64() : '',
            website: website,
            realisation_date: realisation,
            client: client,
            state_ids: cantons.filter(item => item.active).map(item => item.id),
            category_id: category.id,
            images: images.filter(item => item.data).map(item => ({
                copyright: item.copyright,
                base64: item.data.substring(item.data.indexOf(',') + 1),
            })),
        };
        if (id) {
            data.id = id;
        }
        const endpoint = id ?
            'rpc/update_reference_object' :
            'rpc/create_reference_object';
        webPlatformAdapter.request('POST', endpoint, data, response => {
            navigate('reference-gallery/manage');
        })
    }

    const getEmptySlotIndexes = () => {
        const emptySlotIndexes = [];
        images.forEach((item, index) => {
            if (!item.data) {
                emptySlotIndexes.push(index);
            }
        })
        return emptySlotIndexes;
    }

    const validateImageDimensions = (files, onSuccess) => {
        let onLoadCount = 0;
        let invalidImagesText = '';
        const onLoadImage = (img) => {
            onLoadCount++;
            if (img.width < minimumImageDimensionInPixels &&
                img.height < minimumImageDimensionInPixels) {
                invalidImagesText = 'reference-gallery.project.images.upload.error.size.too-small';
            }
            const areAllImagesLoaded = (onLoadCount === files.length);
            if (!areAllImagesLoaded) {
                return;
            }
            if (invalidImagesText) {
                setFormErrors(changer.changeImmutable(formErrors, 'images', invalidImagesText));
            }
            if (!invalidImagesText) {
                onSuccess();
            }
        }
        for (let i = 0; i < files.length; i++) {
            const fileReader = new FileReader();
            const file = files[i];
            fileReader.onload = () => {
                const img = new Image();
                img.onload = () => onLoadImage(img);
                img.src = fileReader.result;
            };
            fileReader.readAsDataURL(file.getOriginalFile());
        }
    }

    const onChooseImages = (files, error) => {
        setFormErrors(changer.changeImmutable(formErrors, 'images', error));
        if (error) {
            return;
        }
        const onValidDimensions = () => {
            const newImages = cloner.clone(images);
            const emptySlotIndexes = getEmptySlotIndexes();
            const limitedFiles = files.slice(0, emptySlotIndexes.length);
            let finishedPromisesCount = 0;
            emptySlotIndexes.forEach((slotIndex, index) => {
                const file = limitedFiles[index];
                if (!file) {
                    return;
                }
                const base64Promise = fileLoader.loadBase64(file.getOriginalFile());
                base64Promise.then(base64 => {
                    finishedPromisesCount++;
                    const areAllPromisesDone = (finishedPromisesCount === limitedFiles.length);
                    newImages[slotIndex].data = downloader.createBase64Href(base64);
                    if (areAllPromisesDone) {
                        setImages(newImages);
                        refreshInvalidImagesText(newImages);
                    }
                })
            })
        }
        validateImageDimensions(files, onValidDimensions)
    }

    const onChangeImageText = (id, value) => {
        const newImages = cloner.clone(images);
        const index = newImages.findIndex(item => item.id === id);
        newImages[index].copyright = value;
        setImages(newImages);
    }

    const refreshInvalidImagesText = (images) => {
        const message = !hasMinimumOneImage(images) ? 'reference-gallery.manage.error.required-image' : null;
        setFormErrors(changer.changeImmutable(formErrors, 'images', message));
    }

    const onRemoveImage = (id) => {
        const removingIndex = images.findIndex(item => item.id === id);
        const newImages = [];
        images.forEach((image, index) => {
            if (removingIndex === index) {
                return;
            }
            newImages.push(image);
        })
        newImages.push(createInitialImage());
        setImages(newImages);
        refreshInvalidImagesText(newImages);
    }

    return <Page contentNavigation={<ReferenceGalleryContentNavigation setReferenceGalleryPageArgs={setReferenceGalleryPageArgs}/>} title={'reference-gallery.name'}>
        <AppTitleBack title={translate(pageTitle)}
                      onClickBack={() => history.push(pathFactory.create('reference-gallery/manage'))}
        />
        <Content className={'manage-project'}>
            <Form primaryButtonLabel={translate('form.save')} onClickPrimaryButton={onSubmit} onErrors={onSubmitErrors}>
                <div className="bx--grid">
                    <div className="bx--row">
                        <div className="fields-column bx--col-lg-8 bx--col-md-4 bx--col-sm-4 left">
                            <Tile>
                                <TextInput id={'name'} label={translate('reference-gallery.form.name')}
                                           required
                                           value={title}
                                           onChange={(event, value) => {
                                               setTitle(value)
                                               setFormErrors(changer.changeImmutable(formErrors, 'name', null))
                                           }}
                                           invalidText={translate(formErrors.name)}
                                />
                                <MultiselectDropdown id={'canton'}
                                                     label={translate('reference-gallery.form.canton')}
                                                     required
                                                     isOpen={isCantonsOpen}
                                                     toggle={() => setIsCantonsOpen(!isCantonsOpen)}
                                                     items={cantons}
                                                     onClickItem={(event, item, updatedItems) => {
                                                         setCantons(updatedItems)
                                                         setFormErrors(changer.changeImmutable(formErrors, 'canton', null))
                                                     }}
                                                     invalidText={translate(formErrors.canton)}
                                />
                                <TextInput id={'street'} label={translate('reference-gallery.project.street')}
                                           value={street} onChange={(event, value) => setStreet(value)}
                                />
                                <TextInput id={'zip'} label={translate('reference-gallery.project.zip')}
                                           value={zip} onChange={(event, value) => setZip(value)}
                                />
                                <TextInput id={'location'} required
                                           label={translate('reference-gallery.project.location')}
                                           value={location}
                                           onChange={(event, value) => {
                                               setLocation(value)
                                               setFormErrors(safeStateChanger.changeValue(formErrors, 'location', null))
                                           }}
                                           invalidText={translate(formErrors.location)}
                                />
                                <TextInput id={'client'} label={translate('reference-gallery.project.client')}
                                           value={client} onChange={(event, value) => setClient(value)}
                                />
                                <TextInput id={'realisation'}
                                           label={translate('reference-gallery.project.realisation_date')}
                                           value={realisation} onChange={(event, value) => setRealisation(value)}
                                />
                                <FileUploader label={translate('reference-gallery.project.description.upload')}
                                              sizeLimit={500}
                                              sizeLimitUnit={'KB'}
                                              files={dossierFiles}
                                              fileTypes={['pdf']}
                                              buttonLabel={translate('file-uploader.upload-button.label')}
                                              translate={translate}
                                              onChange={(event, files, error) => onChangeDossier(files, error)}
                                              onRemove={(event, file, updatedFiles, error) => {
                                                  setDossierFiles(updatedFiles)
                                                  setFormErrors(changer.changeImmutable(formErrors, 'dossier', error));
                                              }}
                                              invalidText={translate(formErrors.dossier)}
                                />
                                <WebsiteInput id={'website'} label={translate('reference-gallery.project.website')}
                                              value={website}
                                              onChange={(event, value, error) => {
                                                  setWebsite(value)
                                                  setFormErrors(safeStateChanger.changeValue(formErrors, 'website', error))
                                              }}
                                              invalidText={translate(formErrors.website)}
                                />
                                <Dropdown id={'category'} label={translate('reference-gallery.form.category')} required
                                          items={categories}
                                          onChange={(event, item) => {
                                              setCategory(item)
                                              setFormErrors(changer.changeImmutable(formErrors, 'category', null))
                                          }}
                                          value={category}
                                          invalidText={translate(formErrors.category)}
                                />
                            </Tile>
                        </div>
                        <div className="fields-column bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                            <InfoBlock>
                                <span className={'label'}>{translate('reference-gallery.project.images.upload')}</span>
                                <FileUploader sizeLimit={5}
                                              sizeLimitUnit={'MB'}
                                              fileTypes={['jpg', 'jpeg', 'png']}
                                              translate={translate}
                                              onChange={(event, files, error) => onChooseImages(files, error)}
                                              draggable
                                              multiple
                                              invalidText={translate(formErrors.images)}
                                              additionalDescription={translate(
                                                  'reference-gallery.project.images.upload.additional-description',
                                                  {pixels: minimumImageDimensionInPixels}
                                              )}
                                              disabled={Boolean(images.filter(image => image.data).length === maximumImagesCount)}
                                />
                            </InfoBlock>
                            <div id={dndContainerId}>
                                <DragAndDrop id={dndContainerId}>
                                    <ManagableImages images={images}
                                                     onSortImage={(newSortedImages) => setImages(newSortedImages)}
                                                     onChangeImageText={(id, value) => onChangeImageText(id, value)}
                                                     onRemoveImage={(id) => onRemoveImage(id)}
                                                     />
                                </DragAndDrop>
                            </div>

                            <TextArea id={'description'} required
                                      label={translate('reference-gallery.project.description')}
                                      value={description}
                                      onChange={(event, value) => {
                                          setDescription(value)
                                          setFormErrors(safeStateChanger.changeValue(formErrors, 'description', null))
                                      }}
                                      invalidText={translate(formErrors.description)}
                            />
                        </div>
                    </div>
                </div>
            </Form>
        </Content>
    </Page>;
}
export default ManageProjectPage;
