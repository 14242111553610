import React, { useContext } from "react";
import { ContentNavigation } from "@sia/style-guide/dist/components";
import { useTranslator } from "common/Translation/Translator";
import { useHistory } from "react-router-dom";
import { usePathFactory } from "common/Path/PathFactoryHook";
import GlobalContext from "contexts/GlobalContext";

export const ReferenceGalleryContentNavigation = (props) => {
  const translate = useTranslator();
  const history = useHistory();
  const pathFactory = usePathFactory();
  const GlobalContextValue = useContext(GlobalContext);
  const user = GlobalContextValue.user;
  const shouldRender = () => {
    if (!user) {
      return false;
    }
    return ["corporate", "subsidiaryCompany"].includes(
      user.getMemberType().getName()
    );
  };
  if (!shouldRender()) {
    return null;
  }
  const routerItems = [
    {
      label: translate("reference-gallery.search"),
      path: pathFactory.create("reference-gallery/search"),
    },
    {
      label: translate("reference-gallery.manage"),
      path: pathFactory.create("reference-gallery/manage"),
    },
  ];
  const contentNavigationItems = routerItems.map((routerItem) => {
    return {
      label: routerItem.label,
      onClick: () => {
        props.setReferenceGalleryPageArgs((state) => ({
          ...state,
          chosenSort: {
            id: "newest",
            label: translate("reference-gallery.sort.newest"),
          },
        }));
        history.push(routerItem.path);
      },
    };
  });
  const selectedItemIndex = routerItems.findIndex((routerItem) =>
    history.location.pathname.includes(routerItem.path)
  );
  return (
    <ContentNavigation
      items={contentNavigationItems}
      preselectedItemIndex={selectedItemIndex}
    />
  );
};

export default ReferenceGalleryContentNavigation;
