import { useEffect } from "react";
import { useTranslator } from "common/Translation/Translator";
import { CookiesBanner } from "@sia/style-guide";
import ReactGA from "react-ga4";
import { useCookies } from "react-cookie";

const CookieClient = ({ history, lang }) => {
  const translate = useTranslator();
  const [cookies, setCookie, removeCookie] = useCookies(["cookie_consent"]);
  useEffect(() => {
    if (cookies.cookie_consent) {
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID);
      ReactGA.send("pageview");
    } else {
      const cookiesArray = Object.entries(cookies);
      for (let index = 0; index < cookiesArray.length; index++) {
        const element = cookiesArray[index];
        if (element[0].includes("_ga")) {
          removeCookie(element[0], { path: "/", domain: ".sia.ch" });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies.cookie_consent]);

  if (cookies.cookie_consent !== undefined) return <></>;
  return (
    <CookiesBanner
      translate={translate}
      history={history}
      onReject={() => {
        setCookie("cookie_consent", false, { path: "/", maxAge: 60 * 60 * 24 * 365 * 100 });
      }}
      onAccept={() => {
        setCookie("cookie_consent", true, { path: "/", maxAge: 60 * 60 * 24 * 365 * 100 });
      }}
      lang={lang}
    />
  );
};
export default CookieClient;
