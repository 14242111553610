import React from "react";
import Tag from "@sia/style-guide/dist/components/Tag/Tag";
import { useTranslator } from "common/Translation/Translator";
import FragmentTitle from "./FragmentTitle";
const InfoListWithExtras = ({ listItems, listTitle, extras, type, onTagClick }) => {
  const filteredItems = listItems.filter((e) => e.name);
  const isThereAnyFilteredItems = filteredItems.length > 0;
  const translate = useTranslator();
  const renedrItemsList = (label = "", items) =>
    items.map((item, idx) => (
      <React.Fragment key={item}>
        <span className="sia-text-08" key={idx}>
          {label ? `${label}, ` : ""} {item}
        </span>
        <br />
      </React.Fragment>
    ));

  return (
    isThereAnyFilteredItems && (
      <div className="Branch">
        <FragmentTitle title={listTitle} />
        {listItems.map((item, idx) => {
          return (
            item.name && (
              <React.Fragment key={idx}>
                <Tag label={item.name} type={type} key={idx} onClick={() => onTagClick && onTagClick({ id: item.id })} />
                <br />
              </React.Fragment>
            )
          );
        })}
        {Object.entries(extras).map(([key, value]) => {
          switch (key) {
            case "council":
              return value.length > 0 && renedrItemsList(translate("directory.member.info.professional_group.label.council"), value);
            case "delegate":
              return value.length > 0 && renedrItemsList("", value);
            case "committee":
              return value.length > 0 && renedrItemsList(translate("directory.member.info.professional_group.label.committee"), value);
            case "commission":
              return value.length > 0 && renedrItemsList(translate("directory.member.info.professional_group.label.commission"), value);
            case "director":
              return value.length > 0 && renedrItemsList(translate("directory.member.info.professional_group.label.director"), value);
            default:
              return null;
          }
        })}
      </div>
    )
  );
};
export default InfoListWithExtras;
