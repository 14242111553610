import FragmentTitle from "./FragmentTitle";
const ExtraInfo = ({ infos, title }) => {
  return (
    infos !== "None" && (
      <div className="ExtraInfo">
        <FragmentTitle title={title} />
        <div className="ExtracInfo-List">
          {infos.split("\n").map((text, idx) => (
            <p key={idx}>{text}</p>
          ))}
        </div>
      </div>
    )
  );
};

export default ExtraInfo;
