import {useCallback} from 'react'
import PropTypes from "prop-types";
import ManagableImage from './ManagableImage.js'
import sorter from 'common/Sorter.js';
import './ManagableImages.scss';

const ManagableImages = (props) => {

    const onDrop = useCallback((dragIndex, dropIndex) => {
        const newImages = sorter.sortItem(props.images, dragIndex, dropIndex);
        props.onSortImage(newImages);
    }, [props]);

    const renderImages = () => props.images.map((image, index) => <ManagableImage key={image.id ? image.id : index}
                                                                                  index={index}
                                                                                  image={image}
                                                                                  onDrop={onDrop}
                                                                                  onChangeImageText={props.onChangeImageText}
                                                                                  onRemoveImage={props.onRemoveImage}
    />);
    return <div className={'managable-images'}>
        {renderImages()}
    </div>
}

ManagableImages.propTypes = {
    images: PropTypes.array.isRequired,
    onSortImage: PropTypes.func.isRequired,
    onChangeImageText: PropTypes.func.isRequired,
    onRemoveImage: PropTypes.func.isRequired,
};
export default ManagableImages;