import React, { useContext } from 'react';
import { Content } from '@sia/style-guide';
import { useTranslator } from "common/Translation/Translator";
import Page from "../Page";
import './ErrorPage.scss'
import { HttpErrorContext } from 'contexts/HttpErrorContext';

const ErrorPage = () => {
    const { status, statusText } = useContext(HttpErrorContext)
    const translate = useTranslator();
    return <Page className='ErrorPage'>
        <Content activePageTitle={true}>
            {status ? (
                <>
                    <h1 className='ErrorPage-Title'>{translate(`error.${status}.title`, { defaultValue: translate('error.generic.title') })}</h1>
                    <p>[{status}]{statusText && ` - ${statusText}`}</p>
                </>
            ) :
                <h1 className='ErrorPage-Title'>{translate('error.generic.title')}</h1>
            }
        </Content>
    </Page>;
};

export default ErrorPage;