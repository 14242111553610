import webPlatformAdapter from "common/Adapters/WebPlatformAdapter/WebPlatformAdapter";

class ProjectImageLoader {

    // todo, temporary. until url can directly be placed as img src
    loadImagesByIds(ids, callback) {
        const images = [];
        let fetchesDoneCount = 0;
        ids.forEach(id => {
            webPlatformAdapter.request('get', `refimg/${id}/99999/99999`, null, response => {
                images.push({id: id, data: response.data});
                fetchesDoneCount++;
                const wereAllImagesFetched = (fetchesDoneCount === ids.length);
                if (wereAllImagesFetched) {
                    callback(images);
                }
            });
        })
    };

}

const projectImageLoader = new ProjectImageLoader();
export default projectImageLoader;