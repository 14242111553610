import React, { useContext, useEffect, useState } from 'react';
import Page from "pages/Page";
import ReferenceGalleryContentNavigation from "../ReferenceGalleryContentNavigation";
import { useTranslator } from "common/Translation/Translator";
import GlobalContext from "contexts/GlobalContext";
import './ManageProjectsPage.scss';
import webPlatformAdapter from "common/Adapters/WebPlatformAdapter/WebPlatformAdapter";
import itemPreviewFactory from "../ItemPreviewFactory";
import { AddAlt32, cloner, Content, ReferenceGalleryItemPreviews, Alert } from "@sia/style-guide";
import { usePathFactory } from "common/Path/PathFactoryHook";
import { useHistory, useLocation, Link as RouterLink } from "react-router-dom";
import { useNavigator } from "common/Navigator";

const ManageProjectsPage = ({ setReferenceGalleryPageArgs }) => {
    const location = useLocation()
    const baseUrl = location.pathname;
    const translate = useTranslator();
    const navigate = useNavigator();
    const GlobalContextValue = useContext(GlobalContext);
    const user = GlobalContextValue.user;
    const language = GlobalContextValue.language;
    const [items, setItems] = useState([]);
    const [shouldFetchItems, setShouldFetchItems] = useState(true);
    const pathFactory = usePathFactory();
    const history = useHistory();
    const [showAlert, setShowAlert] = useState(false)

    useEffect(() => {
        if (user && shouldFetchItems) {
            setShouldFetchItems(false);
            webPlatformAdapter.getOwnerReferenceObjects(language, (response) => {
                const items = response.data.map(responseItem => {
                    // TODO remove this line when the API is ready.
                    const responseItemWithTitle = { ...responseItem, title: { name: responseItem.title, lang: "" } }
                    return itemPreviewFactory.createFromResponseItem(responseItemWithTitle)
                }
                )
                setItems(items)
            })
        }
    }, [user, shouldFetchItems, language])

    const generateUnpublishCallback = (item) => {
        if (item.status === "unpublished" && item.thumbnail_picture === null) {
            return () => setShowAlert(true)
        }
        return null;
    }

    const createActions = (responseItem) => {
        const data = { project_id: responseItem.id };
        const refetch = () => setShouldFetchItems(true);
        const actions = {
            change: () => history.push(pathFactory.create(`reference-gallery/manage/project/${responseItem.id}`)),
            hide: (responseItem.status === 'published') ? () => webPlatformAdapter.request('post', 'rpc/unpublish_reference_object', data, refetch) : null,
            show: (responseItem.status === 'unpublished' && responseItem.thumbnail_picture !== null) ? () => webPlatformAdapter.request('post', 'rpc/publish_reference_object', data, refetch) : generateUnpublishCallback(responseItem),
            remove: () => webPlatformAdapter.request('post', 'rpc/delete_reference_object', data, refetch),
        };
        return actions;
    }

    const enrichToRenderableItems = () => {
        return items.map(item => {
            const actions = createActions(item.responseItem)

            const navigateToProjectPage = () => history.push(
                pathFactory.create(`reference-gallery/manage/project/${item.id}/preview`),
                { canGoBack: true }
            );

            const onClickUnpublished = (item) => {
                if (!item.published && item.image === null) {
                    return null
                }
                return navigateToProjectPage
            }
            const newItem = cloner.clone(item)
            item.url = `${baseUrl}/project/${item.id}/preview`
            newItem.actions = actions
            newItem.onClick = navigateToProjectPage
            newItem.onClickUnpublished = onClickUnpublished(item)
            return newItem
        })
    }

    const renderContent = () => {
        if (!items.length) {
            return translate('reference-gallery.manage.no-items-found');
        }
        return <ReferenceGalleryItemPreviews items={enrichToRenderableItems()} translate={translate} elementToRender={RouterLink} />
    }
    return <Page contentNavigation={<ReferenceGalleryContentNavigation setReferenceGalleryPageArgs={setReferenceGalleryPageArgs} />} title={'reference-gallery.name'}>
        <Content className={'reference-gallery-manage'}>
            <Alert open={showAlert} onRequestClose={() => setShowAlert(false)} alertMessage={translate("reference-gallery.project.preview.alert.no-image.message")} alertHeading={translate("reference-gallery.project.preview.alert.no-image.heading")} />
            <p>{translate('reference-gallery.manage.description')}</p>
            {renderContent()}
            <div className={'toolbar'}>
                <AddAlt32 onClick={() => navigate('reference-gallery/manage/project')} />
            </div>
        </Content>
    </Page>;
}

export default ManageProjectsPage;
