class ItemPreviewFactory {
    createFromResponseItem(responseItem) {
        const categoryNames = responseItem.categories.map(category => category.name);
        const cantonCodes = responseItem.states ? responseItem.states.map(canton => canton.computed_name) : [];
        return {
            id: responseItem.id,
            company: responseItem.corporate,
            image: responseItem.thumbnail_picture,
            categories: categoryNames,
            location: responseItem.city,
            cantons: cantonCodes,
            published: (responseItem.status === 'published'),
            title:{...responseItem.title},
            responseItem: responseItem // for filtering
        };
    }
}

const itemPreviewFactory = new ItemPreviewFactory();
export default itemPreviewFactory;