const useFormMethods = (cmsAdapter, cachedValues, setCachedValues) => {
  const fetchForm = (lang, form_id, onSuccess) => {
    const cachedValue = cachedValues[`inForm-${form_id}`];
    if (cachedValue) {
      onSuccess(cachedValue);
    } else {
      cmsAdapter.fetchForm(lang, form_id, (data) => {
        onSuccess(data);
        setCachedValues({ ...cachedValues, [`inForm-${form_id}`]: data });
      });
    }
  };
  const onSubmitForm = (lang, form_id, values, onSuccess, onError) => {
    cmsAdapter.submitForm(
      lang,
      form_id,
      values,
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  };

  const fetchTerms = (lang, term_id, onSuccess) => {
    cmsAdapter.fetchTerms(lang, term_id, (data) => {
      onSuccess(data);
    });
  };

  return { fetchForm, fetchTerms, onSubmitForm };
};

export default useFormMethods;
