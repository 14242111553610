import IDPAdapter from "common/Adapters/IDPAdapter/IDPAdapter";
import cloner from "common/Cloner";

class HttpClientAuthorizer {

    authorizeRequestConfig(requestConfig) {
        const accessToken = IDPAdapter.getToken();
        if (!accessToken) {
            return requestConfig;
        }
        const newRequestConfig = cloner.clone(requestConfig);
        newRequestConfig.headers = {authorization: `Bearer ${accessToken}`}
        return newRequestConfig;
    }

}

const httpClientAuthorizer = new HttpClientAuthorizer();
export default httpClientAuthorizer;