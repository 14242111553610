import {fileLoader} from "@sia/style-guide";

class Downloader {

    createBase64Href(base64, type = 'jpg') {
        if(base64.startsWith('JVBER'))
            return base64;
        return `data:application/${type};base64,${base64}`;
    }

    getFileExtensionFromBase64(base64) {
        const mimeType = fileLoader.getMimeTypeFromBase64(base64)
        const split = mimeType.split('/')
        return split[1]
    }
}

const downloader = new Downloader();
export default downloader;