import { formatSIAPageCT, formatEventOccurrence, formatMenuItems, formatSectionMenu, formatAgenda } from "./ContentFormatter";

const headers = {
  headers: {
    "api-key": process.env.REACT_APP_CMS_API_KEY,
  },
};
const cmsURL = process.env.REACT_APP_CMS_URL;

export default class HttpWebsiteAdapter {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  fetchMenuItems(languageKey, onSuccess, history) {
    const url = `${cmsURL}/${languageKey}/api/menu`;
    this.httpClient.request("get", url, undefined, ({ data }) => onSuccess(formatMenuItems(data, history, languageKey)), undefined, headers);
  }

  searchArticles(languageKey, params, onSuccess) {
    const url = `${cmsURL}/${languageKey}/api/article/search${params}`;
    this.httpClient.request("get", url, undefined, ({ data }) => onSuccess(data), undefined, headers);
  }

  articlesFilters(languageKey, onSuccess) {
    const url = `${cmsURL}/${languageKey}/api/article/filters`;
    this.httpClient.request("get", url, undefined, ({ data }) => onSuccess(data), undefined, headers);
  }

  fetchNoMenu(language, onSuccess) {
    const url = `${cmsURL}/${language}/api/nomenu`;
    this.httpClient.request("get", url, undefined, (data) => onSuccess(data), undefined, headers);
  }
  fetchSectionMenu(languageKey, sectionName, onSuccess) {
    const url = `${cmsURL}/${languageKey}/api/menu/section/${sectionName}`;
    this.httpClient.request("get", url, undefined, ({ data }) => onSuccess(formatSectionMenu(data, languageKey)), undefined, headers);
  }
  fetchSectionLogos(languageKey, sectionName, onSuccess) {
    const url = `${cmsURL}/${languageKey}/api/logo/section/${sectionName}`;
    this.httpClient.request("get", url, undefined, ({ data }) => onSuccess(data), undefined, headers);
  }
  fetchAliases(language, alias, onSuccess) {
    const url = `${cmsURL}/${language}/api/aliases?alias=${alias}`;
    this.httpClient.request("get", url, undefined, (data) => onSuccess(data.data), undefined, headers);
  }

  fetchNode(languageKey, node_id, onSuccess) {
    const url = `${cmsURL}/${languageKey}/api/node/${node_id}`;
    this.httpClient.request(
      "get",
      url,
      undefined,
      ({ data }) => {
        if (data?.content_type === "sia_page") return onSuccess(formatSIAPageCT(data));
        if (data?.content_type === "sektion_page") return onSuccess(formatSIAPageCT(data));
        if (data?.content_type === "event_occurrence") return onSuccess(formatEventOccurrence(data));
        return onSuccess(data);
      },
      undefined,
      headers
    );
  }

  fetchForm(languageKey, form_id, onSuccess) {
    const url = `${cmsURL}/${languageKey}/webform_rest/${form_id}/fields`;
    this.httpClient.request(
      "get",
      url,
      undefined,
      ({ data }) => {
        return onSuccess(data);
      },
      undefined,
      headers
    );
  }

  fetchTerms(languageKey, term_id, onSuccess) {
    const url = `${cmsURL}/${languageKey}/api/terms/${term_id}`;
    this.httpClient.request(
      "get",
      url,
      undefined,
      ({ data }) => {
        return onSuccess(data);
      },
      undefined,
      headers
    );
  }

  submitForm(languageKey, form_id, values, onSuccess, onError) {
    const url = `${cmsURL}/${languageKey}/webform_rest/submit`;
    this.httpClient.request(
      "post",
      url,
      { webform_id: form_id, ...values },
      (data) => {
        return onSuccess(data);
      },
      (data) => {
        return onError(data);
      },
      {
        headers: {
          ...headers.headers,
          "Content-Type": "application/json",
        },
      },
      true
    );
  }

  fetchNodeByHash(languageKey, hash, onSuccess) {
    const url = `${cmsURL}/${languageKey}/api/getByHash/${hash}`;
    this.httpClient.request(
      "get",
      url,
      undefined,
      ({ data }) => {
        if (data?.content_type === "sia_page") return onSuccess(formatSIAPageCT(data));
        if (data?.content_type === "sektion_page") return onSuccess(formatSIAPageCT(data));
        if (data?.content_type === "event_occurrence") return onSuccess(formatEventOccurrence(data));
        return onSuccess(data);
      },
      undefined,
      headers
    );
  }

  fetchAgenda(languageKey, onSuccess, translate, events_number) {
    const url = `${cmsURL}/${languageKey}/api/events${events_number ? `?events_number=${events_number}` : ""}`;
    setTimeout(() => {
      this.httpClient.request(
        "get",
        url,
        undefined,
        ({ data }) => onSuccess(formatAgenda(data, translate)),
        undefined,
        headers,
        false,
        !!events_number
      );
    }, 300);
  }

  fetchParagraph(onSuccess, languageKey, id) {
    const url = `${cmsURL}/${languageKey}/api/paragraph/${id}`;
    setTimeout(() => {
      this.httpClient.request("get", url, undefined, ({ data }) => onSuccess(data), undefined, headers, false, true);
    }, 300);
  }

  globalSearch(onSuccess, languageKey, text) {
    const url = `${cmsURL}/${languageKey}/api/search?text=${text}`;
    this.httpClient.request("get", url, undefined, ({ data }) => onSuccess(data), undefined, headers, false, false);
  }
}
