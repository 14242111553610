class Paginator {

    paginateItems(items, targetPage, limit) {
        const indexFactor = (targetPage - 1);
        const offset = indexFactor * limit;
        return items.slice(offset, offset + limit);
    }
}

const paginator = new Paginator();
export default paginator;