// todo https://stackoverflow.com/questions/71482650/how-to-abstract-environmental-js-class-implementations
import IDPUser from "./IDPUser";

const MockIDPAdapter = {
    user: null,
    init: (successCallback) => {
        const user = new IDPUser('mock-DE', 'de');
        // const user = null;
        MockIDPAdapter.user = user;
        successCallback(user);
    },
    isLoggedIn: () => {
        return Boolean(MockIDPAdapter.user);
    },
    fetchCurrentUser: () => {
        return new Promise((resolve) => {
            const currentUser = {
                siaId: 'mock-sia-id',
                firstName: 'John',
                lastName: 'Doe',
                email: 'john.doe@localhost.local',
            }
            resolve(currentUser);
        });
    },
    redirectToChangePasswordPage: () => {
        alert('IDPAdapter.redirectToChangePasswordPage()');
    },
    logout: () => {
    },
    createLoginUrl: () => {
        return 'loginUrl';
    },
    getToken: () => {
        return 'token-123';
    },
}
export default MockIDPAdapter;