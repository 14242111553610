export function scrollToElement(history) {
  setTimeout(() => {
    const myParam = history.location.search;
    const accordionItem = new URLSearchParams(myParam).get("item");
    if (accordionItem) return; //If there is an accordion item selected we should not scroll to the page section
    const elementId =
      decodeURIComponent(history?.location?.hash?.slice(1))
        ?.replace(/<[^>]*>?/gm, "")
        ?.toLowerCase() || "";
    const element = document.getElementById(elementId);
    if (!element) return;
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - 50;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }, 100);
}
