import Keycloak from 'keycloak-js';
import IDPUser from "./IDPUser";

const IDPUrl = process.env.REACT_APP_IDP_URL;
const realm = process.env.REACT_APP_IDP_REALM;
const clientId = process.env.REACT_APP_IDP_CLIENT_ID;
const keycloakClient = Keycloak({
    url: IDPUrl,
    realm: realm,
    clientId: clientId
});

const KeycloakIDPAdapter = {
    init: (successCallback) => {
        const initOptions = {
            onLoad: 'check-sso', // to keep logged-in state after refresh
            silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
        }
        keycloakClient.init(initOptions).then(() => {
            const user = keycloakClient.authenticated ? new IDPUser(
                keycloakClient.tokenParsed.preferred_username,
                keycloakClient.tokenParsed.locale,
                keycloakClient.tokenParsed.realm_access.roles[0]
            ) : null;
            successCallback(user);
        });
    },
    isLoggedIn: () => {
        return Boolean(keycloakClient.authenticated);
    },
    fetchCurrentUser: () => {
        return new Promise((resolve) => {
            keycloakClient.loadUserProfile().then(userProfileResponse => {
                const currentUser = {
                    siaId: userProfileResponse.username,
                    firstName: userProfileResponse.firstName,
                    lastName: userProfileResponse.lastName,
                    email: userProfileResponse.email,
                }
                resolve(currentUser);
            });
        });
    },
    createLoginUrl: (locale, redirectUrl) => {
        localStorage.setItem('sia.webPlatform.forceUserLanguage', true);
        return keycloakClient.createLoginUrl({
            locale: locale,
            redirectUri: redirectUrl ? redirectUrl : window.location.href
        });
    },
    createPasswordResetUrl: () => {
        return `${IDPUrl}/realms/${realm}/login-actions/reset-credentials?client_id=${clientId}#/password/request-reset`;
    },
    redirectToChangePasswordPage: () => {
        keycloakClient.login({action: 'UPDATE_PASSWORD'});
    },
    logout: (redirectUrl) => {
        keycloakClient.logout({redirectUri: redirectUrl || window.location.origin});
    },
    getToken: () => {
        return keycloakClient.token;
    },
    getClient: () => {
        return keycloakClient;
    }
}
export default KeycloakIDPAdapter;