import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { createConfig } from "@sia/shared/js/i18n";

const init = () => {
  i18n.use(initReactI18next).init({ ...createConfig(), nsSeparator: false });
};
const getBrowserLanguageKey = () => {
  return navigator.language.substring(0, 2); // substring because language may come as en_US
};
const getLocale = () => {
  const locales = {
    de: "de_DE",
    en: "en_US",
    fr: "fr_CH",
    it: "it_IT",
  };
  return locales[i18n.language];
};
export { i18n, init, getBrowserLanguageKey, getLocale };
