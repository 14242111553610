import cloner from "./Cloner";
import httpErrorsHandler from "./ErrorsHandlers/HttpErrorHandler";

const axios = require("axios").default;

class HttpClient {
  runningRequests = 0;

  setLoaderStateCallback(setIsLoaderShown) {
    // todo, find a solution on how to inject the react state setter in the constructor
    this.setIsLoaderShown = setIsLoaderShown;
  }

  showLoader() {
    this.setIsLoaderShown(true);
  }

  hideLoader() {
    this.setIsLoaderShown(false);
  }

  setNavigationCallBack(callBack) {
    this.navigationCallBack = callBack;
  }

  setErrorCallBack(callBack) {
    this.errorCallBack = callBack;
  }

  request(method, url, data, onSuccess, onError, config, ignoreError, ignoreLoader = false) {
    this.runningRequests++;
    !ignoreLoader && this.showLoader();
    const promise = this.createPromise(method, url, data, config);
    promise
      .then(onSuccess)
      .catch((error) => {
        if (ignoreError) onError(error);
        else httpErrorsHandler.handleError(error, this.navigationCallBack, this.errorCallBack, onError);
      })
      .finally(() => {
        this.runningRequests--;
        if (this.runningRequests === 0) {
          this.hideLoader();
        }
      });
  }
  // requestWithDelay(method, url, data, onSuccess, onError, config, ignoreError, delay) {
  //   this.runningRequests++;
  //   this.showLoader();
  //   setTimeout(() => {
  //     const promise = this.createPromise(method, url, data, config);
  //     promise
  //       .then(onSuccess)
  //       .catch((error) => {
  //         if (ignoreError) onError(error);
  //         else httpErrorsHandler.handleError(error, this.navigationCallBack, this.errorCallBack, onError);
  //       })
  //       .finally(() => {
  //         this.runningRequests--;
  //         if (this.runningRequests === 0) {
  //           this.hideLoader();
  //         }
  //       });
  //   }, delay);
  // }

  requestWithDelay(method, url, data, onSuccess, onError, config, ignoreError, delay) {
    this.runningRequests++;
    this.showLoader();
    const promise = this.createPromise(method, url, data, config);
    promise
      .then((data) => {
        setTimeout(
          () => {
            onSuccess(data);
          },

          delay
        );
      })
      .catch((error) => {
        if (ignoreError) onError(error);
        else httpErrorsHandler.handleError(error, this.navigationCallBack, this.errorCallBack, onError);
      })
      .finally(() => {
        setTimeout(() => {
          this.runningRequests--;
          if (this.runningRequests === 0) {
            this.hideLoader();
          }
        }, delay);
      });
  }

  createPromise(method, url, data, config) {
    switch (method.toLowerCase()) {
      case "get":
        const urlData = data ? "?" + new URLSearchParams(data).toString() : "";
        return axios.get(`${url}${urlData}`, config);
      case "post":
      case "patch":
      case "put":
      case "delete":
        const newConfig = cloner.clone(config);
        newConfig.method = method;
        newConfig.url = url;
        newConfig.data = data;
        return axios.request(newConfig);
      default:
        return;
    }
  }
}

const httpClient = new HttpClient();
export default httpClient;
