import React, { useContext, useEffect, useState } from "react";
import { useTranslator } from "common/Translation/Translator";
import { useHistory } from "react-router-dom";
import UserContentNavigation from "pages/Account/UserContentNavigation";
import Page from "pages/Page";
import {
  TrashCan24,
  Edit24,
  Content,
  AddAlt24,
  Form,
  InfoBlock,
  TextInput,
  Dropdown,
  Link,
  Tile,
  Modal,
  phoneFormatter,
  safeStateChanger,
  merger,
  resetMultiInputEmptyErrors,
  cloner,
  getMultiInputInnerIds,
  Checkbox,
} from "@sia/style-guide";
import "./AddressesPage.scss";
import addressSorter from "./AddressSorter";
import GlobalContext from "contexts/GlobalContext";
import Address from "./Address";
import { PhoneInput } from "components/PhoneInput";
import { usePathFactory } from "common/Path/PathFactoryHook";
import webPlatformAdapter from "common/Adapters/WebPlatformAdapter/WebPlatformAdapter";
import { BackButton } from "@sia/style-guide/dist/components/BackButton/BackButton";
import { WebsiteInput } from "components/WebsiteInput";
import EmailInput from "components/EmailInput";
import MultiInput from "components/MultiInput";
import countryRepository from "common/Country/CountryRepository";
import i18n from "i18next";
import erpAdapter from "adapters/ERPAdapter";
import { HttpErrorContext } from "contexts/HttpErrorContext";

const SubsidaryAddressesPage = () => {
  const translate = useTranslator();
  const history = useHistory();
  const pathFactory = usePathFactory();
  const GlobalContextValue = useContext(GlobalContext);
  const user = GlobalContextValue.user;
  const language = GlobalContextValue.language;
  const [removingAddress, setRemovingAddress] = useState();
  const [editingAddress, setEditingAddress] = useState();
  const [addingAddress, setAddingAddress] = useState();
  const [countries, setCountries] = useState([]);
  const [cantons, setCantons] = useState([]);
  const [genders, setGenders] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [subsidiaries, setSubsidiaries] = useState([]);
  const [selectedSubsidiary, setSelectedSubsidiary] = useState(null);
  const [shouldFetchSelectedSubsidiary, setShouldFechSelectedSubsidiary] = useState(true);
  const initialAddressFormValues = {
    firstName: "",
    lastName: "",
    department: "",
    gender: null,
    useHeadquarters: false,
    additionalInfo: "",
    primaryStreetWithNumber: "",
    secondaryStreetWithNumber: "",
    postOfficeBox: "",
    zip: "",
    location: "",
    canton: null,
    country: null,
    email: "",
    phone: "",
    mobile: "",
    website: "",
  };
  const initialFormErrors = {
    corporateSubaddressEmployee: null,
    firstName: null,
    lastName: null,
    department: null,
    gender: null,
    contact: null,
    canton: null,
    email: null,
    phone: null,
    mobile: null,
  };
  const [addressFormValues, setAddressFormValues] = useState(initialAddressFormValues);
  const [formErrors, setFormErrors] = useState(initialFormErrors);
  const [editingAddressSelection, setEditingAddressSelection] = useState(false);

  const [chosenDropdownContactAddress, setChosenDropdownContactAddress] = useState(null);
  const [chosenDropdownDeliveryAddress, setChosenDropdownDeliveryAddress] = useState(null);
  const [chosenDropdownInvoiceAddress, setChosenDropdownInvoiceAddress] = useState(null);
  const [persistedDropdownContactAddress, setPersistedDropdownContactAddress] = useState(null);
  const [persistedDropdownDeliveryAddress, setPersistedDropdownDeliveryAddress] = useState(null);
  const [persistedDropdownInvoiceAddress, setPersistedDropdownInvoiceAddress] = useState(null);

  const [wasCountriesFetchInitialized, setWasCountriesFetchInitialized] = useState(false);
  const [wasCantonsFetchInitialized, setWasCantonsFetchInitialized] = useState(false);
  const [shouldFetchAddresses, setShouldFetchAddresses] = useState(true);
  const [shouldFetchSubsidaries, setShouldFetchSubsidaries] = useState(true);
  const [shouldFetchCustomerData, setShouldFetchCustomerData] = useState(true);
  const [wasGendersFetchInitialized, setWasGendersFetchInitialized] = useState(false);
  const [isEmailOrWebsiteInvalid, setIsEmailOrWebsiteInvalid] = useState(false);
  const title = translate("account.customer-data.addresses.subsidiary");
  const country = addressFormValues.country ? countryRepository.find(addressFormValues.country.id) : null;
  const switzerlandCountry = countryRepository.findByName("switzerland");
  const [shouldChangeTopAddresses, setShouldChangeTopAddresses] = useState(false);
  const [contactAddressId, setContactAddressId] = useState(null);
  const [deliveryAddressId, setDeliveryAddressId] = useState(null);
  const [invoiceAddressId, setInvoiceAddressId] = useState(null);
  const createCommonAddressName = (address) => {
    return `${translate("account.customer-data.addresses.type.common")} ${address.getLabel()}`;
  };
  const createAddressName = (address) => {
    if (address.isDomicile()) {
      if (user.isMemberTypeGroupOf("corporate")) {
        return translate("account.customer-data.addresses.type.headquarters");
      }
    }
    return createCommonAddressName(address);
  };
  const dropdownAddresses = addressSorter.sort(addresses).map((address) => {
    return {
      id: address.getId(),
      label: createAddressName(address),
    };
  });

  const { setError: setHttpError } = useContext(HttpErrorContext);

  useEffect(() => {
    const createAddresses = (responseAddresses) => {
      return responseAddresses.map((responseAddress) => {
        const country = countries.find((country) => country.id === responseAddress.country.id);
        const canton = cantons.find((canton) => canton.id === responseAddress.state?.id);
        return new Address(
          responseAddress.id,
          responseAddress.is_main,
          responseAddress.firstname,
          responseAddress.lastname,
          responseAddress.third_party,
          responseAddress.gender,
          responseAddress.use_parent_address,
          responseAddress.label,
          responseAddress.can_be_deleted,
          responseAddress.additional_line,
          responseAddress.street,
          // todo SW-416, fix inconsistent response schema
          responseAddress.street_2 ? responseAddress.street_2 : responseAddress.street2,
          responseAddress.box_number,
          responseAddress.zip,
          responseAddress.city,
          canton,
          country,
          responseAddress.email,
          phoneFormatter.format(responseAddress.phone),
          phoneFormatter.format(responseAddress.mobile),
          responseAddress.website,
          // todo fix typo https://project.sia.ch/web#id=2071&action=155&active_id=64&model=project.task&view_type=form&cids=1&menu_id=
          responseAddress.address_lines
        );
      });
    };
    const fetchCountries = () => {
      webPlatformAdapter.getCountries(language, (response) => {
        const responseCountries = response.data;
        const countries = responseCountries.map((item) => {
          return { id: item.id, label: item.name };
        });
        setCountries(countries);
      });
    };
    const fetchCantons = () => {
      webPlatformAdapter.getStates(language, (response) => {
        const responseCantons = response.data;
        const cantons = responseCantons.map((item) => {
          return { id: item.id, label: item.name };
        });
        setCantons(cantons);
      });
    };

    const changeTopAddresses = () => {
      const contactDropdownAddress = dropdownAddresses.find((dropdownAddress) => dropdownAddress.id === contactAddressId);
      const deliveryDropdownAddress = dropdownAddresses.find((dropdownAddress) => dropdownAddress.id === deliveryAddressId);
      const invoiceDropdownAddress = dropdownAddresses.find((dropdownAddress) => dropdownAddress.id === invoiceAddressId);

      setPersistedDropdownContactAddress(contactDropdownAddress);
      setPersistedDropdownDeliveryAddress(deliveryDropdownAddress);
      setPersistedDropdownInvoiceAddress(invoiceDropdownAddress);

      setChosenDropdownContactAddress(contactDropdownAddress);
      setChosenDropdownDeliveryAddress(deliveryDropdownAddress);
      setChosenDropdownInvoiceAddress(invoiceDropdownAddress);
      setShouldChangeTopAddresses(false);
    };

    const fetchGenders = () => {
      const data = { lang: webPlatformAdapter.getLocale(i18n.language) };
      webPlatformAdapter.request("get", "rpc/get_genders_list", data, (response) => {
        setGenders(response.data);
      });
    };
    const fetchSubsidaries = () => {
      const data = { lang: webPlatformAdapter.getLocale(i18n.language) };
      webPlatformAdapter.request("get", "rpc/get_subsidiaries_corporate", data, (response) => {
        let subsidiaries = [];
        subsidiaries = response.data.map((subsidary) => ({
          id: subsidary.sia_id,
          label: `${subsidary.sia_id} - ${subsidary.name} - ${subsidary.city}`,
        }));
        setSelectedSubsidiary(subsidiaries[0]);
        setSubsidiaries(subsidiaries);
      });
    };
    const fetchSubsidiaryById = (id) => {
      if (user.isMemberTypeGroupOf("corporate")) {
        webPlatformAdapter.getSubsidiaryAddressById(id, language, (response) => {
          setAddresses(createAddresses(response.data));
          setContactAddressId(response?.data[0]?.address_matrix?.contact?.id);
          setDeliveryAddressId(response?.data[0]?.address_matrix?.delivery?.id);
          setInvoiceAddressId(response?.data[0]?.address_matrix?.invoice?.id);
          setShouldChangeTopAddresses(true);
        });
      }
    };
    if (!user) {
      return;
    }
    if (shouldChangeTopAddresses) {
      changeTopAddresses();
    }
    if (!wasCountriesFetchInitialized) {
      setWasCountriesFetchInitialized(true);
      fetchCountries();
    }
    if (!wasCantonsFetchInitialized) {
      setWasCantonsFetchInitialized(true);
      fetchCantons();
    }
    if (!wasGendersFetchInitialized) {
      setWasGendersFetchInitialized(true);
      fetchGenders();
    }
    if (selectedSubsidiary && shouldFetchSelectedSubsidiary) {
      fetchSubsidiaryById(selectedSubsidiary.id);
      setShouldFechSelectedSubsidiary(false);
    }
    if (shouldFetchSubsidaries) {
      fetchSubsidaries();
      setShouldFetchSubsidaries(false);
    }
  }, [
    user,
    wasCountriesFetchInitialized,
    wasCantonsFetchInitialized,
    shouldFetchAddresses,
    cantons,
    countries,
    shouldFetchCustomerData,
    addresses.length,
    dropdownAddresses,
    wasGendersFetchInitialized,
    language,
    shouldFetchSubsidaries,
    selectedSubsidiary,
    shouldFetchSelectedSubsidiary,
    addresses,
    shouldChangeTopAddresses,
    contactAddressId,
    deliveryAddressId,
    invoiceAddressId,
  ]);

  const getSaveEndpoint = (mode, isDomicile) => {
    if (user.isMemberTypeGroupOf("corporate")) {
      return isDomicile ? "account/corporate/subsidiaryaddresses/main" : "account/corporate/subsidiaryaddresses/sub";
    }
  };

  const getSaveData = () => {
    const baseData = {
      address_id: editingAddress?.getId(),
      additional_line: addressFormValues.additionalInfo,
      street: addressFormValues.primaryStreetWithNumber,
      street2: addressFormValues.secondaryStreetWithNumber,
      box_number: addressFormValues.postOfficeBox,
      zip: addressFormValues.zip,
      city: addressFormValues.location,
      country_id: addressFormValues.country?.id,
      state_id: addressFormValues.canton?.id,
      email: addressFormValues.email,
      phone: addressFormValues.phone,
      mobile: addressFormValues.mobile,
      website: addressFormValues.website,
    };
    if (user.isMemberTypeGroupOf("corporate")) {
      baseData["firstname"] = addressFormValues.firstName;
      baseData["lastname"] = addressFormValues.lastName;
      baseData["third_party"] = addressFormValues.department;
      baseData["use_parent_address"] = addressFormValues.useHeadquarters;
      baseData["gender"] = addressFormValues.gender?.id;
      baseData["subsidiary_sia_id"] = selectedSubsidiary.id;
    }
    return baseData;
  };

  const onSaveAssignments = () => {
    const data = {
      subsidiary_sia_id: selectedSubsidiary.id,
      contact_address_id: chosenDropdownContactAddress.id,
      invoice_address_id: chosenDropdownInvoiceAddress.id,
      delivery_address_id: chosenDropdownDeliveryAddress.id,
    };
    erpAdapter.fetch(
      "put",
      "account/corporate/subsidiaryaddresses/addresses-matrix",
      data,
      (response) => {
        setShouldFetchCustomerData(true);
        setEditingAddressSelection(false);
        setShouldChangeTopAddresses(true);
        setShouldFechSelectedSubsidiary(true);
      },
      () => setIsEmailOrWebsiteInvalid(true)
    );
  };
  const onConfirmAddressRemove = () => {
    const data = { address_id: removingAddress.id, subsidiary_sia_id: selectedSubsidiary.id };
    erpAdapter.fetch("delete", "account/corporate/subsidiaryaddresses/sub", data, (response) => {
      setRemovingAddress(null);
      setShouldFechSelectedSubsidiary(true);
      setShouldChangeTopAddresses(true);
    });
  };
  const onConfirmAddressSave = (mode, isDomicile) => {
    const method = mode === "edit" ? "put" : "post";
    const endpoint = getSaveEndpoint(mode, isDomicile);
    const data = getSaveData();
    erpAdapter.fetch(
      method,
      endpoint,
      data,
      (response) => {
        setEditingAddress(null); // todo, merge edit/add form flags
        setAddingAddress(null);
        setAddressFormValues(initialAddressFormValues);
        setShouldFetchAddresses(true);
        setShouldFechSelectedSubsidiary(true);
      },
      () => setIsEmailOrWebsiteInvalid(true)
    );
  };
  const onChangeAddressFormValue = (property, value, error) => {
    let newFormValues = cloner.clone(addressFormValues);

    newFormValues = safeStateChanger.changeValue(newFormValues, property, value);
    if (property === "country" && value.id !== switzerlandCountry.getId()) {
      newFormValues = safeStateChanger.changeValue(newFormValues, "canton", null);
    }

    setAddressFormValues(newFormValues);
    setFormErrors(safeStateChanger.changeValue(formErrors, property, error));
  };
  const onChangeMultiInputField = (multiInputId, name, value, error) => {
    const inputIds = getMultiInputInnerIds(multiInputId);
    const newFormErrors = resetMultiInputEmptyErrors(formErrors, multiInputId, inputIds);
    setFormErrors(safeStateChanger.changeValue(newFormErrors, name, error));
    setAddressFormValues(safeStateChanger.changeValue(addressFormValues, name, value));
  };
  const onCancelAddressForm = (mode) => {
    setAddressFormValues(initialAddressFormValues);
    setFormErrors(initialFormErrors);
    setIsEmailOrWebsiteInvalid(false);

    if (mode === "edit") {
      setEditingAddress(null);
      return;
    }
    if (mode === "add") {
      setAddingAddress(null);
    }
  };
  const createManageableAddressActionIcons = (address) => {
    const icons = [];
    if (!address.isDomicile()) {
      const onClickRemove = address.isRemovable() ? () => setRemovingAddress(address) : null;
      icons.push(<TrashCan24 key={"remove"} disabled={!address.isRemovable()} onClick={onClickRemove} />);
    }
    const canEdit = !(editingAddressSelection || editingAddress || addingAddress);
    const gender = genders.find((gender) => gender.code === address.getGender());
    const onClickEdit = canEdit
      ? () => {
          setEditingAddress(address);
          setAddressFormValues({
            firstName: address.getFirstName() ? address.getFirstName() : "",
            lastName: address.getLastName() ? address.getLastName() : "",
            department: address.getDepartment() ? address.getDepartment() : "",
            gender: gender ? { id: gender["code"], label: gender["title"] } : null,
            useHeadquarters: address.getUseHeadquarters(),
            additionalInfo: address.getAdditionalInfo() ? address.getAdditionalInfo() : "",
            primaryStreetWithNumber: address.getPrimaryStreetWithNumber() ? address.getPrimaryStreetWithNumber() : "",
            secondaryStreetWithNumber: address.getSecondaryStreetWithNumber() ? address.getSecondaryStreetWithNumber() : "",
            postOfficeBox: address.getPostOfficeBox() ? address.getPostOfficeBox() : "",
            zip: address.getZip(),
            location: address.getLocation(),
            canton: address.getCanton(),
            country: address.getCountry(),
            email: address.getEmail() ? address.getEmail() : "",
            phone: address.getPhone() ? address.getPhone() : "",
            mobile: address.getMobile() ? address.getMobile() : "",
            website: address.getWebsite() ? address.getWebsite() : "",
          });
        }
      : null;
    icons.push(<Edit24 key={"edit"} disabled={!canEdit} onClick={onClickEdit} />);
    return icons;
  };

  const renderAddressForm = (mode, isDomicile) => {
    const showWebsiteInput = user.isMemberTypeGroupOf("corporate");
    const showUseHeadquartersCheckbox = user.isMemberTypeGroupOf("corporate") && !isDomicile;
    const showAddressFields =
      (user.isMemberTypeGroupOf("corporate") && isDomicile) ||
      (user.isMemberTypeGroupOf("corporate") && !isDomicile && !addressFormValues.useHeadquarters);
    return (
      <Form
        onClickPrimaryButton={() => onConfirmAddressSave(mode, isDomicile)}
        primaryButtonLabel={translate("form.save")}
        onErrors={(errors) => setFormErrors(merger.mergeObjectsImmutable(initialFormErrors, errors))}
        onClickSecondaryButton={() => {
          onCancelAddressForm(mode);
          if (isEmailOrWebsiteInvalid) {
            setIsEmailOrWebsiteInvalid(false);
            setHttpError((err) => ({ ...err, isEmailOrWebsiteInvalid: false }));
          }
        }}
        secondaryButtonLabel={translate("form.cancel")}
      >
        {user.isMemberTypeGroupOf("corporate") && !isDomicile && (
          <>
            <MultiInput id={"corporateSubaddressEmployee"} invalidText={translate(formErrors.corporateSubaddressEmployee)}>
              <TextInput
                id={"firstName"}
                disabled={mode === "edit"}
                label={translate("account.customer-data.addresses.subsidiary.address.employee.first-name")}
                onChange={(event, value, error) => onChangeMultiInputField("corporateSubaddressEmployee", "firstName", value, error)}
                value={addressFormValues.firstName}
                invalidText={translate(formErrors.firstName)}
              />
              <TextInput
                id={"lastName"}
                disabled={mode === "edit"}
                label={translate("account.customer-data.addresses.subsidiary.address.employee.last-name")}
                onChange={(event, value, error) => onChangeMultiInputField("corporateSubaddressEmployee", "lastName", value, error)}
                value={addressFormValues.lastName}
                invalidText={translate(formErrors.lastName)}
              />
              <TextInput
                id={"department"}
                disabled={mode === "edit"}
                label={translate("account.customer-data.addresses.subsidiary.address.department")}
                onChange={(event, value, error) => onChangeMultiInputField("corporateSubaddressEmployee", "department", value, error)}
                value={addressFormValues.department}
                invalidText={translate(formErrors.department)}
              />
            </MultiInput>
            {(addressFormValues.firstName || addressFormValues.lastName) && (
              <Dropdown
                id={"gender"}
                required
                disabled={mode === "edit"}
                label={translate("account.customer-data.addresses.subsidiary.address.gender")}
                items={genders.map((gender) => {
                  return { id: gender["code"], label: gender["title"] };
                })}
                value={addressFormValues.gender}
                onChange={(event, item) => onChangeAddressFormValue("gender", item)}
                invalidText={translate(formErrors.gender)}
              />
            )}
          </>
        )}
        {showUseHeadquartersCheckbox && (
          <Checkbox
            id={"use-headquarters"}
            label={translate("account.customer-data.addresses.subsidiary.address.corporate.use-headquarters")}
            value={addressFormValues.useHeadquarters}
            onChange={(event, value) => onChangeAddressFormValue("useHeadquarters", value)}
          />
        )}
        {showAddressFields && (
          <>
            <MultiInput id={"address"} invalidText={translate(formErrors.address)}>
              <TextInput
                id={"primaryStreetWithNumber"}
                label={translate("account.customer-data.addresses.subsidiary.address.street-with-number.primary")}
                onChange={(event, value, error) => onChangeMultiInputField("address", "primaryStreetWithNumber", value, error)}
                value={addressFormValues.primaryStreetWithNumber}
                invalidText={translate(formErrors.primaryStreetWithNumber)}
              />
              <TextInput
                id={"secondaryStreetWithNumber"}
                label={translate("account.customer-data.addresses.subsidiary.address.street-with-number.secondary")}
                onChange={(event, value, error) => onChangeMultiInputField("address", "secondaryStreetWithNumber", value, error)}
                value={addressFormValues.secondaryStreetWithNumber}
                invalidText={translate(formErrors.secondaryStreetWithNumber)}
              />
              <TextInput
                id={"postOfficeBox"}
                label={translate("account.customer-data.addresses.subsidiary.address.post-office-box")}
                onChange={(event, value, error) => onChangeMultiInputField("address", "postOfficeBox", value, error)}
                value={addressFormValues.postOfficeBox}
                invalidText={translate(formErrors.postOfficeBox)}
              />
            </MultiInput>
            <TextInput
              id={"zip"}
              required
              label={translate("account.customer-data.addresses.subsidiary.address.zip")}
              onChange={(event) => onChangeAddressFormValue("zip", event.target.value)}
              value={addressFormValues.zip}
              invalidText={translate(formErrors.zip)}
            />
            <TextInput
              id={"location"}
              required
              label={translate("account.customer-data.addresses.subsidiary.address.location")}
              onChange={(event) => onChangeAddressFormValue("location", event.target.value)}
              value={addressFormValues.location}
              invalidText={translate(formErrors.location)}
            />
            <Dropdown
              id={"country"}
              required
              label={translate("account.customer-data.addresses.subsidiary.address.country")}
              items={countries}
              value={addressFormValues.country}
              onChange={(event, item) => onChangeAddressFormValue("country", item)}
              invalidText={translate(formErrors.country)}
            />
            {country && country.getName() === "switzerland" && (
              <Dropdown
                id={"canton"}
                required
                label={translate("account.customer-data.addresses.subsidiary.address.canton")}
                items={cantons}
                value={addressFormValues.canton}
                onChange={(event, item) => onChangeAddressFormValue("canton", item)}
                invalidText={translate(formErrors.canton)}
              />
            )}
            {isDomicile && (
              <EmailInput
                id={"email"}
                value={addressFormValues.email}
                required
                label={translate("account.customer-data.addresses.subsidiary.address.email")}
                onChange={(event, value, error) => {
                  if (isEmailOrWebsiteInvalid) {
                    setIsEmailOrWebsiteInvalid(false);
                    setHttpError((err) => ({ ...err, isEmailOrWebsiteInvalid: false }));
                  }
                  onChangeAddressFormValue("email", value, error);
                }}
                invalidText={translate(formErrors.email)}
              />
            )}
          </>
        )}
        <MultiInput id={"contact"} invalidText={translate(formErrors.contact)}>
          {!isDomicile && (
            <EmailInput
              id={"email"}
              value={addressFormValues.email}
              label={translate("account.customer-data.addresses.subsidiary.address.email")}
              onChange={(event, value, error) => {
                if (isEmailOrWebsiteInvalid) {
                  setIsEmailOrWebsiteInvalid(false);
                  setHttpError((err) => ({ ...err, isEmailOrWebsiteInvalid: false }));
                }
                onChangeMultiInputField("contact", "email", value, error);
              }}
              invalidText={translate(formErrors.email)}
            />
          )}
          <PhoneInput
            id={"phone"}
            value={addressFormValues.phone}
            label={translate("account.attributes.phone")}
            onChange={(event, value, error) => onChangeMultiInputField("contact", "phone", value, error)}
            invalidText={translate(formErrors.phone)}
          />
          <PhoneInput
            id={"mobile"}
            value={addressFormValues.mobile}
            label={translate("account.customer-data.addresses.subsidiary.address.mobile")}
            onChange={(event, value, error) => onChangeMultiInputField("contact", "mobile", value, error)}
            invalidText={translate(formErrors.mobile)}
          />
        </MultiInput>
        {showWebsiteInput && (
          <WebsiteInput
            id={"website"}
            value={addressFormValues.website}
            label={translate("account.customer-data.addresses.subsidiary.address.website")}
            onChange={(event, value, error) => {
              if (isEmailOrWebsiteInvalid) {
                setIsEmailOrWebsiteInvalid(false);
                setHttpError((err) => ({ ...err, isEmailOrWebsiteInvalid: false }));
              }
              onChangeAddressFormValue("website", value, error);
            }}
            invalidText={translate(formErrors.website)}
          />
        )}
        {isEmailOrWebsiteInvalid && (
          <span className="address-error-message">{translate("account.customer-data.addresses.subsidiary.address.submit.error")}</span>
        )}
      </Form>
    );
  };
  const renderBreakedParagraph = (values, strong) => {
    if (!values.length) {
      return;
    }
    const renderBreakedValues = () =>
      values
        .filter((item) => item)
        .map((value, index) => {
          const isLast = values.length === index + 1;
          return !isLast ? (
            <React.Fragment key={index}>
              {value}
              <br />
            </React.Fragment>
          ) : (
            value
          );
        });
    const breakedValues = renderBreakedValues();
    return strong ? (
      <p>
        <strong>{breakedValues}</strong>
      </p>
    ) : (
      <p>{breakedValues}</p>
    );
  };
  const renderCorporateHighlightedInfo = (address) => {
    if (!(address.getDepartment() || address.getFirstName() || address.getLastName())) {
      return;
    }
    return renderBreakedParagraph([address.getDepartment(), [address.getFirstName(), address.getLastName()].join(" ")], true);
  };
  const renderAddressInfo = (address) => {
    const preformattedPostalAddressLines = Object.values(address.getPreformattedLines() || {}).filter((item) => item);
    const phoneValues = [address.getPhone(), address.getMobile()].filter((item) => item).map((item) => phoneFormatter.format(item));
    const webValues = [address.getEmail(), address.getWebsite()];
    const linkValues = (values) =>
      values.map((value) => (
        <Link key={value} target={value}>
          {value}
        </Link>
      ));
    return (
      <>
        {user.isMemberTypeGroupOf("corporate") && !address.isDomicile() && renderCorporateHighlightedInfo(address)}
        {renderBreakedParagraph(preformattedPostalAddressLines)}
        {renderBreakedParagraph(linkValues(phoneValues))}
        {renderBreakedParagraph(linkValues(webValues.filter((item) => item)))}
      </>
    );
  };
  const renderManageableAddresses = () => {
    return addressSorter.sort(addresses).map((address) => {
      const isEditingAddress = editingAddress?.id === address.getId();
      if (isEditingAddress) {
        return <Tile key={address.getId()}>{renderAddressForm("edit", address.isDomicile())}</Tile>;
      }
      const actionIcons = createManageableAddressActionIcons(address);
      const labelText = createAddressName(address);
      return (
        <InfoBlock actionIcons={actionIcons} key={address.getId()}>
          <span className={"label"}>{labelText}</span>
          {renderAddressInfo(address)}
        </InfoBlock>
      );
    });
  };
  const removeAddressModal = removingAddress ? (
    <Modal
      title={translate("modal.title.remove", { name: createCommonAddressName(removingAddress) })}
      lead={translate("account.customer-data.addresses.subsidiary.manage.remove.are-you-sure", { confirmLabel: translate("modal.button.confirm") })}
      confirmLabel={translate("modal.button.confirm")}
      cancelLabel={translate("modal.button.cancel")}
      onConfirm={onConfirmAddressRemove}
      onCancel={() => setRemovingAddress(null)}
    />
  ) : null;
  const resetAddressSelection = () => {
    setChosenDropdownContactAddress(persistedDropdownContactAddress);
    setChosenDropdownDeliveryAddress(persistedDropdownDeliveryAddress);
    setChosenDropdownInvoiceAddress(persistedDropdownInvoiceAddress);
  };
  const renderAddressSelection = () => {
    if (editingAddressSelection) {
      return (
        <Tile>
          <Form
            primaryButtonLabel={translate("form.save")}
            onClickPrimaryButton={onSaveAssignments}
            secondaryButtonLabel={translate("form.cancel")}
            onClickSecondaryButton={() => {
              resetAddressSelection();
              setEditingAddressSelection(false);
            }}
          >
            <Dropdown
              id={"contact"}
              label={translate("account.customer-data.addresses.type.contact")}
              placeholder={translate("form.dropdown.choose")}
              items={dropdownAddresses}
              value={chosenDropdownContactAddress}
              onChange={(event, item) => setChosenDropdownContactAddress(item)}
            />
            <Dropdown
              id={"delivery"}
              label={translate("account.customer-data.addresses.type.delivery")}
              placeholder={translate("form.dropdown.choose")}
              items={dropdownAddresses}
              value={chosenDropdownDeliveryAddress}
              onChange={(event, item) => setChosenDropdownDeliveryAddress(item)}
            />
            <Dropdown
              id={"invoice"}
              label={translate("account.customer-data.addresses.type.invoice")}
              placeholder={translate("form.dropdown.choose")}
              items={dropdownAddresses}
              value={chosenDropdownInvoiceAddress}
              onChange={(event, item) => setChosenDropdownInvoiceAddress(item)}
            />
          </Form>
        </Tile>
      );
    }
    const onClickEdit = !editingAddress ? () => setEditingAddressSelection(true) : null;
    return (
      <InfoBlock actionIcons={[<Edit24 key={"edit"} disabled={editingAddress} onClick={onClickEdit} />]}>
        <span className={"label"}>{translate("account.customer-data.addresses.type.contact")}</span>
        <p>{persistedDropdownContactAddress && persistedDropdownContactAddress.label}</p>
        <span className={"label"}>{translate("account.customer-data.addresses.type.delivery")}</span>
        <p>{persistedDropdownDeliveryAddress && persistedDropdownDeliveryAddress.label}</p>
        <span className={"label"}>{translate("account.customer-data.addresses.type.invoice")}</span>
        <p>{persistedDropdownInvoiceAddress && persistedDropdownInvoiceAddress.label}</p>
      </InfoBlock>
    );
  };
  const addAddressForm = addingAddress ? <Tile>{renderAddressForm("add", false)}</Tile> : null;
  const canAddAddress = !addingAddress && !editingAddress;
  const onClickAddAddress = canAddAddress ? () => setAddingAddress(true) : null;
  return (
    <Page contentNavigation={<UserContentNavigation />} title={"account.name"}>
      <Content width={"slim"} centeredTexts={true}>
        <h1>
          <BackButton onClick={() => history.push(pathFactory.create("account/customer-data"))} />
          {title}
        </h1>

        <p>{translate("account.customer-data.addresses.subsidiary.choose.description")}</p>
        <Dropdown
          id={"subsidiary"}
          label={translate("account.customer-data.addresses.subsidiary")}
          items={subsidiaries}
          onChange={(e, value) => {
            if (!selectedSubsidiary || value.id !== selectedSubsidiary.id) {
              setShouldFechSelectedSubsidiary(true);
            }
            if (editingAddress) {
              setEditingAddress(null);
            }
            if (editingAddressSelection) {
              setEditingAddressSelection(false);
            }
            setSelectedSubsidiary(value);
          }}
          value={selectedSubsidiary}
        />
        {renderAddressSelection()}

        <p>{translate("account.customer-data.addresses.subsidiary.manage.description")}</p>
        {renderManageableAddresses()}

        {addAddressForm}
        <div className={"add-address"}>
          <AddAlt24 disabled={!canAddAddress} onClick={onClickAddAddress} />
        </div>

        {removeAddressModal}
      </Content>
    </Page>
  );
};
export default SubsidaryAddressesPage;
