import React, {useContext} from 'react';
import PropTypes from "prop-types";
import {Route} from 'react-router-dom';
import IDPAdapter from "common/Adapters/IDPAdapter/IDPAdapter";
import GlobalContext from "contexts/GlobalContext";
import {useTranslation} from "react-i18next";
import NotLoggedInPage from "./pages/Error/NotLoggedinPage";
import PermissionDeniedPage from "./pages/Error/PermissionDeniedPage";
import redirector from "common/Redirector";

const PrivateRoute = (props) => {
    const {i18n} = useTranslation();
    const GlobalContextValue = useContext(GlobalContext);
    const user = GlobalContextValue.user;
    const createRoute = (render) => <Route exact={props.exact} path={props.path} render={render}/>;
    const component = props.render();
    if (component.type.name === 'Redirect' ||
        component.type.name === 'NotFoundPage') {
        return createRoute(props.render);
    }
    if (!GlobalContextValue.isIDPInitialized) {
        return createRoute(() => <NotLoggedInPage/>);
    }
    if (IDPAdapter.isLoggedIn()) {
        if (!user) {
            return <NotLoggedInPage/>
        }
        if (props.allowedMemberTypes && !props.allowedMemberTypes.includes(user.getMemberType().getName())) {
            return createRoute(() => <PermissionDeniedPage/>);
        }
        return createRoute(props.render);
    }
    redirector.redirectToLoginForm(i18n.language);
}
PrivateRoute.propTypes = {
    path: PropTypes.string.isRequired,
    render: PropTypes.func.isRequired,
    exact: PropTypes.bool,
    allowedMemberTypes: PropTypes.array,
};
export default PrivateRoute;