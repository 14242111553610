import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslator } from "common/Translation/Translator";
import { ServiceSubscriptionBox, Modal, dateFormatter, currencyFormatter } from "@sia/style-guide";
import UserContentNavigation from "./UserContentNavigation";
import Page from "pages/Page";
import GlobalContext from "contexts/GlobalContext";
import webPlatformAdapter from "common/Adapters/WebPlatformAdapter/WebPlatformAdapter";
import { usePathFactory } from "common/Path/PathFactoryHook";
import { useHistory } from "react-router-dom";
import "./ServicesPage.scss";
import NotFoundPage from "pages/Error/NotFoundPage";
import erpAdapter from "adapters/ERPAdapter";

const ServicesPage = ({ setIsSubscribedToJobs }) => {
  const [services, setServices] = useState([]);
  const [modalState, setModalState] = useState({
    visible: false,
    modalTitle: "",
    modalLead: "",
  });
  const [shouldFetch, setShouldFetch] = useState(true);
  const [handleModalConfirm, setHandleModalConfirm] = useState(() => () => null);
  const { language, isSubscribedToAnyService, user } = useContext(GlobalContext);
  const translate = useTranslator();
  const history = useHistory();
  const pathFactory = usePathFactory();

  const [newsletters, setNewsletters] = useState([]);
  const [isSubscribedToNewsLetter, setIsSubscribedToNewsletter] = useState(false);

  const [magazines, setMagazines] = useState([]);
  const [selectedMagazine, setSelectedMagazine] = useState(undefined);
  const [isSubscribedToMagazines, setIsSubscribedToMagazines] = useState(false);
  const [ePaper, setEpaper] = useState(false);
  const [printed, setPrinted] = useState(false);
  const [isMagazineSaveButtonActive, setIsMagazineSaveButtonActive] = useState(true);

  const checkBoxes = useMemo(
    () => ({
      [translate("subscriptionBox.form.e-paper")]: {
        value: ePaper,
        onCheckboxChange: (e, value) => {
          if (!selectedMagazine) {
            setEpaper(false);
            setPrinted(false);
            return;
          }
          setEpaper(value);
        },
      },

      [[translate("subscriptionBox.form.printed")]]: {
        value: printed,
        onCheckboxChange: (e, value) => {
          if (!selectedMagazine) {
            setEpaper(false);
            setPrinted(false);
            return;
          }
          setPrinted(value);
        },
      },
    }),
    [ePaper, printed, selectedMagazine, translate]
  );

  const onMagazineChange = useCallback((value) => {
    setSelectedMagazine(value);
    setServices((serviceState) => {
      let serviceStateCopy = [...serviceState];
      let firstService = serviceState[0];
      firstService.options.selectedValue = value;
      return serviceStateCopy;
    });
  }, []);

  useEffect(() => {
    if (!selectedMagazine) {
      Object.values(checkBoxes).forEach((checkBox) => {
        checkBox.onCheckboxChange(null, false);
      });
      return;
    }
  }, [selectedMagazine, checkBoxes]);
  const REFERENCE_GALLERY_SERVICE_ID = 119055;
  const JOBS_SERVICE_ID = 49899;
  const LAW_SERVICE_ID = 49891;
  const MAGAZINE_SERVICE_ID = 4;
  const NEWSLETTER_SERVICE_ID = 49925;

  const unsubscribeFromService = (service_id, product_id) => {
    switch (service_id) {
      case JOBS_SERVICE_ID:
        erpAdapter.fetch(
          "POST",
          "account/corporate/services/service/unsubscribe",
          {
            product_id,
          },
          (response) => {
            setShouldFetch(true);
          }
        );
        break;
      // TODO chanage to the correct endpoint when the API is ready.
      case LAW_SERVICE_ID:
        erpAdapter.fetch(
          "POST",
          "account/corporate/services/service/unsubscribe",
          {
            product_id,
          },
          (response) => {
            setShouldFetch(true);
          }
        );
        break;

      // TODO chanage to the correct endpoint when the API is ready.
      case MAGAZINE_SERVICE_ID:
        erpAdapter.fetch(
          "POST",
          "account/corporate/services/service/unsubscribe",
          {
            product_id: MAGAZINE_SERVICE_ID,
          },
          (response) => {
            setShouldFetch(true);
          }
        );
        break;

      default:
        break;
    }
  };

  const subscribeToService = useCallback(
    (service_id, product_id) => {
      switch (service_id) {
        case JOBS_SERVICE_ID:
          erpAdapter.fetch(
            "POST",
            "account/corporate/services/service/subscribe",
            {
              product_id,
            },
            (response) => {
              setShouldFetch(true);
              setIsSubscribedToJobs(true);
            }
          );
          break;

        // TODO chanage to the correct endpoint when the API is ready.
        case LAW_SERVICE_ID:
          erpAdapter.fetch(
            "POST",
            "account/corporate/services/service/subscribe",
            {
              product_id,
            },
            (response) => {
              setShouldFetch(true);
            }
          );
          break;

        // TODO chanage to the correct endpoint when the API is ready.
        case MAGAZINE_SERVICE_ID:
          erpAdapter.fetch(
            "POST",
            "account/corporate/services/service/subscribe",
            {
              product_id: MAGAZINE_SERVICE_ID,
            },
            (response) => {
              setShouldFetch(true);
            }
          );
          break;

        // TODO chanage to the correct endpoint when the API is ready.
        case NEWSLETTER_SERVICE_ID:
          erpAdapter.fetch(
            "PUT",
            "account/single/services/newsletter",
            {
              product_ids: product_id,
            },
            (response) => {
              setShouldFetch(true);
            }
          );
          break;

        default:
          break;
      }
    },
    [setIsSubscribedToJobs]
  );

  const confirmUnsubscribe = useCallback((currentService, translate) => {
    const modalTitle = translate("account.services.servicebox.confirm.unsubscribe", { service: currentService.name });

    const cancelDate = new Date(currentService.cancel_date);

    const modalLead = translate("account.services.servicebox.confirm.unsubscribe.lead", { date: dateFormatter.format(cancelDate) });

    setHandleModalConfirm(() => () => {
      unsubscribeFromService(currentService.service_id, currentService.product_id);
      setModalState((state) => ({ ...state, visible: false }));
    });
    setModalState((state) => ({
      ...state,
      visible: true,
      modalTitle,
      modalLead,
    }));
  }, []);

  const confirmSubscribe = useCallback(
    (currentService, translate) => {
      const modalTitle = translate("account.services.servicebox.confirm.subscribe", { service: currentService.name });

      let modalLead = "";
      if (currentService.price > 0) {
        modalLead = translate("account.services.servicebox.confirm.subscribe.lead", { price: currencyFormatter.format(currentService.price) });
      }

      setHandleModalConfirm(() => () => {
        subscribeToService(currentService.service_id, currentService.product_id);
        setModalState((state) => ({ ...state, visible: false }));
      });

      setModalState((state) => ({
        ...state,
        visible: true,
        modalTitle,
        modalLead,
      }));
    },
    [subscribeToService]
  );

  const onMagazineSave = useCallback(() => {
    confirmSubscribe({ service_id: MAGAZINE_SERVICE_ID, name: selectedMagazine, price: 0 }, translate);
  }, [confirmSubscribe, translate, selectedMagazine]);

  const onNewsletterSave = useCallback(
    (updatedCheckboxes) => {
      let subscribedNewsletter = updatedCheckboxes.filter((newsletter) => newsletter.isSubscribed).map((newsletter) => newsletter.name);
      let queryStr = subscribedNewsletter.join(", ");
      let product_ids = updatedCheckboxes.filter((newsletter) => newsletter.isSubscribed).map((newsletter) => parseInt(newsletter.id));
      let currentlySelectedNewsletterIds = newsletters.filter((newsletter) => newsletter.isSubscribed).map((newsletter) => parseInt(newsletter.id));
      const [shortest, longest] =
        currentlySelectedNewsletterIds.length > product_ids.length
          ? [product_ids, currentlySelectedNewsletterIds]
          : [currentlySelectedNewsletterIds, product_ids];
      if (longest.every((product) => shortest.includes(product))) {
        return;
      }
      confirmSubscribe({ service_id: NEWSLETTER_SERVICE_ID, product_id: product_ids, name: queryStr, price: 0 }, translate);
    },
    [confirmSubscribe, translate, newsletters]
  );

  const extractPropsFromService = useCallback(
    (service) => {
      if (!service?.service) {
        return {};
      }
      let service_id = service.service[0].service_id;
      let extraProps = {};

      switch (service_id) {
        case REFERENCE_GALLERY_SERVICE_ID:
          extraProps = {
            onManage: () => history.push(pathFactory.create("reference-gallery/manage")),
          };
          break;

        case JOBS_SERVICE_ID:
          {
            let product_id = Object.keys(service.products[0])[0];
            let isCancelled = service.products[0][product_id]["subscription"]["status"] === "cancelled";
            let cancelDate = new Date(service.products[0][product_id]["subscription"]["cancel_date"]);
            let isSubscribed = isCancelled ? true : service.products[0][product_id]["subscription"]["status"] === "subscribed";
            let name = service.service[0].sale_name;
            let price = service.products[0][product_id].details.price;
            product_id = parseInt(product_id);
            const linksMap = {
              de: "de/cms/organisation/mitgliedschaft#firmenmitglied",
              fr: "fr/cms/organisation/affiliation#bureaumembre",
              it: "it/cms/organizzazione/affiliazione#socioditta",
              en: "en/cms/organisation/membership#corporatemembers",
            };
            extraProps = {
              isSubscribed,
              isCancelled,
              onManage: () => history.push(pathFactory.create("jobs/manage")),
              onSubscribe: () => {
                confirmSubscribe({ service_id, product_id, name, price }, translate);
              },
              onUnsubscribe: () => {
                if (isCancelled) {
                  return null;
                }
                return confirmUnsubscribe({ service_id, product_id, name, price }, translate);
              },
              options: {
                url: linksMap[language],
                linkTitle: translate("account.services.servicebox.more-info"),
                price: price,
                pricePhrase: translate("account.services.servicebox.price-phrase"),
                cancelDate,
              },
            };
          }
          break;

        case LAW_SERVICE_ID:
          {
            let product_id = Object.keys(service.products[0])[0];
            const linksMap = {
              de: "de/cms/premium-rechtsauskunft-agb",
              fr: "fr/cms/conseil-juridique-premium-CG",
              it: "it/cms/consulenza-giuridica-premium-CG",
              en: "en/cms/node/174",
            };
            let isCancelled = service.products[0][product_id]["subscription"]["status"] === "cancelled";
            let cancelDate = new Date(service.products[0][product_id]["subscription"]["cancel_date"]);
            let isSubscribed = isCancelled ? true : service.products[0][product_id]["subscription"]["status"] === "subscribed";
            let name = service.service[0].sale_name;
            let price = service.products[0][product_id].details.price;
            product_id = parseInt(product_id);

            extraProps = {
              isSubscribed,
              isCancelled,
              onSubscribe: () => {
                confirmSubscribe({ service_id, product_id, name, price }, translate);
              },
              onUnsubscribe: () => {
                if (isCancelled) {
                  return null;
                }
                return confirmUnsubscribe({ service_id, product_id, name, price }, translate);
              },
              options: {
                url: linksMap[language],
                linkTitle: translate("account.services.servicebox.more-info"),
                price: price,
                pricePhrase: translate("account.services.servicebox.price-phrase"),
                cancelDate,
              },
            };
          }
          break;
        case MAGAZINE_SERVICE_ID:
          extraProps = {
            isSubscribed: isSubscribedToMagazines,
            onSave: onMagazineSave,
            options: {
              name: "Magazines",
              formatBoxes: checkBoxes,
              radioItems: magazines,
              selectedValue: selectedMagazine,
              setSelectedValue: setSelectedMagazine,
              isSaveButtonActive: isMagazineSaveButtonActive,
              setIsSaveButtonActive: setIsMagazineSaveButtonActive,
              onRadioboxChange: onMagazineChange,
            },
          };

          break;
        case NEWSLETTER_SERVICE_ID:
          extraProps = {
            isSubscribed: isSubscribedToNewsLetter,
            onSave: onNewsletterSave,
            options: {
              name: "newsletters",
              checkBoxes: newsletters,
            },
          };
          break;
        default:
          break;
      }

      return extraProps;
    },
    [
      magazines,
      confirmSubscribe,
      confirmUnsubscribe,
      history,
      pathFactory,
      translate,
      checkBoxes,
      isMagazineSaveButtonActive,
      isSubscribedToMagazines,
      onMagazineChange,
      onMagazineSave,
      selectedMagazine,
      isSubscribedToNewsLetter,
      newsletters,
      onNewsletterSave,
      language,
    ]
  );
  const updateServicesState = useCallback((service) => {
    if (!service?.service) {
      return {};
    }
    let service_id = service.service[0].service_id;
    switch (service_id) {
      case NEWSLETTER_SERVICE_ID:
        {
          let products = service.products;
          let productNewsletters = products.map((product) => {
            let productValue = Object.values(product)[0];
            let productId = Object.keys(product)[0];
            let productName = productValue.details.sale_name;
            let isSubscribed = productValue.subscription.status === "subscribed";
            let isCancelled = productValue.subscription.status === "cancelled";
            return {
              id: productId,
              name: productName,
              isSubscribed,
              isCancelled,
            };
          });
          const isSubscribedToAnyNewsLetter = productNewsletters.some((e) => e.isSubscribed);
          setIsSubscribedToNewsletter(isSubscribedToAnyNewsLetter);
          setNewsletters(productNewsletters);
        }
        break;
      case MAGAZINE_SERVICE_ID:
        {
          let products = service.products;
          let productMagazines = products.map((product) => {
            let productValue = Object.values(product)[0];
            let productName = productValue.details.sale_name;
            return { value: productName, label: productName };
          });
          let noMagazine = { value: "", label: "Keine" };
          productMagazines.push(noMagazine);
          let uniqueMagazines = productMagazines.reduce((acc, e) => {
            let objectAlreadyExists = acc.find((obj) => e.value === obj.value);
            if (!objectAlreadyExists) {
              acc.push(e);
            }
            return acc;
          }, []);
          setMagazines(uniqueMagazines);

          let selectedProduct = products.find((product) => {
            let productValue = Object.values(product)[0];
            let subscriptionStatus = productValue.subscription.status;
            return subscriptionStatus === "subscribed";
          });
          if (!selectedProduct) {
            setSelectedMagazine("");
            setIsSubscribedToMagazines(false);
          } else {
            let selectedMagazine = Object.values(selectedProduct)[0].details.sale_name;
            setSelectedMagazine(selectedMagazine);
            setIsSubscribedToMagazines(true);
          }
        }
        break;
      default:
        break;
    }
  }, []);

  const extractServicesFromData = useCallback(
    (data) => {
      if (data === null) {
        return [];
      }
      let userRole = user.getRole();
      let filteredServices = null;
      if (userRole === "sia_role_member_corporate") filteredServices = ["2", "3", "4", "5"];
      if (userRole === "sia_role_member_single") filteredServices = ["2"];

      let serviceEntries = Object.entries(data);
      serviceEntries = serviceEntries.filter(([key, value]) => filteredServices.includes(key)).map((entry) => entry[1]);
      serviceEntries = serviceEntries.flatMap((entry) => Object.entries(entry));
      let services = serviceEntries.map((entry) => {
        let serviceType = entry[0];
        let service = entry[1];
        let serivceDetails = entry[1].service[0];

        let serviceName = serivceDetails.sale_name;
        let service_id = serivceDetails.service_id;
        let key = Object.keys(service.products[0])[0];
        let serviceDescription = service.products[0][key].details.description;
        let extraProps = extractPropsFromService(service);
        let isSubscribed = extraProps.isSubscribed;
        let isCancelled = extraProps.isCancelled;
        return {
          isSubscribed,
          isCancelled,
          service_id,
          type: parseInt(serviceType),
          title: serviceName,
          description: serviceDescription,
          translate: translate,
          ...extraProps,
        };
      });
      services.sort((a, b) => b.type - a.type);
      return services;
    },
    [translate, extractPropsFromService, user]
  );

  const updateMagazines = useCallback(
    (data) => {
      let serviceEntries = Object.entries(data);
      serviceEntries = serviceEntries.map((entry) => entry[1]);
      serviceEntries = serviceEntries.flatMap((entry) => Object.entries(entry));
      serviceEntries.forEach((entry) => {
        let serviceType = parseInt(entry[0]);
        let service = entry[1];
        if (serviceType === 4) {
          updateServicesState(service);
        }
      });
    },
    [updateServicesState]
  );
  const [data, setData] = useState(null);

  useEffect(() => {
    let services = extractServicesFromData(data);
    setServices(services);
  }, [data, extractServicesFromData]);

  useEffect(() => {
    if (language && shouldFetch) {
      webPlatformAdapter.getServiceSubscriptions(language, (response) => {
        setShouldFetch(false);
        const { data } = response;
        updateMagazines(data);
        setData(data);
      });
    }
  }, [extractServicesFromData, updateMagazines, language, shouldFetch]);

  if (!isSubscribedToAnyService) {
    return <NotFoundPage />;
  }

  const handleModalCancel = () => setModalState((state) => ({ ...state, visible: false }));

  const elementToRender = () => {
    if (services.length === 0) {
      return null;
    }
    return services.map((service, i) => <ServiceSubscriptionBox key={i} {...service} />);
  };

  return (
    <Page contentNavigation={<UserContentNavigation />} title={"account.name"}>
      <div className="bx--grid reset-spacings">
        <div className="bx--row">
          <div className="bx--col-lg-2 bx--col-md-0 bx--col-ms-0"></div>
          <div className="bx--col-lg-6 bx--col-md-4 bx--col-sm-4">
            <div className="Services-Container Services-Container-left">
              {services.length > 0 &&
                elementToRender().map((e, i) => {
                  if (i % 2 === 0) return e;
                  return null;
                })}
            </div>
          </div>
          <div className="bx--col-lg-6 bx--col-md-4 bx--col-sm-4">
            <div className="Services-Container Services-Container-right">
              {services.length > 0 &&
                elementToRender().map((e, i) => {
                  if (i % 2 !== 0) return e;
                  return null;
                })}
            </div>
          </div>
          {modalState.visible ? (
            <Modal
              title={modalState.modalTitle}
              lead={modalState.modalLead}
              onConfirm={handleModalConfirm}
              onCancel={handleModalCancel}
              confirmLabel={translate("modal.button.confirm")}
              cancelLabel={translate("modal.button.cancel")}
            />
          ) : null}
          <div className="bx--col-lg-2 bx--col-md-0 bx--col-ms-0"></div>
        </div>
      </div>
    </Page>
  );
};

export default ServicesPage;
