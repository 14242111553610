import KeycloakIDPAdapter from './KeycloakIDPAdapter';
import MockIDPAdapter from './MockIDPAdapter'; // todo, find an abstraction solution to not deploy this in production. https://stackoverflow.com/q/71482650/4840661

const availableIDPAdapters = {
    keycloak: KeycloakIDPAdapter,
    mock: MockIDPAdapter
};

// todo factory like on WebPlatformAdapter
const environmentIDPAdapterKey = process.env.REACT_APP_IDP_ADAPTER;
const IDPAdapter = availableIDPAdapters[environmentIDPAdapterKey];

if (!IDPAdapter) {
    throw new Error(`Invalid IDPAdapter from key '${environmentIDPAdapterKey}'`);
}
export default IDPAdapter;