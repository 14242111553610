import { useHistory, useLocation } from "react-router-dom";
import { usePathFactory } from "common/Path/PathFactoryHook";

export function useErrorNavigator() {
    const history = useHistory();
    const location = useLocation()
    const pathFactory = usePathFactory();
    const navigate = (route) => {
        history.replace(location.pathname)
        history.push(pathFactory.create(route)
        )
    }
    return navigate;
}