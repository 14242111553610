import HttpWebPlatformAdapter from "./HttpWebPlatformAdapter";
import MockWebPlatformAdapter from "./MockWebPlatformAdapter"; // todo, find an abstraction solution to not deploy this in production. https://stackoverflow.com/q/71482650/4840661
import httpClient from "common/HttpClient";

const create = () => {
    if (process.env.REACT_APP_WEB_PLATFORM_API_URL === 'mock') {
        return new MockWebPlatformAdapter();
    }
    return new HttpWebPlatformAdapter(httpClient);
}

const webPlatformAdapter = create();
export default webPlatformAdapter;