import "./Fragments.scss";
const FragmentTitle = ({ title }) => {
  return (
    <div className="FragmentTitle">
      <span className="sia-text-07">{title}</span>
    </div>
  );
};

export default FragmentTitle;
