let re = /Chrome\/\d+\.\d+\.\d+\.\d+/

let googleDesktopBots = [
    "Mozilla/5.0 (compatible; Googlebot/2.1; +http://www.google.com/bot.html)",
    "Googlebot/2.1 (+http://www.google.com/bot.html)"
]
let googleDesktopVersionedBot = "Mozilla/5.0 AppleWebKit/537.36 (KHTML, like Gecko; compatible; Googlebot/2.1; +http://www.google.com/bot.html)"

let googleSmartphoneVersionedBot = [
    "Mozilla/5.0 (Linux; Android 6.0.1; Nexus 5X Build/MMB29P) AppleWebKit/537.36 (KHTML, like Gecko)",
    "Mobile Safari/537.36 (compatible; Googlebot/2.1; +http://www.google.com/bot.html)"
]

const isGoogleBot = (userAgent) => {
    const isGoogleDesktopBot = googleDesktopBots.some(bot => userAgent.includes(bot))
    const isGoogleDesktopVersionedBot = userAgent.includes(googleDesktopVersionedBot) && re.test(userAgent)
    const isGoogleSmartphoneVersionedBot = googleSmartphoneVersionedBot.every(substr => userAgent.includes(substr)) && re.test(userAgent)
    return isGoogleDesktopBot || isGoogleDesktopVersionedBot || isGoogleSmartphoneVersionedBot
}

export default  isGoogleBot;